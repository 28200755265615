import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import InfoCreateur from "../commun/InfoGeneralOpenning/InfoCreateur/InfoCreateur";
import Panel from "../commun/Panel/Panel";
import ProgressBarComponent from "../commun/ProgressBar/ProgressBarComponent";
import { OpeningVariantTree } from "../TreeOpening/OpeningVariantTree";
import EvaluationDetails from "./EvaluationDetails/EvaluationDetails";
import MoveDetails from "./MoveDetails/MoveDetails";
import SessionStats from "./SessionStats/SessionStats";
import "./TreeAndDetails.scss";

export default function TreeAndDetails() {
  const { mode, currentPage, infoOpeningData } = useLessonOpeningStore();

  return (
    <div className="treeAndDetails">
      {/* Section InfoCreateur */}

      <InfoCreateur />

      {/* Section OpeningVariantTree */}
      <div className="treeAndDetails_conteneur">
        <div className="treeAndDetails_conteneur_tree">
          <div
            className={`treeAndDetails_tree ${mode === "cours" ? "" : "blur"}`}
          >
            <OpeningVariantTree />
          </div>
        </div>
        <div className="treeAndDetails_details ">
          {mode === "graduation" && <EvaluationDetails />}
          {mode === "entrainement" && <SessionStats />}
          {/* <Panel /> */}
        </div>
        {mode === "cours" && <MoveDetails />}
      </div>
      <div className="treeAndDetails_outil">
        <Panel />
        {mode === "cours" && (
          <ProgressBarComponent
            percentage={(currentPage / infoOpeningData.nbrOfPath) * 100}
          />
        )}
      </div>
    </div>
  );
}
