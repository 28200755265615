import { useEffect, useState } from "react";
import { config } from "../config";
import { useAnnotationsStore } from "../store/useAnnotationsStore";
import { useLessonOpeningStore } from "../store/useLessonOpeningStore";
import { OpeningMove } from "../types";
import { fireConfetti } from "../utils/confetti";
import { averageMoveTime } from "../utils/utils";

type ModeLesson = "graduation" | "entrainement" | "cours";

type ModeHandler = {
  initialize: () => void;
  handleCorrectMove: (matchingMove: OpeningMove) => void;
  handleIncorrectMove: (sourceSquare: string, targetSquare: string) => void;
  makeOpponentMove: (overrideCurrentMove?: OpeningMove) => void; // Accepte un argument facultatif
  handleNoMoreMoves: () => void;
  final: () => void;
};

export const useModeLogic = () => {
  const {
    updateMoveTimes,
    setIncrementStreakCount,
    setIncrementErrorCount,
    currentMoveEntrainement,
    setCurrentPage,
    currentPage,
    currentMove,
    loadMove,
    getNextMoves,
    mode,
    errorCount,
    completedVariante,
    setIncrementCompletedVarianteCount,
    openingManager,
    nbrMemoryPosition,
    getMoveByPage,
    infoOpeningData,
    resetIncrementLesson,
    setIncrementnbrMemoryPosition,
    gameChess, // Ajout de gameChess ici pour gérer les coups de l'adversaire
    setScoreGraduation,
    movesTimes,
  } = useLessonOpeningStore();

  const { addAnnotation } = useAnnotationsStore();
  const [previousErrorCount, setPreviousErrorCount] = useState(0);
  const DELAY = 500; // Constante pour les délais d'attente
  const color = infoOpeningData.color === "w" ? "black" : "white";

  const loadMoveAndCurrentPage = (move: OpeningMove) => {
    if (!move || !move.path) {
      console.error("Move or path is undefined", move);
      return;
    }
    // Mise à jour de la page courante
    const nextPath = move.path.find((value) => value >= currentPage);
    setCurrentPage(nextPath ?? move.path[0]);

    // Mise à jour du déplacement
    loadMove(move);
  };

  const handleOpponentTurn = () => {
    const latestPlayerColor = infoOpeningData.color; // Obtenir la valeur actuelle
    const latestCurrentPlayerColor = gameChess.getCurrentPlayerColor(); // Obtenir la valeur actuelle
    const latestCurrentMove = currentMove; // Obtenir la dernière version de currentMove

    if (latestPlayerColor !== latestCurrentPlayerColor && latestCurrentMove) {
      makeOpponentMove();
    }
  };

  const modeHandlers: Record<ModeLesson, ModeHandler> = {
    graduation: {
      initialize: () => {
        setCurrentPage(0);
        resetIncrementLesson();
        const move = getMoveByPage(0)!;
        loadMove(move);
        infoOpeningData.color === "b" && handler.makeOpponentMove(move); //pour savoir si on declanche le premier coup
      },
      handleCorrectMove: (matchingMove: OpeningMove) => {
        updateMoveTimes();
        setIncrementStreakCount();
        loadMoveAndCurrentPage(matchingMove);
        if (completedVariante >= config.nbrCompletedVariante)
          setIncrementnbrMemoryPosition();
      },
      handleIncorrectMove: () => {
        setIncrementErrorCount();
      },
      makeOpponentMove: (overrideCurrentMove?: OpeningMove) => {
        // Utiliser `overrideCurrentMove` si fourni, sinon utiliser `currentMove` du store
        const moveToUse = overrideCurrentMove || currentMove;

        if (!moveToUse) {
          console.error("No current move available.");
          return;
        }

        const nextMoves = getNextMoves(moveToUse.history); // Utiliser `moveToUse`

        const opponentMove =
          nextMoves[Math.floor(Math.random() * nextMoves.length)];

        if (completedVariante < config.nbrCompletedVariante) {
          setTimeout(() => loadMoveAndCurrentPage(opponentMove), 500);
        } else {
          setTimeout(
            () =>
              loadMoveAndCurrentPage(
                openingManager.getRandomMove(5, 10, color)!
              ),
            500
          );
        }
      },

      // Constante pour les délais d'attente

      handleNoMoreMoves: () => {
        const hasMoreVariants =
          completedVariante < config.nbrCompletedVariante - 1;

        // Incrémenter le compteur de variantes complétées si nécessaire
        if (completedVariante < config.nbrCompletedVariante) {
          setIncrementCompletedVarianteCount();
        }

        // Si la variante n'est pas encore terminée, recommencer au premier coup
        if (hasMoreVariants) {
          setTimeout(() => {
            const move = getMoveByPage(0)!;
            loadMoveAndCurrentPage(move);

            // Si le joueur est noir, faire jouer l'adversaire
            if (infoOpeningData.color === "b") {
              setTimeout(() => {
                handler.makeOpponentMove(move); // Appelle `makeOpponentMove` avec le coup initial
              }, DELAY);
            }
          }, DELAY);
        } else {
          setTimeout(() => {
            const randomMove = openingManager.getRandomMove(5, 10, color)!;
            loadMoveAndCurrentPage(randomMove);
          }, DELAY);
        }
      },

      final: () => {
        const MAX_SCORE = 20; // Score maximal sur 20
        const ERROR_PENALTY = 2; // Pénalité par erreur
        const BASE_TIME = 1.5; // Temps minimum (1.5s) avant d'appliquer la pénalité
        const LINEAR_TIME_PENALTY = 0.5; // Pénalité linéaire par seconde au-dessus de BASE_TIME

        const moyenneTimes = averageMoveTime(movesTimes);

        // 1. Pénalité d'erreur : on soustrait des points pour chaque erreur
        const errorPenalty = errorCount * ERROR_PENALTY;

        // 2. Pénalité de temps : seulement si le temps moyen est supérieur à 1.5 secondes
        const moyenneTimesNumber = parseFloat(moyenneTimes); // Convertir en nombre

        let timePenalty = 0;
        if (moyenneTimesNumber > BASE_TIME) {
          const excessTime = moyenneTimesNumber - BASE_TIME;
          // Appliquer une pénalité linéaire au-delà de BASE_TIME
          timePenalty = excessTime * LINEAR_TIME_PENALTY; // Pénalité linéaire
        }

        // Calculer le score total en tenant compte des pénalités
        let finalScore = MAX_SCORE - (errorPenalty + timePenalty); // Soustraction des pénalités
        finalScore = Math.max(0, Math.min(finalScore, MAX_SCORE)); // S'assurer que le score est entre 0 et 20

        // Arrondir à un chiffre après la virgule
        finalScore = parseFloat(finalScore.toFixed(1));

        console.log(`Score final: ${finalScore}/20`);
        setScoreGraduation(finalScore);
      },
    },
    entrainement: {
      handleCorrectMove: (matchingMove: OpeningMove) => {
        setIncrementStreakCount();
        loadMoveAndCurrentPage(matchingMove);
      },
      initialize: () => {
        resetIncrementLesson();
        handleOpponentTurn();
      },
      handleIncorrectMove: () => {
        setIncrementErrorCount();
      },
      makeOpponentMove: () => {
        const nextMoves = getNextMoves(currentMove.history);
        const opponentMove =
          nextMoves[Math.floor(Math.random() * nextMoves.length)];
        setTimeout(() => loadMoveAndCurrentPage(opponentMove), 500);
      },
      handleNoMoreMoves: () => {
        if (errorCount === previousErrorCount) {
          setTimeout(() => {
            loadMoveAndCurrentPage(currentMoveEntrainement!);
          }, 500);
          fireConfetti();
        } else {
          setTimeout(() => {
            loadMoveAndCurrentPage(currentMoveEntrainement!);
          }, 500);
          setPreviousErrorCount(errorCount);
        }
      },
      final: () => {},
    },
    cours: {
      initialize: () => {},
      handleCorrectMove: () => {},
      handleIncorrectMove: () => {},
      makeOpponentMove: () => {},
      handleNoMoreMoves: () => {},
      final: () => {},
    },
  };

  const handler = modeHandlers[mode];

  const handleInitializMove = () => {
    handler.initialize();
  };

  useEffect(() => {
    handleInitializMove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (currentPage) {
      if (
        mode === "graduation" &&
        completedVariante === config.nbrCompletedVariante &&
        nbrMemoryPosition === config.nbrMemoryPosition
      ) {
        handler.final();
        return;
      }
      handleOpponentTurn(); // Appeler la gestion du tour de l'adversaire une fois que `currentPage` a changé
    }
  }, [currentPage]); // Ajouter `currentPage` comme dépendance

  const handleCorrectMove = (matchingMove: OpeningMove) => {
    handler.handleCorrectMove(matchingMove);
  };

  const handleIncorrectMove = (sourceSquare: string, targetSquare: string) => {
    addAnnotation(sourceSquare, "error");
    addAnnotation(targetSquare, "error");
    handler.handleIncorrectMove(sourceSquare, targetSquare);
  };

  const makeOpponentMove = () => {
    const nextMoves = getNextMoves(currentMove.history);

    if (!nextMoves.length) {
      handleNoMoreMoves();
      return false;
    }
    handler.makeOpponentMove();
  };

  const handleNoMoreMoves = () => {
    handler.handleNoMoreMoves();
  };

  return {
    handleCorrectMove,
    makeOpponentMove,
    handleIncorrectMove,
    handleNoMoreMoves,
  };
};
