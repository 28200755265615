import React, { useState } from "react";
import Modal from "react-modal";
import logo from "../../assets/logo.png";
import EmailSignInForm from "../commun/SignInButton/EmailSignInForm/EmailSignInForm";
import GoogleSignInButton from "../commun/SignInButton/GoogleSignInButton/GoogleSignInButton";
import "./AuthModal.scss";

interface AuthModalProps {
  open: boolean;
  handleClose: () => void;
}

Modal.setAppElement("#root");

const AuthModal: React.FC<AuthModalProps> = ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      isOpen={open}
      onRequestClose={!isLoading ? handleClose : undefined}
      shouldCloseOnOverlayClick={!isLoading}
      shouldCloseOnEsc={!isLoading}
      className="auth-modal"
      overlayClassName="auth-modal-overlay"
    >
      <div className="modal-content">
        <div className={`modal-inner-content ${isLoading ? "is-loading" : ""}`}>
          <div className="modal-header">
            <img src={logo} alt="logo" className="modal-logo" loading="lazy" />
          </div>
          <h2 className="modal-title">
            Connectez-vous ou créez un compte pour continuer
          </h2>
          <div className="modal-form">
            <GoogleSignInButton setIsLoading={setIsLoading} />
            <div className="divider"></div>
            <EmailSignInForm />
          </div>
        </div>
        {isLoading && <div className="loading-spinner"></div>}
      </div>
    </Modal>
  );
};

export default AuthModal;
