import { TreeOpeningData } from "../types";

// Variables CSS utilisées pour les couleurs
const primaryColor = "hsl(253, 91%, 58%)"; // Couleur primaire (bon coup)
const destructiveColor = "hsl(339.2, 90.36%, 51.18%)"; // Couleur pour les coups destructeurs
const secondaryColor = "hsl(253, 7%, 9%)"; // Variante ou coup secondaire
const neutralColor = "hsl(215, 27.9%, 50%)"; // Couleur neutre

export const mockOpeningEmptyData: TreeOpeningData = {
  tree: {
    evaluation: "",
    san: "",
    arrows: [],
    history: [],
    circles: [],
    path: [0],
    children: {},
    blockEntrainement: false,
    fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
  },
};
