// TreeComponent.tsx

import React, { useEffect, useRef, useState } from "react";
import Tree from "react-d3-tree";
import { OpeningMove, TreeNode } from "../../../types";
import TreeD3Manager from "../../../utils/TreeD3Manager"; // Import the TreeD3Manager
import { TreeLinkManager } from "../../../utils/TreeLinkManager";
import { renderCustomNode } from "../../TreeOpening/RenderCustomNode/renderCustomNode";
import ChessboardPreview from "../PreviewChessboard/PreviewChessboard";
import "./TreeComponent.scss";

interface TreeComponentProps {
  onRequestClose?: () => void;
  treeNode?: TreeNode | null;
  currentMove: OpeningMove;
  loadMove: (move: OpeningMove) => void | null;
  TREE_ID: string;
  color: "w" | "b";
}

const TreeComponent: React.FC<TreeComponentProps> = ({
  onRequestClose,
  treeNode,
  currentMove,
  color,
  loadMove,
  TREE_ID,
}) => {
  const chessboardPreviewRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const treeLinkManagerRef = useRef<TreeLinkManager>(
    new TreeLinkManager(TREE_ID, currentMove)
  );

  // State to track the dimensions of the parent container
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  // Constants
  const NODE_RADIUS = 12;
  const NODE_WIDTH = 50;

  // Instantiate the TreeD3Manager
  const treeD3ManagerRef = useRef<TreeD3Manager>(
    new TreeD3Manager(NODE_RADIUS, NODE_WIDTH, TREE_ID)
  );

  const isFirstRun = useRef(true); // Crée un drapeau pour savoir si c'est le premier rendu

  useEffect(() => {
    if (isFirstRun.current) {
      // Exécuter l'effet uniquement lors du premier rendu
      treeD3ManagerRef.current.clearLines();
      treeD3ManagerRef.current.addLinesBetweenNodes(); // Ajouter les lignes entre les nodes avec le même FEN

      // Mettre à jour le currentMove dans le TreeLinkManager lorsque celui-ci change
      treeLinkManagerRef.current.updateCurrentMove(currentMove);
      treeD3ManagerRef.current.addIconToLinks();

      isFirstRun.current = false; // Marquer comme "déjà exécuté"
    }
  }, [treeNode, currentMove]);

  // Handle the size of the parent container
  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect) {
        setContainerDimensions({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      }
    };

    const observer = new ResizeObserver((entries) => handleResize(entries));

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      id="treeWrapper"
      ref={containerRef}
      style={{ width: "100%", height: "100%" }}
    >
      {treeNode && (
        <Tree
          data={treeNode}
          renderCustomNodeElement={(rd3tProps) =>
            renderCustomNode(
              rd3tProps,
              chessboardPreviewRef,
              currentMove ?? undefined,
              loadMove,
              onRequestClose,
              TREE_ID
            )
          }
          zoomable={true}
          draggable={true}
          nodeSize={{ x: NODE_WIDTH, y: NODE_WIDTH }}
          separation={{ siblings: 1, nonSiblings: 1.5 }}
          translate={{
            x: containerDimensions.width / 2,
            y: 50,
          }}
          pathClassFunc={(linkData: any) =>
            treeLinkManagerRef.current.getPathClass(linkData)
          }
          orientation="vertical"
        />
      )}

      <ChessboardPreview ref={chessboardPreviewRef} color={color} />
    </div>
  );
};

export default TreeComponent;
