import { useEffect, useState } from "react";
import "../../../assets/svg/pieces";
import { defaultPieces } from "../../../assets/svg/pieces";
import "./LoaderChess.scss";

type Pieces = { [key: string]: React.ReactNode };

interface Move {
  piece: string; // Clé des pièces, ex. 'wP', 'bP'
  from: string;
  to: string;
  delay: number;
}

const LoaderChess = () => {
  const [pieces, setPieces] = useState<Pieces>({});
  const [currentOpeningIndex, setCurrentOpeningIndex] = useState(0);

  const openings = [
    {
      name: "Ouverture Italienne",
      moves: [
        { piece: "wP", from: "e2", to: "e4", delay: 0 },
        { piece: "bP", from: "e7", to: "e5", delay: 300 },
        { piece: "wN", from: "g1", to: "f3", delay: 600 },
        { piece: "bN", from: "b8", to: "c6", delay: 900 },
        { piece: "wB", from: "f1", to: "c4", delay: 1200 },
        { piece: "bB", from: "f8", to: "c5", delay: 1500 },
      ],
    },
    {
      name: "Défense Sicilienne",
      moves: [
        { piece: "wP", from: "e2", to: "e4", delay: 0 },
        { piece: "bP", from: "c7", to: "c5", delay: 300 },
        { piece: "wN", from: "g1", to: "f3", delay: 600 },
        { piece: "bP", from: "d7", to: "d6", delay: 900 },
        { piece: "wP", from: "d2", to: "d4", delay: 1200 },
        { piece: "bP", from: "c5", to: "d4", delay: 1500 },
        { piece: "wN", from: "f3", to: "d4", delay: 1800 },
        { piece: "bN", from: "g8", to: "f6", delay: 2100 },
      ],
    },
  ];

  useEffect(() => {
    let isMounted = true;

    const playOpening = async () => {
      setPieces(getInitialPositions());

      const currentOpening = openings[currentOpeningIndex];
      const totalMoves = currentOpening.moves.length;

      for (let i = 0; i < totalMoves; i++) {
        const move = currentOpening.moves[i];
        await new Promise((resolve) => setTimeout(resolve, move.delay));

        if (!isMounted) return;

        setPieces((prevPieces) => {
          const updatedPieces = { ...prevPieces };
          delete updatedPieces[move.from];
          updatedPieces[move.to] = move.piece;
          return updatedPieces;
        });
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (!isMounted) return;

      setCurrentOpeningIndex((prevIndex) => (prevIndex + 1) % openings.length);
    };

    playOpening();

    return () => {
      isMounted = false;
    };
  }, [currentOpeningIndex]);

  const getInitialPositions = (): Pieces => {
    return {
      a1: "wR",
      b1: "wN",
      c1: "wB",
      d1: "wQ",
      e1: "wK",
      f1: "wB",
      g1: "wN",
      h1: "wR",
      a2: "wP",
      b2: "wP",
      c2: "wP",
      d2: "wP",
      e2: "wP",
      f2: "wP",
      g2: "wP",
      h2: "wP",
      a8: "bR",
      b8: "bN",
      c8: "bB",
      d8: "bQ",
      e8: "bK",
      f8: "bB",
      g8: "bN",
      h8: "bR",
      a7: "bP",
      b7: "bP",
      c7: "bP",
      d7: "bP",
      e7: "bP",
      f7: "bP",
      g7: "bP",
      h7: "bP",
    };
  };

  const renderBoard = () => {
    const squares = [];
    for (let rank = 8; rank >= 1; rank--) {
      for (let file = 0; file < 8; file++) {
        const squareColor = (rank + file) % 2 === 0 ? "light" : "dark";
        const position = `${String.fromCharCode(97 + file)}${rank}`;
        const pieceKey = pieces[position] as string; // Forcer le type à string
        const piece = pieceKey && defaultPieces({})[pieceKey];

        squares.push(
          <div
            key={position}
            className={`square ${squareColor}`}
            style={{ position: "relative" }}
          >
            {piece}
          </div>
        );
      }
    }
    return squares;
  };

  return (
    <div className="chess-loader">
      <div className="chess-board">{renderBoard()}</div>
      <p className="opening-name">{openings[currentOpeningIndex].name}</p>
    </div>
  );
};

export default LoaderChess;
