import { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { HiOutlineLightBulb } from "react-icons/hi";
import { PiTreeStructureLight } from "react-icons/pi";
import { useModalTree } from "../../../../contexts/ModalTreeContext";

import { useLessonOpeningStore } from "../../../../store/useLessonOpeningStore";

import { useAuthPremium } from "../../../../contexts/AuthPremiumContext";
import { OpeningMove } from "../../../../types";
import Tooltip from "../../Tooltip/Tooltip";
import "./TrainingPanel.scss";

const TrainingPanel = ({
  setIsLocked,
}: {
  setIsLocked: (locked: boolean) => void;
}) => {
  const { pathWithAccessCheck } = useAuthPremium();
  const {
    handleUndoMove,
    handleNextMove,
    loadMoveWrapper,
    currentMove,
    getOpeningTreeConverted,
    infoOpeningData,
  } = useLessonOpeningStore();
  const { setMode } = useLessonOpeningStore();
  const [isLightPressed, setIsLightPressed] = useState(false);

  const {
    openModalTree,
    setTreeNode,
    setCurrentMove,
    setLoadMove,
    setTreeId,
    setColor,
    setLegende,
  } = useModalTree();

  const handleLightPressStart = (
    event: React.MouseEvent | React.TouchEvent
  ) => {
    event.preventDefault();
    setMode("cours");
    setIsLocked(true); // Verrouille le mode sur "entrainement"
    setIsLightPressed(true);
    handleNextMove();
  };

  const handleLightPressEnd = () => {
    if (isLightPressed) {
      setIsLightPressed(false);
      handleUndoMove();
      setMode("entrainement");
      setIsLocked(false); // Déverrouille après le relâchement
    }
  };

  useEffect(() => {
    const handleGlobalRelease = () => {
      handleLightPressEnd();
    };

    document.addEventListener("mouseup", handleGlobalRelease);
    document.addEventListener("touchend", handleGlobalRelease);

    return () => {
      document.removeEventListener("mouseup", handleGlobalRelease);
      document.removeEventListener("touchend", handleGlobalRelease);
    };
  }, [isLightPressed]);

  const handleModalTree = () => {
    const treeNode = getOpeningTreeConverted();
    const move = currentMove;

    setTreeNode(treeNode);
    setCurrentMove(move);
    setLoadMove(() => async (moveDetail: OpeningMove) => {
      await pathWithAccessCheck(
        () => loadMoveWrapper(moveDetail),
        moveDetail.path[0]
      );
    });
    setTreeId("modaltreecours");
    setLegende(null);
    setColor(infoOpeningData.color);
    openModalTree();
  };

  return (
    <div className="training-panel">
      <Tooltip text="Retour">
        <button
          type="button"
          aria-haspopup="dialog"
          className="training-panel_btn"
          onClick={() => {
            setMode("cours");
          }}
        >
          <div className="training-panel_btn_arrowLeft">
            <FaArrowLeft />
          </div>
        </button>
      </Tooltip>
      <div className="divider"></div>

      <Tooltip text="Arbre d'ouverture">
        <button
          type="button"
          className="learn-panel_btn"
          onClick={handleModalTree}
        >
          <div className="training-panel_btn_tree">
            <PiTreeStructureLight />
          </div>
        </button>
      </Tooltip>

      <Tooltip text="Montre le coup suivant">
        <button
          type="button"
          className="learn-panel_btn"
          onMouseDown={handleLightPressStart}
          onTouchStart={handleLightPressStart}
        >
          <div className="training-panel_btn_light">
            <HiOutlineLightBulb />
          </div>
        </button>
      </Tooltip>
    </div>
  );
};

export default TrainingPanel;
