// src/utils/TreeD3Manager.tsx
import * as d3 from "d3";
import { createRoot } from "react-dom/client";
import LinksIcon from "../components/commun/TreeLink/LinkIcon";

class TreeD3Manager {
  private NODE_RADIUS: number;
  private NODE_WIDTH: number;
  private TREE_ID: string;
  private positionCache: Map<SVGGraphicsElement, { x: number; y: number }> =
    new Map();

  constructor(NODE_RADIUS: number, NODE_WIDTH: number, TREE_ID: string) {
    this.NODE_RADIUS = NODE_RADIUS;
    this.NODE_WIDTH = NODE_WIDTH;
    this.TREE_ID = TREE_ID;
  }

  private getTransformedPosition = (element: SVGGraphicsElement) => {
    // Vérifier si la position de cet élément est déjà dans le cache
    if (this.positionCache.has(element)) {
      return this.positionCache.get(element);
    }

    const svg = element.ownerSVGElement;
    if (!svg) {
      console.error("L'élément n'est pas dans un SVG.");
      return null;
    }

    const point = svg.createSVGPoint();
    point.x = 0;
    point.y = 0;

    const ctm = element.getCTM(); // Utiliser getCTM()
    if (ctm) {
      const transformedPoint = point.matrixTransform(ctm);
      const position = {
        x: transformedPoint.x,
        y: transformedPoint.y,
      };

      // Ajouter la position calculée au cache
      this.positionCache.set(element, position);

      return position;
    }

    return null;
  };

  public clearLines() {
    // Sélectionner et supprimer toutes les lignes avec la classe spécifique
    d3.selectAll("line.link-active-dashed").remove();
  }

  public addLinesBetweenNodes() {
    const allNodes = document.querySelectorAll("[data-fen]");
    const fenMap: { [fen: string]: Element[] } = {};

    // Fonction pour extraire les 4 premiers champs du FEN (jusqu'à la prise en passant)
    const extractPositionFromFEN = (fen: string) => {
      const fenParts = fen.split(" ");
      // On récupère seulement les 4 premiers champs (placement des pièces, trait, droits de roque, prise en passant)
      return fenParts.slice(0, 4).join(" ");
    };

    // Grouper les nœuds par la position jusqu'à la prise en passant (sans le compteur de demi-coups)
    allNodes.forEach((node) => {
      const fen = node.getAttribute("data-fen");
      if (fen && this.TREE_ID === node.getAttribute("data-idTree")) {
        const positionKey = extractPositionFromFEN(fen);

        // Initialiser l'entrée dans fenMap si elle n'existe pas encore
        if (!fenMap[positionKey]) {
          fenMap[positionKey] = [];
        }

        // Ajouter le nœud correspondant à la même position (sans compter les demi-coups)
        fenMap[positionKey].push(node);
      }
    });

    // Vérifie si plusieurs nœuds ont le même FEN et calcule la distance
    Object.keys(fenMap).forEach((fen) => {
      const nodesWithSameFen = fenMap[fen];
      if (nodesWithSameFen.length > 1) {
        nodesWithSameFen.forEach((node, index) => {
          if (index < nodesWithSameFen.length - 1) {
            let node1 = node;
            let node2 = nodesWithSameFen[index + 1];

            console.log(node1, node2);

            // Obtenir les coordonnées dans le système de coordonnées SVG
            let node1Bounds = this.getTransformedPosition(
              node1 as SVGGraphicsElement
            );
            let node2Bounds = this.getTransformedPosition(
              node2 as SVGGraphicsElement
            );

            const svg = d3.selectAll("g.rd3t-g");

            if (svg.empty() || !node1Bounds || !node2Bounds) {
              console.warn("SVG non trouvé ou positions invalides");
              return;
            }

            // Assurez-vous que node1 est toujours à gauche de node2
            if (node2Bounds.x < node1Bounds.x) {
              // Inverser les coordonnées si node2 est à gauche de node1
              [node1Bounds, node2Bounds] = [node2Bounds, node1Bounds];
            }

            // Ajouter une ligne entre les deux nœuds (utilisant les positions dans le SVG)

            console.log(node1Bounds.x, node2Bounds.x);
            if (
              node1Bounds.x !== node2Bounds.x &&
              node2Bounds.x - node1Bounds.x > this.NODE_WIDTH * 2
            ) {
              svg
                .append("line")
                .attr("x1", node1Bounds.x + this.NODE_RADIUS)
                .attr("y1", node1Bounds.y - this.NODE_WIDTH)
                .attr(
                  "x2",
                  node1Bounds.x + this.NODE_RADIUS + this.NODE_WIDTH / 2
                )
                .attr("y2", node1Bounds.y - this.NODE_WIDTH)
                .attr("stroke", "red") // Couleur de la ligne
                .attr("stroke-width", "1") // Épaisseur de la ligne
                .attr("class", "link-active-dashed");

              svg
                .append("line")
                .attr(
                  "x1",
                  node1Bounds.x + this.NODE_RADIUS + this.NODE_WIDTH / 2
                )
                .attr("y1", node1Bounds.y - this.NODE_WIDTH)
                .attr(
                  "x2",
                  node1Bounds.x + this.NODE_RADIUS + this.NODE_WIDTH / 2
                )
                .attr("y2", node1Bounds.y - this.NODE_WIDTH * 1.5)
                .attr("stroke", "red") // Couleur de la ligne
                .attr("stroke-width", "1") // Épaisseur de la ligne
                .attr("class", "link-active-dashed");

              svg
                .append("line")
                .attr(
                  "x1",
                  node1Bounds.x + this.NODE_RADIUS + this.NODE_WIDTH / 2
                )
                .attr("y1", node1Bounds.y - this.NODE_WIDTH * 1.5)
                .attr(
                  "x2",
                  node2Bounds.x - this.NODE_WIDTH / 2 - this.NODE_RADIUS
                )
                .attr("y2", node1Bounds.y - this.NODE_WIDTH * 1.5)
                .attr("stroke", "red") // Couleur de la ligne
                .attr("stroke-width", "1") // Épaisseur de la ligne
                .attr("class", "link-active-dashed");

              svg
                .append("line")
                .attr(
                  "x1",
                  node2Bounds.x - this.NODE_WIDTH / 2 - this.NODE_RADIUS
                )
                .attr("y1", node1Bounds.y - this.NODE_WIDTH * 1.5)
                .attr(
                  "x2",
                  node2Bounds.x - this.NODE_WIDTH / 2 - this.NODE_RADIUS
                )
                .attr("y2", node2Bounds.y - this.NODE_WIDTH)
                .attr("stroke", "red") // Couleur de la ligne
                .attr("stroke-width", "1") // Épaisseur de la ligne
                .attr("class", "link-active-dashed");

              svg
                .append("line")
                .attr(
                  "x1",
                  node2Bounds.x - this.NODE_WIDTH / 2 - this.NODE_RADIUS
                )
                .attr("y1", node2Bounds.y - this.NODE_WIDTH)
                .attr("x2", node2Bounds.x - this.NODE_RADIUS)
                .attr("y2", node2Bounds.y - this.NODE_WIDTH)
                .attr("stroke", "red") // Couleur de la ligne
                .attr("stroke-width", "1") // Épaisseur de la ligne
                .attr("class", "link-active-dashed");
            } else {
              svg
                .append("line")
                .attr("x1", node1Bounds.x + this.NODE_RADIUS)
                .attr("y1", node1Bounds.y - this.NODE_WIDTH)
                .attr("x2", node2Bounds.x - this.NODE_RADIUS)
                .attr("y2", node2Bounds.y - this.NODE_WIDTH)
                .attr("stroke", "red") // Couleur de la ligne
                .attr("stroke-width", "1") // Épaisseur de la ligne
                .attr("class", "link-active-dashed");
            }
            // svg
            //   .append("line")
            //   .attr("x1", node1Bounds.x + this.NODE_RADIUS)
            //   .attr("y1", node1Bounds.y - this.NODE_WIDTH)
            //   .attr("x2", node2Bounds.x - this.NODE_RADIUS)
            //   .attr("y2", node2Bounds.y - this.NODE_WIDTH)
            //   .attr("stroke", "red") // Couleur de la ligne
            //   .attr("stroke-width", "1") // Épaisseur de la ligne
            //   .attr("class", "link-active-dashed");
            //   } else
            //     // svg
            //     //   .append("line")
            //     //   .attr("x1", node1Bounds.x + this.NODE_RADIUS)
            //     //   .attr("y1", node1Bounds.y - this.NODE_WIDTH)
            //     //   .attr("x2", node2Bounds.x - this.NODE_RADIUS)
            //     //   .attr("y2", node2Bounds.y - this.NODE_WIDTH)
            //     //   .attr("stroke", "red") // Couleur de la ligne
            //     //   .attr("stroke-width", "1") // Épaisseur de la ligne
            //     //   .attr("class", "link-active-dashed");
            // }
          }
        });
      }
    });
  }

  public addIconToLinks() {
    if ("modaltreecreation" === this.TREE_ID) {
      const links = document.querySelectorAll(".modaltreecreation");

      links.forEach((link) => {
        // Récupérer les coordonnées du lien (attribut 'd')
        const path = link as SVGPathElement;

        // Calculer la longueur totale du chemin
        const totalLength = path.getTotalLength();

        // Trouver le point au milieu du chemin
        const midpoint = path.getPointAtLength(totalLength / 2);

        const transformedX = midpoint.x;
        const transformedY = midpoint.y;

        const iconSize = 14; // Taille de l'icône

        // Récupérer l'historique à partir de la classe du lien
        const linkClasses = link.classList; // Liste des classes de `link`

        // Trouver la classe qui commence et se termine par un underscore
        const historyClass = Array.from(linkClasses).find((cls) => {
          return cls.startsWith("_") && cls.endsWith("_");
        });

        let history: string[] = [];

        if (historyClass) {
          // Enlever les underscores au début et à la fin
          const historyString = historyClass.slice(1, -1);

          // Diviser la chaîne en un tableau d'historique
          history = historyString.split("-");
        }

        // Créer un élément 'g' pour contenir le composant React
        const gElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g"
        );

        // Ajouter le 'g' dans le même parent que le lien
        link.parentNode?.appendChild(gElement);

        // Rendre le composant React dans l'élément 'g'
        const root = createRoot(gElement);
        root.render(
          <LinksIcon
            transformedX={transformedX}
            transformedY={transformedY}
            iconSize={iconSize}
            history={history}
          />
        );
      });
    }
  }
}

export default TreeD3Manager;
