import React, { useEffect, useRef, useState } from "react";
import { defaultPieces } from "../../assets/svg/pieces";
import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import { getPieceFromMove } from "../../utils/utils";
import ChessboardCours from "../commun/ChessboardCours/ChessboardCours";
import InfoCreateur from "../commun/InfoGeneralOpenning/InfoCreateur/InfoCreateur";
import PanelContainer from "../commun/Panel/Panel";
import ProgressBarComponent from "../commun/ProgressBar/ProgressBarComponent";
import EvaluationDetails from "../TreeAndDetails/EvaluationDetails/EvaluationDetails";
import MoveDetails from "../TreeAndDetails/MoveDetails/MoveDetails";
import SessionStats from "../TreeAndDetails/SessionStats/SessionStats";
import TreeAndMoveDetails from "../TreeAndDetails/TreeAndDetails";
import "./ChessboardCoursEntrainement.scss";

const ChessboardCoursEntrainement: React.FC = () => {
  let MIN_COLUMN_WIDTH = 400; // Constante pour la largeur minimale
  const { currentPage, infoOpeningData, mode, currentMove } =
    useLessonOpeningStore();

  const [boardWidth, setBoardWidth] = useState(400);
  const containerRef = useRef<HTMLDivElement>(null);
  const pieces = defaultPieces({ width: "", height: "" });
  const piece = getPieceFromMove(currentMove.san, currentMove.history);
  const [viewMode, setViewMode] = useState<"computer" | "tablet" | "mobile">(
    window.innerWidth > 920
      ? "computer"
      : window.innerWidth > 769
      ? "tablet"
      : "mobile"
  ); // Détecte la vue initiale

  useEffect(() => {
    const updateView = () => {
      const width = window.innerWidth;

      if (width > 920) {
        setViewMode("computer");
        MIN_COLUMN_WIDTH = 400;
      } else if (width > 769) {
        setViewMode("tablet");
        MIN_COLUMN_WIDTH = 320;
      } else {
        setViewMode("mobile");
        MIN_COLUMN_WIDTH = 0;

        // Logique pour ordinateur et tablette
        if (containerRef.current) {
          const totalWidth = containerRef.current.clientWidth;
          const height = (containerRef.current.clientHeight * 2) / 3;

          // Calculer une largeur maximale pour la première colonne
          const availableWidthForBoard = totalWidth - MIN_COLUMN_WIDTH;
          const newBoardWidth = Math.min(availableWidthForBoard, height);

          // Mettre à jour la largeur uniquement si elle change
          setBoardWidth(newBoardWidth);
        }

        return;
      }

      // Logique pour ordinateur et tablette
      if (containerRef.current) {
        const totalWidth = containerRef.current.clientWidth;
        const height = containerRef.current.clientHeight;

        // Calculer une largeur maximale pour la première colonne
        const availableWidthForBoard = totalWidth - MIN_COLUMN_WIDTH;
        const newBoardWidth = Math.min(availableWidthForBoard, height);

        // Mettre à jour la largeur uniquement si elle change
        setBoardWidth(newBoardWidth);
      }
    };

    // Mise à jour lors du redimensionnement
    window.addEventListener("resize", updateView);

    // Observer les changements de dimension du conteneur
    const resizeObserver = new ResizeObserver(updateView);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      updateView(); // Initialisation
    }

    return () => {
      window.removeEventListener("resize", updateView);
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []); // Pas de dépendances nécessaires

  return (
    <>
      {viewMode === "computer" && (
        <div
          className="chessboardCoursEntrainement_viewComputer"
          ref={containerRef}
          style={{
            gridTemplateColumns: `${boardWidth}px minmax(${MIN_COLUMN_WIDTH}px, 500px)`,
          }}
        >
          <div
            className="chessboard-item"
            style={{
              maxHeight: `${boardWidth}px`,
            }}
          >
            <ChessboardCours boardSize={boardWidth} />
          </div>
          <div
            className="tree-details-item"
            style={{
              maxHeight: `${boardWidth}px`,
              maxWidth: "500px",
              minWidth: "400px",
              flex: 1,
            }}
          >
            <TreeAndMoveDetails />
          </div>
        </div>
      )}

      {viewMode === "tablet" && (
        <div
          className="chessboardCoursEntrainement_viewTablette"
          style={{
            gridTemplateColumns: `${boardWidth}px minmax(${MIN_COLUMN_WIDTH}px, 500px)`,
          }}
        >
          {/* <InfoCreateur /> */}
          <div className="content" ref={containerRef}>
            <div
              className="chessboard-item"
              style={{
                maxHeight: `${boardWidth}px`,
                maxWidth: `${boardWidth}px`,
              }}
            >
              <ChessboardCours boardSize={boardWidth} />
            </div>
            <div
              className="tree-details-item"
              style={{
                maxHeight: `${boardWidth}px`,

                minWidth: "320px",
                maxWidth: "320px",
              }}
            >
              <TreeAndMoveDetails />
            </div>
          </div>
          <div className="outil">
            <PanelContainer />
            <ProgressBarComponent
              percentage={(currentPage / (infoOpeningData.nbrOfPath - 1)) * 100}
            />
          </div>
        </div>
      )}

      {viewMode === "mobile" && (
        <div className="chessboardCoursEntrainement_mobile">
          <InfoCreateur />
          <div className="content" ref={containerRef}>
            <div className="chessboardContent">
              <div className="chessboard-item">
                <ChessboardCours boardSize={boardWidth} />
              </div>
            </div>
            <div
              className="tree-details-item custom-scrollbar"
              style={{
                maxHeight: "auto",
                width: "100%",
              }}
            >
              {mode === "cours" && <MoveDetails />}
              {mode === "graduation" && <EvaluationDetails />}
              {mode === "entrainement" && <SessionStats />}
            </div>
          </div>
          <div className="outil">
            <PanelContainer />
            <ProgressBarComponent
              percentage={(currentPage / (infoOpeningData.nbrOfPath - 1)) * 100}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChessboardCoursEntrainement;
