import React, { useCallback, useRef } from "react";
import { FaCheck, FaTimes } from "react-icons/fa"; // Import des icônes
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "react-modal";

import "./ImageCropModal.scss";

interface ImageCropModalProps {
  src: string | null;
  crop: Crop;
  setCrop: (crop: Crop) => void;

  onCancel: () => void;
  onValidate: (croppedImage: string) => void;
}

const ImageCropModal: React.FC<ImageCropModalProps> = ({
  src,
  crop,
  setCrop,
  onCancel,
  onValidate,
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      imgRef.current = e.currentTarget;
    },
    []
  );

  const getCroppedImage = useCallback(async () => {
    if (imgRef.current && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx?.drawImage(
        imgRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg");
      onValidate(base64Image);
    }
  }, [crop, onValidate]);

  return (
    <Modal
      isOpen={!!src}
      contentLabel="Recadrer l'image"
      className="modal"
      overlayClassName="overlayImageCropModal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      {src && (
        <div className="crop-container">
          <ReactCrop crop={crop} onChange={(c) => setCrop(c)} aspect={1}>
            <img src={src} onLoad={onImageLoad} alt="Crop me" loading="lazy" />
          </ReactCrop>

          <div className="modal-buttons">
            <button className="validate-btn" onClick={getCroppedImage}>
              <FaCheck /> Valider
            </button>
            <button className="cancel-btn" onClick={onCancel}>
              <FaTimes /> Annuler
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ImageCropModal;
