import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import { SidebarCustom } from "../SidebarCustom/SidebarCustom";
import "./header.scss";

export default function Header() {
  return (
    <>
      <div className="header">
        <SidebarCustom />
        <LanguageDropdown />
      </div>
    </>
  );
}
