import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { GiWhiteBook } from "react-icons/gi";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { IoIosAddCircle } from "react-icons/io";
import {
  IoMailOpenOutline,
  IoPersonCircleOutline,
  IoStar,
} from "react-icons/io5";
import { MdMenu } from "react-icons/md";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../../assets/logo.png"; // Importer le logo
import icons from "../../../assets/svg/icons";
import profil from "../../../assets/user.png";
import { useAuthPremium } from "../../../contexts/AuthPremiumContext";
import { useUserStore } from "../../../store/useUserStore";
import "./SidebarCustom.scss";

const API_URL = process.env.REACT_APP_API_URL || "";

export function SidebarCustom() {
  const { isLogged, setIsLogged, role, pseudo, isPremium } = useUserStore();
  const [toggled, setToggled] = React.useState(false);
  const { checkAccess } = useAuthPremium();
  const [statutPremium, setStatutPremium] = useState(false); // Nouvel état local
  const [buildNumber, setBuildNumber] = useState("Loading...");
  const navigate = useNavigate();

  useEffect(() => {
    const herokuBuildNumber =
      process.env.REACT_APP_HEROKU_RELEASE_VERSION || "N/A";
    setBuildNumber(herokuBuildNumber);
  }, []);

  useEffect(() => {
    let estMonté = true;

    const vérifierPremium = async () => {
      const statut = await isPremium();
      if (estMonté) {
        setStatutPremium(statut);
      }
    };

    vérifierPremium();

    return () => {
      estMonté = false;
    };
  }, [isPremium]);

  // Fonction pour fermer la sidebar en cliquant à l'extérieur
  const closeSidebar = () => {
    setToggled(false);
  };

  const handleConnected = async () => {
    if (isLogged) {
      try {
        // Appeler l'API de déconnexion pour supprimer le cookie
        await axios.post(`${API_URL}/api/logout`);
        toast.error("Vous êtes maintenant déconnecté", {
          autoClose: 4000, // Ferme automatiquement après 4 secondes
          position: "top-right",
          theme: "colored",
        });
        setIsLogged(false); // Mettre à jour l'état local
      } catch (error) {
        console.error("Erreur lors de la déconnexion :", error);
      }
    } else {
      checkAccess("login");
    }
  };

  return (
    <>
      {/* Bouton pour ouvrir/fermer la sidebar */}
      <div className="sidebarCustom_menu" onClick={() => setToggled(!toggled)}>
        <MdMenu />
      </div>

      {/* Afficher un backdrop transparent pour fermer en cliquant à l'extérieur */}
      {toggled && <div className="sb-backdrop" onClick={closeSidebar} />}

      {/* Sidebar qui passe au-dessus du contenu */}
      <div className={`sb-sidebar ${toggled ? "active" : ""}`}>
        <Sidebar>
          <Menu className="sb-menu">
            {/* Modification ici pour inclure l'image du logo et le texte "Learn Openings" */}
            <MenuItem
              icon={
                <div className="logo-circle" onClick={() => navigate("/")}>
                  <img
                    src={logo}
                    alt="Learn Openings Logo"
                    className="sb-logo"
                    loading="lazy"
                  />
                </div>
              }
              className="sb-menu-item logo-menu-item"
              onClick={() => navigate("/")}
            >
              <span className="sb-logo-text">Learn Openings</span>
            </MenuItem>

            <MenuItem
              icon={<IoPersonCircleOutline name="bar-chart" />}
              className="sb-menu-item"
              onClick={() => navigate("/profil")}
            >
              Profil
            </MenuItem>

            <SubMenu
              className="sb-menu-item"
              icon={<GiWhiteBook />}
              label="Opennings"
            >
              <MenuItem
                icon={<FaMagnifyingGlass />}
                className="sb-menu-item"
                onClick={() => navigate("/")}
              >
                Explorer
              </MenuItem>
              <MenuItem
                icon={<IoStar />}
                className="sb-menu-item"
                onClick={() => navigate("/myopenning")}
              >
                My openings
              </MenuItem>
            </SubMenu>

            {/* {!statutPremium && (
              <MenuItem
                icon={icons({ width: 15, height: 15 }).premium}
                className="sb-menu-item"
                onClick={() => navigate("/payment")}
              >
                Premium
              </MenuItem>
            )} */}

            <MenuItem
              icon={<IoMailOpenOutline name="bar-chart" />}
              className="sb-menu-item"
              onClick={() => navigate("/contact")}
            >
              Contact us
            </MenuItem>

            {role === "creator" || role === "admin" ? (
              <SubMenu
                className="sb-menu-item"
                icon={<HiMiniWrenchScrewdriver />}
                label="Outils"
              >
                <MenuItem
                  icon={<IoIosAddCircle />}
                  className="sb-menu-item"
                  onClick={() => navigate("/OpeningManagement")}
                >
                  Management Opening
                </MenuItem>
              </SubMenu>
            ) : null}
          </Menu>

          <div className="sb-loginDisconnect-version">
            <div
              onClick={handleConnected}
              className={isLogged ? "disconnect-section" : "login-section"}
            >
              {isLogged ? (
                <>
                  {icons({}).disconnect}
                  <div className="image-login-pseudo">
                    <img
                      src={`${API_URL}/user/picture/${pseudo}`}
                      alt="Profil"
                      className="image-profil"
                      onError={(e) => (e.currentTarget.src = profil)} // Remplace l'image si elle échoue
                      loading="lazy"
                    />
                    {pseudo}
                  </div>
                </>
              ) : (
                <> {icons({}).login} Login</>
              )}
              <div
                className={`${isLogged ? "connected" : "disconnected"}`}
              ></div>
            </div>

            <div className="legal-section">
              Mention legal ------- Build {buildNumber}
            </div>
          </div>
        </Sidebar>
      </div>
    </>
  );
}
