// AccessDenied.tsx
import { Link } from "react-router-dom";
import LoaderChess from "../../components/commun/LoaderChess/LoaderChess";
import "./AccessDenied.scss"; // Lien vers le fichier SCSS

const AccessDenied = () => {
  return (
    <div className="access-denied-container">
      <h1>Accès refusé</h1>
      <LoaderChess />
      <Link to="/" className="home-link">
        Retour à la page d'accueil
      </Link>
    </div>
  );
};

export default AccessDenied;
