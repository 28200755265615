import React, { useEffect, useMemo, useRef, useState } from "react";
import Tree from "react-d3-tree";
import { useAuthPremium } from "../../contexts/AuthPremiumContext";
import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import { OpeningMove } from "../../types";
import TreeManager from "../../utils/TreeManager";
import ChessboardPreview from "../commun/PreviewChessboard/PreviewChessboard";
import "./OpeningVariantTree.scss";
import { renderCustomNode } from "./RenderCustomNode/renderCustomNode";

export const OpeningVariantTree: React.FC = () => {
  const {
    getOpeningTreeConverted,
    loadMoveWrapper,
    currentMove,
    infoOpeningData,
  } = useLessonOpeningStore();

  const { pathWithAccessCheck } = useAuthPremium();

  const treeData = getOpeningTreeConverted();

  const { ancestors, targetNode } = useMemo(() => {
    if (currentMove?.history && treeData) {
      return TreeManager.findTargetNodeByHistory(treeData, currentMove.history);
    }
    return { ancestors: [], targetNode: null };
  }, [treeData, currentMove?.history]);

  const subsetTreeData = useMemo(() => {
    if (targetNode && ancestors.length >= 0) {
      return TreeManager.buildSubsetTree(ancestors, targetNode);
    } else if (treeData) {
      return {
        ...treeData,
        isAncestorNode: false,
        children: TreeManager.getDirectChildren(treeData),
      };
    }
    return undefined;
  }, [ancestors, targetNode, treeData]);

  const loadMoveWraperWithCheck = async (moveDetail: OpeningMove) => {
    await pathWithAccessCheck(
      () => loadMoveWrapper(moveDetail),
      moveDetail.path[0]
    );
  };

  const chessboardPreviewRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      if (entries[0].contentRect) {
        setContainerDimensions({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      }
    };

    const observer = new ResizeObserver((entries) => handleResize(entries));

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Calcul dynamique de la translation pour centrer l'arbre
  const { translateX, translateY } = useMemo(() => {
    return {
      translateX: containerDimensions.width / 2,
      translateY: (containerDimensions.height * 0.4) / 2,
    };
  }, [containerDimensions]);

  // Calcul dynamique de la taille des nœuds
  const nodeSize = useMemo(() => {
    const size = Math.min(
      containerDimensions.width,
      containerDimensions.height
    );
    const nodeWidth = size * 0.3; // Ajustez le pourcentage selon vos besoins
    const nodeHeight = size * 0.3;
    return { x: nodeWidth, y: nodeHeight };
  }, [containerDimensions]);

  // Calcul dynamique de la séparation entre les nœuds
  const separation = useMemo(() => {
    const sep = containerDimensions.width * 0.1; // Ajustez le coefficient selon vos besoins
    return {
      siblings: 1,
      nonSiblings: sep,
    };
  }, [containerDimensions]);

  // Calcul dynamique de la séparation entre les nœuds
  const rayonNode = useMemo(() => {
    // Ajustez le coefficient selon vos besoins
    return 12;
  }, [containerDimensions]);

  return (
    <div className="openingVariantTree" ref={containerRef}>
      {subsetTreeData && (
        <Tree
          data={subsetTreeData}
          renderCustomNodeElement={(rd3tProps) =>
            renderCustomNode(
              rd3tProps,
              chessboardPreviewRef, // Corrected order
              currentMove,
              loadMoveWraperWithCheck,
              undefined, // handleCloseModal (optional)
              undefined, // idTree (optional)
              rayonNode // rayonNode
            )
          }
          nodeSize={nodeSize} // Taille des nœuds dynamique
          separation={separation} // Séparation dynamique
          translate={{ x: translateX, y: translateY }} // Translation dynamique
          pathClassFunc={(linkData: any) => {
            const sourceNode = linkData.source;
            const targetNode = linkData.target;

            if (sourceNode.depth === 0 && targetNode.depth === 1) {
              return "link-ancestor";
            }
            if (sourceNode.depth === 1 && targetNode.depth === 2) {
              return "link-active";
            }
            return "link-inactive";
          }}
          orientation="vertical"
        />
      )}

      <ChessboardPreview
        ref={chessboardPreviewRef}
        color={infoOpeningData.color}
      />
    </div>
  );
};
