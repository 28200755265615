import axios from 'axios';
import React, { useState } from 'react';
import { useUserStore } from '../../../../store/useUserStore';
import './EmailSignInForm.scss';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL || '';

const EmailSignInForm: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { checkLoginStatus } = useUserStore();

  const isSignUp = tabValue === 1;

  const handleTabChange = (newValue: number) => {
    setTabValue(newValue);
    setEmailError('');
    setPasswordError('');
  };

  const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError('Veuillez entrer une adresse email valide.');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (isSignUp && newPassword.length < 8) {
      setPasswordError('Le mot de passe doit comporter au moins 8 caractères.');
    } else {
      setPasswordError('');
    }
  };

  const isFormValid = () => {
    const isEmailValid = validateEmail(email);
    const isPasswordValid = isSignUp ? password.length >= 8 : password.length > 0;
    return isEmailValid && isPasswordValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid()) {
      toast.error("Le mot de passe ou l'adresse email n'est pas correctement rempli", {
        autoClose: 10000,
        position: "top-right",
        theme: "colored",
      });
      return;
    }
    try {
      const endpoint = isSignUp ? '/api/signup' : '/api/login';
      await axios.post(
        `${API_URL}${endpoint}`,
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      );
      const loginSuccessful = await checkLoginStatus();

      if (loginSuccessful) {
        // Show success toast if login was successful
        toast.success("Vous êtes maintenant connecté", {
          autoClose: 4000, // Ferme automatiquement après 10 secondes
          position: "top-right",
          theme: "colored",
        });
      }
    } catch (error: any) {
      // Traiter les erreurs renvoyées par axios
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Erreur : ${error.response.data.message}`, {
          autoClose: 10000,
          position: "top-right",
          theme: "colored",
        });
      } else {
        toast.error("L'authentification a échoué. Veuillez réessayer.", {
          autoClose: 10000,
          position: "top-right",
          theme: "colored",
        });
      }
    }
  };

  return (
    <div className="email-sign-in-form">
      <div className="tabs">
        <button
          className={`tab ${tabValue === 0 ? 'active' : ''}`}
          onClick={() => handleTabChange(0)}
        >
          Connexion
        </button>
        <button
          className={`tab ${tabValue === 1 ? 'active' : ''}`}
          onClick={() => handleTabChange(1)}
        >
          Inscription
        </button>
      </div>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          required
          className={`input ${emailError ? 'input-error' : ''}`}
        />
        {emailError && <div className="error-message">{emailError}</div>}

        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={handlePasswordChange}
          required
          className={`input ${passwordError ? 'input-error' : ''}`}
        />
        {passwordError && <div className="error-message">{passwordError}</div>}

        <button
          type="submit"
          className={`submit-button ${!isFormValid() ? 'disabled' : ''}`}
          disabled={!isFormValid()}
        >
          {isSignUp ? 'Inscription' : 'Connexion'}
        </button>
      </form>
    </div>
  );
};

export default EmailSignInForm;
