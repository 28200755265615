import React from "react";
import useCreateOpeningStore from "../../../../../store/useCreateOpeningStore";
import { OpeningMove, TreeNode } from "../../../../../types";
import "./NodeWithSingleChild.scss"; // Ajoute un fichier de styles dédié

interface NodeWithSingleChildProps {
  nodeDatum: TreeNode;
  handleMouseEnter: (
    event: React.MouseEvent,
    history: string[],
    comment?: string
  ) => void;
  handleMouseLeave: () => void;
  currentMove?: OpeningMove; // Ajout d'une propriété pour déterminer l'état actif
  forceActiveClass?: "activeGreen" | "activeOrange"; // Prop facultative pour forcer une classe active
}

const NodeWithSingleChild: React.FC<NodeWithSingleChildProps> = ({
  nodeDatum,
  handleMouseEnter,
  handleMouseLeave,
  forceActiveClass, // Nouvelle prop pour forcer une classe
}) => {
  const { selectedNode, addMultiPath, setSelectedNode, infoOpeningData } =
    useCreateOpeningStore();

  let isActive = false;
  let isClick = false;

  const firstChildKey = Object.keys(nodeDatum?.move?.children || {})[0];
  const firstChildHasNoPath =
    nodeDatum?.move?.children[firstChildKey]?.path?.length;

  const isPremium = infoOpeningData.pathForPremium! <= nodeDatum.move.path[0];
  if (
    nodeDatum?.move?.path.length >=
    Object.keys(nodeDatum?.move?.children).length
  ) {
    isActive = true;

    if (firstChildHasNoPath === 0) {
      isActive = false;
      isClick = true;
    }
  }

  const handleClick = () => {
    if (selectedNode) {
      addMultiPath(selectedNode, nodeDatum);
      setSelectedNode(null);
    } else {
      console.log(nodeDatum);
      setSelectedNode(nodeDatum);
    }
  };

  return (
    <g
      className={`nodeWithSingleChild ${
        forceActiveClass // Priorité à la prop pour forcer l'état actif
          ? forceActiveClass
          : isActive
          ? isPremium
            ? "activeOrange" // Inversement : premium devient 'activeOrange'
            : "activeGreen" // Non-premium devient 'activeGreen'
          : isClick
          ? "nodeClickStart"
          : "inactive"
      }`}
      onClick={isClick ? (e) => handleClick() : () => {}}
      onMouseEnter={(e) => handleMouseEnter(e, nodeDatum?.move?.history || [])}
      onMouseLeave={handleMouseLeave}
    >
      <circle cx="0" cy="0" r="12" strokeWidth="1" />
      <text
        x="0"
        y="0"
        textAnchor="middle"
        fontSize="10"
        fill="white"
        fontWeight="normal"
        dominantBaseline="central"
      >
        {nodeDatum.name}
      </text>
    </g>
  );
};

export default NodeWithSingleChild;
