// TreeLinkManager.ts

import { OpeningMove } from "../types"; // Assurez-vous d'importer le type approprié

export class TreeLinkManager {
  private treeId: string;
  private currentMove: OpeningMove | null;

  constructor(treeId: string, currentMove: OpeningMove | null) {
    this.treeId = treeId;
    this.currentMove = currentMove;
  }

  // Fonction pour mettre à jour le coup actuel
  updateCurrentMove(currentMove: OpeningMove | null) {
    this.currentMove = currentMove;
  }

  // Méthode pour déterminer la classe du lien
  getPathClass(linkData: any): string {
    if (this.treeId === "modaltreecreation") {
      // Ajout de la logique pour vérifier si un élément du parent + 1 est dans l'enfant
      const parentNode = linkData.source;
      const childNode = linkData.target;

      const parentPath = parentNode?.data?.move?.path || [];
      const childPath = childNode?.data?.move?.path || [];

      // Vérifie si parentPath[i] + 1 est inclus dans childPath
      const hasConsecutiveElement = parentPath.some((parentStep: number) =>
        childPath.includes(parentStep + 1)
      );

      const history = childNode?.data?.move?.history || [];

      if (hasConsecutiveElement) {
        return `modaltreecreation link-ancestor _${history.join("-")}_`; // Retourne "link-ancestor" suivi du historique des coups
      }

      return `modaltreecreation _${history.join("-")}_`; // Retourne seulement l'history du noeud enfant pour retrouver le link dans l'arbre
    } else {
      const targetNode = linkData.target;

      const nodeHistory =
        targetNode?.data?.move?.history?.[0] === "Start"
          ? targetNode.data.move.history.slice(1)
          : targetNode?.data?.move?.history || [];

      const currentMoveHistory = this.currentMove?.history;

      if (currentMoveHistory && nodeHistory) {
        if (this.startsWithDynamicHistory(currentMoveHistory, nodeHistory)) {
          return "link-ancestor";
        }
      }
      return "link-active";
    }
  }

  // Méthode privée pour comparer les historiques
  private startsWithDynamicHistory(
    history: string[],
    dynamicHistory: string[]
  ): boolean {
    return dynamicHistory.every((move, index) => history[index] === move);
  }
}
