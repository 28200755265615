import { useEffect, useRef, useState } from "react";
import { Chessboard } from "react-chessboard";
import "./Test.scss";

export default function Test() {
  const MIN_COLUMN_WIDTH = 200; // Constante pour la largeur minimale
  const [boardWidth, setBoardWidth] = useState(400);
  const testRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateBoardWidth = () => {
      if (testRef.current) {
        const totalWidth = testRef.current.clientWidth;
        const height = testRef.current.clientHeight;

        // Calculer une largeur maximale pour la première colonne
        const availableWidthForBoard = totalWidth - MIN_COLUMN_WIDTH; // Laissez au moins MIN_COLUMN_WIDTH pour la deuxième colonne
        const newBoardWidth = Math.min(availableWidthForBoard, height);

        // Mettez à jour la largeur uniquement si elle change
        if (boardWidth !== newBoardWidth) {
          setBoardWidth(newBoardWidth);
        }
      }
    };

    const resizeObserver = new ResizeObserver(updateBoardWidth);

    if (testRef.current) {
      resizeObserver.observe(testRef.current);
      updateBoardWidth(); // Initialisation
    }

    return () => {
      if (testRef.current) {
        resizeObserver.unobserve(testRef.current);
      }
    };
  }, [boardWidth]);

  return (
    <div
      className="test"
      ref={testRef}
      style={{
        gridTemplateColumns: `${boardWidth}px minmax(${MIN_COLUMN_WIDTH}px, 1fr)`,
      }}
    >
      <div
        className="conteneur"
        style={{
          maxHeight: `${boardWidth}px`,
        }}
      >
        <Chessboard boardWidth={boardWidth} />
      </div>
      <div
        className="conteneur"
        style={{
          maxHeight: `${boardWidth}px`,
        }}
      >
        {/* Contenu dynamique ici */}
        <p>Deuxième colonne avec une largeur minimum garantie</p>
      </div>
    </div>
  );
}
