import React, { createContext, ReactNode, useContext, useState } from "react";
import OpeningTreeModal from "../components/commun/OpeningTreeModal/OpeningTreeModal";
import { OpeningMove, TreeNode } from "../types"; // Assurez-vous que ces types sont importés

// Définir le type du contexte
interface ModalTreeContextProps {
  openModalTree: () => void;
  closeModalTree: () => void;
  setTreeNode: (node: TreeNode | null) => void;
  setCurrentMove: (move: OpeningMove | null) => void;
  setLoadMove: (loadMove: (move: OpeningMove) => void) => void;
  setTreeId: (id: string) => void;
  setLegende: (legende: React.ReactNode) => void;
  setColor: (color: "w" | "b") => void;
}

// Créer le contexte avec une valeur par défaut
const ModalTreeContext = createContext<ModalTreeContextProps | undefined>(
  undefined
);

// Créer le provider du contexte
export const ModalTreeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpenModalTree, setIsOpenModalTree] = useState(false);
  const [treeNode, setTreeNode] = useState<TreeNode | null>(null);
  const [treeId, setTreeId] = useState<string | null>(null);
  const [legende, setLegende] = useState<React.ReactNode | null>(null);
  const [currentMove, setCurrentMove] = useState<OpeningMove | null>(null);
  const [color, setColor] = useState<"w" | "b">("w");
  const [loadMove, setLoadMove] =
    useState<(move: OpeningMove) => void | null>();

  // Gestion de l'ouverture et fermeture de la modal
  const openModalTree = () => {
    setIsOpenModalTree(true);
  };
  const closeModalTree = () => setIsOpenModalTree(false);

  return (
    <ModalTreeContext.Provider
      value={{
        openModalTree,
        closeModalTree,
        setTreeNode,
        setCurrentMove,
        setLoadMove,
        setTreeId,
        setLegende,
        setColor,
      }}
    >
      {children}
      {isOpenModalTree && treeNode && currentMove && treeId && color && (
        <OpeningTreeModal
          treeNode={treeNode}
          currentMove={currentMove}
          loadMove={loadMove}
          open={isOpenModalTree}
          handleClose={closeModalTree}
          treeId={treeId}
          legende={legende}
          color={color}
        />
      )}
    </ModalTreeContext.Provider>
  );
};

// Hook pour consommer le contexte
export const useModalTree = () => {
  const context = useContext(ModalTreeContext);
  if (!context) {
    throw new Error("useModalTree must be used within a ModalTreeProvider");
  }
  return context;
};
