import { create } from "zustand";
import i18n from "../i18n/i18n"; // Importer i18n pour gérer les changements de langue
import { Language } from "../types";

interface SiteSettingsState {
  language: Language; // Utilisation du type Language
  setLanguage: (language: Language) => void;
}

// Fonction pour récupérer la langue par défaut du navigateur et la normaliser
const getBrowserLanguage = (): Language => {
  const browserLanguage = navigator.language.split("-")[0]; // On prend uniquement la partie "en", "fr", etc.
  const supportedLanguages: Language[] = ["en", "fr"]; // Ajouter ici toutes les langues supportées

  // Gérer le cas où fallbackLng peut être un tableau ou une chaîne
  const fallbackLanguage = Array.isArray(i18n.options.fallbackLng)
    ? i18n.options.fallbackLng[0] // Si c'est un tableau, on prend le premier élément
    : i18n.options.fallbackLng || "en"; // Si c'est une chaîne, on l'utilise directement ou on fallback sur "en"

  // Vérifier si la langue du navigateur est supportée, sinon utiliser la langue de secours
  const languageToSet = supportedLanguages.includes(browserLanguage as Language)
    ? (browserLanguage as Language)
    : (fallbackLanguage as Language);

  i18n.changeLanguage(languageToSet); // Mettre à jour la langue dans i18n dès que la langue par défaut est déterminée

  return languageToSet;
};

export const useSiteSettingsStore = create<SiteSettingsState>((set) => ({
  // Utilise la langue par défaut du navigateur
  language: getBrowserLanguage(), // Appelle la fonction pour obtenir la langue du navigateur

  setLanguage: (language: Language) =>
    set(() => {
      i18n.changeLanguage(language); // Mettre à jour la langue dans i18n
      return { language }; // Mettre à jour la langue dans Zustand
    }),

  // Mettre à jour le mode et initialiser la page d'entraînement à chaque passage en mode "entrainement"
}));
