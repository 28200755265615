import Quill from "quill";
import React, { useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { defaultPieces } from "../../../assets/svg/pieces";
import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import { useSiteSettingsStore } from "../../../store/useSiteSettingsStore";
import { Evaluation } from "../../../types";
import { getPieceFromMove } from "../../../utils/utils";
import "./MoveDetails.scss";

// Default evaluation messages
const defaultMessages = {
  mistake: "Coup sous-optimal dans cette position.",
  perfect: "Coup optimal, correspondant à la meilleure continuation.",
  error: "Coup incorrect ; des options plus efficaces sont disponibles.",
  good: "Coup correct et cohérent avec la position.",
};

// Custom embed for SVG icons
class SvgBlot extends Quill.import("blots/embed") {
  static blotName = "svgIcon";
  static tagName = "span";
  static className = "svg-icon";

  static create(value: any) {
    const node = super.create();
    node.innerHTML = value;
    return node;
  }

  static value(node: any) {
    return node.innerHTML;
  }
}

Quill.register(SvgBlot);

const MoveDetails: React.FC = () => {
  const { getComment, currentMove } = useLessonOpeningStore();
  const { language } = useSiteSettingsStore();
  const quillRef = useRef<ReactQuill | null>(null);

  const pieces = defaultPieces({ width: "24px", height: "24px" });
  const piece = getPieceFromMove(currentMove.san, currentMove.history);

  console.log(currentMove);
  // Determine evaluation message
  const validEvaluations = ["mistake", "perfect", "error", "good"];
  const message =
    getComment(currentMove.fen, language)?.slice(3) ||
    (validEvaluations.includes(currentMove.evaluation)
      ? defaultMessages[currentMove.evaluation as keyof Evaluation]
      : "Coup sans évaluation.");

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      // Réinitialiser le contenu en supprimant tout
      quill.deleteText(0, quill.getLength());

      // Générer les SVG sous forme de chaîne HTML uniquement si ce n'est pas le début
      const svgHtml =
        currentMove.history.length > 0 && piece && pieces[piece]
          ? ReactDOMServer.renderToStaticMarkup(pieces[piece])
          : null;

      // Générer la portion pour le coup uniquement si ce n'est pas le début
      const moveHtml =
        currentMove.history.length > 0
          ? `<strong>${currentMove.history.length}.${currentMove.san}</strong>&nbsp;&nbsp;`
          : "";

      const formattedHtml = `
           <p>

             ${svgHtml ? `<span class="svg-icon" >${svgHtml}</span>&nbsp` : ""}
             ${moveHtml}
             <span>${message}</span>
           </p>
         `;

      // Insérer le contenu HTML formaté dans Quill
      quill.clipboard.dangerouslyPasteHTML(0, formattedHtml);

      // Déplacer le curseur à la fin du contenu
      quill.setSelection(quill.getLength(), 0);
    }
  }, [piece, currentMove, getComment, language, pieces]);

  return (
    <div className="move-details-container">
      <div className="move-details-container_text custom-scrollbar">
        <ReactQuill
          ref={quillRef}
          readOnly={true}
          theme="bubble"
          modules={{ toolbar: false }}
        />
      </div>
    </div>
  );
};

export default MoveDetails;
