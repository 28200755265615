import { useState } from "react";
import Header from "../../components/commun/header/header";

import NotLogged from "../../components/PayementModal/NotLogged/NotLogged";
import TabContent from "../../components/TabNavigation/TabContent/TabContent";
import TabNavigation from "../../components/TabNavigation/TabNavigation";
import UserInfo from "../../components/UserInfo/UserInfo";
import { useUserStore } from "../../store/useUserStore";
import "./Profil.scss";
const API_URL = process.env.REACT_APP_API_URL || ""; // URL de base de l'API

export default function Profil() {
  const [activeTab, setActiveTab] = useState<"subscription" | "cours">(
    "subscription"
  );
  const { isLogged } = useUserStore();

  return (
    <div className="pageProfil">
      <Header />
      {isLogged ? (
        <div className="contentProfil">
          <UserInfo />
          <TabNavigation activeTab={activeTab} onTabChange={setActiveTab} />
          <TabContent activeTab={activeTab} />
        </div>
      ) : (
        <NotLogged message="Vous devez vous connecter pour acceder a la page profil" />
      )}
    </div>
  );
}
