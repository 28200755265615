import React from "react";
import { OpeningMove, TreeNode } from "../../../types";

import NodeBasic from "./NodeBasic/NodeBasic";
import NodeCreation from "./NodeCreation/NodeCreation";

interface CustomNodeProps {
  nodeDatum: TreeNode;
  handleCloseModal?: () => void;
  handleMouseEnter: (
    event: React.MouseEvent,
    history: string[],
    comment?: string
  ) => void;
  rayonNode?: number;
  handleMouseLeave: () => void;
  loadMove: (move: OpeningMove) => void;
  idTree?: string;
  currentMove?: OpeningMove | null; // Autorise null ici
}
const CustomNode: React.FC<CustomNodeProps> = ({
  nodeDatum,
  rayonNode = 12,
  handleCloseModal,
  handleMouseEnter,
  handleMouseLeave,
  idTree,
  currentMove,
  loadMove,
}) => {
  return (
    <>
      {/* Le reste du code */}
      {idTree === "modaltreecreation" ? (
        <NodeCreation
          nodeDatum={nodeDatum}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          currentMove={currentMove}
        />
      ) : (
        <NodeBasic
          nodeDatum={nodeDatum}
          rayonNode={rayonNode}
          handleMouseEnter={handleMouseEnter}
          handleCloseModal={handleCloseModal}
          handleMouseLeave={handleMouseLeave}
          loadMove={loadMove}
          currentMove={currentMove}
        />
      )}
    </>
  );
};

export default CustomNode;
