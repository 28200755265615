// VerticalPricingCard.tsx

import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import React from "react";
import { defaultPieces } from "../../../../../assets/svg/pieces";
import { useSubscriptionStore } from "../../../../../store/useSubscriptionStore";
import { InfoSubscription } from "../../../../../types";
import "./Vertical.scss";

interface VerticalPricingCardProps {
  data: InfoSubscription;
  isSubscription: boolean;
  isBuy: boolean;
}

const pieces = defaultPieces({ width: "70px", height: "70px" });

// Fonction pour convertir le code de devise en symbole
const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode.toUpperCase()) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    case "CAD":
      return "$";
    case "AUD":
      return "$";
    default:
      return currencyCode; // Retourner le code si aucun symbole n'est trouvé
  }
};

// Fonction pour convertir l'intervalle en texte approprié
const getIntervalText = (interval: string): string => {
  if (interval.includes("mois")) {
    const match = interval.match(/(\d+)\s*mois/);
    if (match && parseInt(match[1], 10) === 6) {
      return "Semestriel";
    }
    return "Mensuel";
  } else if (interval.includes("an")) {
    return "Annuel";
  }
  return interval; // Si l'intervalle n'est pas reconnu
};

const VerticalPricingCard: React.FC<VerticalPricingCardProps> = ({
  data,
  isBuy,
}) => {
  const { selectSubscription } = useSubscriptionStore();

  const originalPrice = data.originalPrice;
  const discountedPrice = parseFloat(data.price);
  const discountPercentage = data.discount;

  return (
    <div className="pricing-card vertical">
      {discountPercentage > 0 && (
        <div className="discount-ribbon">
          <span>-{discountPercentage.toFixed(0)}%</span>
        </div>
      )}
      <div className="card-header">
        <div className="icon">{pieces.wK}</div>
        <h2>{getIntervalText(data.interval)}</h2>
      </div>

      {/* Section du prix */}
      <div className="card-price">
        {discountPercentage > 0 && originalPrice ? (
          <>
            {data.endDate && (
              <div className="timerPrincipal">
                <FlipClockCountdown
                  to={new Date(data.endDate || "").getTime()}
                  labelStyle={{
                    fontSize: 0,
                    color: "hsl(35, 65%, 75%)",
                  }}
                  separatorStyle={{ color: "hsl(0, 0%, 100%)", size: "6px" }}
                  digitBlockStyle={{
                    width: 18,
                    height: 27,
                    fontSize: 18,
                    color: "hsl(0, 0%, 100%)",
                  }}
                  labels={["D", "H", "M", "S"]}
                  showLabels={false}
                  duration={0.5}
                  renderMap={[false, true, true, true]}
                />
              </div>
            )}
            <div className="original-discounted">
              <span
                className="original-price"
                style={{ textDecoration: "line-through" }}
              >
                {originalPrice.toFixed(2)} {getCurrencySymbol(data.currency)}
              </span>
              <h4>
                <span className="discounted-price">
                  {discountedPrice.toFixed(2)}{" "}
                  {getCurrencySymbol(data.currency)}
                </span>
              </h4>
            </div>
          </>
        ) : (
          <h4>
            {data.price} {getCurrencySymbol(data.currency)}
          </h4>
        )}
      </div>

      <div className="card-features" style={{ textAlign: "center" }}>
        <ul>
          {data.features.map((feature: string, index: number) => (
            <li key={index}>
              <i className="fa fa-check" aria-hidden="true"></i>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      {isBuy && (
        <div className="paySubscription-button">
          <button onClick={() => selectSubscription(data)}>Buy Now</button>
        </div>
      )}
    </div>
  );
};

export default VerticalPricingCard;
