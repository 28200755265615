import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import axios from "axios";
import { useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import user from "../../../../assets/user.png";
import { useLessonOpeningStore } from "../../../../store/useLessonOpeningStore";
import "./InfoCreateur.scss";
const API_URL = process.env.REACT_APP_API_URL || "";

export default function InfoCreateur() {
  const { infoOpeningData } = useLessonOpeningStore();

  const [isFavorited, setIsFavorited] = useState(infoOpeningData.hasFavorited);
  const [isLikes, setIsLikes] = useState(infoOpeningData.hasLiked);
  const [isSubscription, setIsSubscription] = useState(
    infoOpeningData.hasSubscription
  );

  const toggleFavorite = async () => {
    const previousState = isFavorited;
    setIsFavorited(!isFavorited); // Changement optimiste
    try {
      const response = await axios.post(
        `${API_URL}/api/engagements/toggle/${infoOpeningData._id}`,
        { type: "favorite" },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      setIsFavorited(previousState); // Restaure la valeur initiale en cas d'échec
      console.error("Error toggling favorite", error);
    }
  };

  const toggleLikes = async () => {
    const previousLikes = isLikes;
    try {
      setIsLikes(!isLikes);
      const response = await axios.post(
        `${API_URL}/api/engagements/toggle/${infoOpeningData._id}`,
        { type: "like" },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      setIsLikes(previousLikes);
      console.error("Error toggling like", error);
    }
  };

  const toggleSubscription = async () => {
    const previousIsSubscribed = isSubscription;
    try {
      setIsSubscription(!isSubscription);
      const response = await axios.post(
        `${API_URL}/api/engagements/toggle/${infoOpeningData._id}`,
        { type: "subscribe" },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      setIsSubscription(previousIsSubscribed);
      console.error("Error toggling subscription", error);
    }
  };

  return (
    <div className="info-createur">
      <div className="card">
        <div className="creator-info">
          <div className="creator-image">
            <img
              src={`${API_URL}/user/picture/${infoOpeningData.creator}`}
              alt="Profil"
              className="creator-image_image"
              onError={(e) => (e.currentTarget.src = user)}
               loading="lazy"
            />
            <div className="creator-name-wrapper">
              <p className="creator-name">{infoOpeningData.creator.pseudo}</p>
            </div>
          </div>
          <div className="text-info">
            <h2 className="course-name">
              {infoOpeningData.title.toLocaleUpperCase()}
            </h2>
          </div>
          <div className="action-buttons">
            <button className="btn-likes" onClick={toggleLikes}>
              {isLikes ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </button>
            <button className="btn-favorite" onClick={toggleFavorite}>
              {isFavorited ? (
                <FaStar className="star" />
              ) : (
                <FaRegStar className="star" />
              )}
            </button>
            <button className="btn-subscribe" onClick={toggleSubscription}>
              {isSubscription ? (
                <NotificationsIcon />
              ) : (
                <NotificationsNoneIcon />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
