import React, { useEffect, useRef } from "react";
import { OpeningMove, TreeNode } from "../../../../types";
import "./NodeBasic.scss";

interface NodeBasicProps {
  nodeDatum: TreeNode;
  rayonNode: number;
  handleCloseModal?: () => void;
  handleMouseEnter: (
    event: React.MouseEvent,
    history: string[],
    comment?: string
  ) => void;
  handleMouseLeave: () => void;
  loadMove: (move: OpeningMove) => void;
  currentMove?: OpeningMove | null; // Autorise null ici
}

const NodeBasic: React.FC<NodeBasicProps> = ({
  nodeDatum,
  rayonNode,
  handleCloseModal,
  handleMouseEnter,
  handleMouseLeave,
  currentMove,
  loadMove,
}) => {
  const nodeRef = useRef<SVGGElement>(null);

  const handleClick = () => {
    if (nodeDatum.move) {
      loadMove(nodeDatum.move);
      if (handleCloseModal) handleCloseModal();
    }
  };

  useEffect(() => {
    if (nodeRef.current) {
      const { x, y } = nodeRef.current.getBoundingClientRect();
      nodeRef.current.setAttribute("data-x", x.toString());
      nodeRef.current.setAttribute("data-y", y.toString());
    }
  }, []);

  const isCurrentMove =
    currentMove && currentMove.path[0] === nodeDatum.move.path[0];

  return (
    <g
      ref={nodeRef}
      data-fen={nodeDatum.move.fen}
      onClick={handleClick}
      onMouseEnter={(e) => handleMouseEnter(e, nodeDatum.move.history || [])}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: "pointer" }}
    >
      <circle
        cx="0"
        cy="0"
        r={`${rayonNode}`}
        className={`nodeCircle ${isCurrentMove ? "shadow" : ""}`}
        strokeWidth="1"
      />
      <text
        x="0"
        y="0"
        textAnchor="middle"
        className="nodeText"
        fontSize="10"
        fontWeight="normal"
        dominantBaseline="central"
        stroke="none"
      >
        {nodeDatum.name}
      </text>
    </g>
  );
};

export default NodeBasic;
