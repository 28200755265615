import { useEffect, useRef, useState } from "react";
import Header from "../../components/commun/header/header";
import OpeningGrid from "../../components/commun/OpeningGrid/OpeningGrid";
import SearchBar from "../../components/commun/SearchBar/SearchBar";
import FilterOptions from "../../components/FilterOptions/FilterOptions";
import "./HomePage.scss";

const HomePage = () => {
  const [isSticky, setIsSticky] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (searchRef.current) {
        setIsSticky(searchRef.current.getBoundingClientRect().top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="contentHomePage">
      <Header />
      <div
        ref={searchRef}
        className={`contentHomePage_recherche ${
          isSticky ? "sticky-active" : ""
        }`}
      >
        <SearchBar />
        <FilterOptions />
      </div>
      <OpeningGrid />
    </div>
  );
};

export default HomePage;
