import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  FaCalendarAlt,
  FaCreditCard,
  FaGift,
  FaLock,
  FaSpinner,
} from "react-icons/fa";
import {
  SiAmericanexpress,
  SiMastercard,
  SiStripe,
  SiVisa,
} from "react-icons/si";
import { BeatLoader } from "react-spinners";
import {
  confirmPurchase,
  purchaseArticle,
  verifyPromoCode,
} from "../../../services/paymentService";
import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import { useSubscriptionStore } from "../../../store/useSubscriptionStore";
import PricingArticleCard from "../../commun/PricingCard/PricingArticleCard/PricingArticleCard";
import PricingCard from "../../commun/PricingCard/PricingSubscriptionCard/PricingSubscriptionCard";
import "./FormPayement.scss";

const FormPayement: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    selectedSubscription,
    selectedArticle,
    setLoadingPayment,
    applyDiscount,
  } = useSubscriptionStore();

  const { infoOpeningData } = useLessonOpeningStore();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [isVerifyingPromo, setIsVerifyingPromo] = useState(false);
  const [layout, setLayout] = useState<"vertical" | "horizontal">("vertical");

  const elementStyles = {
    base: {
      color: "#ffffff",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
    },
  };

  const elementOptions = { style: elementStyles };

  const handlePromoCodeChange = async (value: string) => {
    setPromoCode(value);

    if (value.length === 6) {
      setIsVerifyingPromo(true);
      try {
        const data = await verifyPromoCode(
          value,
          selectedSubscription?.priceID || ""
        );
        if (data.discountPercent) {
          applyDiscount(data.previewPrice);
          toast.success(
            `Code promo appliqué avec succès ! ${data.discountPercent}% de réduction`,
            { autoClose: 4000, position: "top-right", theme: "colored" }
          );
        } else {
          toast.error("Code promo invalide ou expiré", {
            autoClose: 4000,
            position: "top-right",
            theme: "colored",
          });
        }
      } catch (error: any) {
        toast.error(error.message, {
          autoClose: 4000,
          position: "top-right",
          theme: "colored",
        });
      } finally {
        setIsVerifyingPromo(false);
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      toast.error("Stripe n'est pas prêt, veuillez patienter et réessayer.");
      return;
    }

    if (!selectedArticle || !selectedArticle.productID) {
      toast.error(
        "Aucun article sélectionné. Veuillez choisir un article et réessayer."
      );
      return;
    }

    if (!infoOpeningData || !infoOpeningData._id) {
      toast.error("Données de l'article indisponibles. Veuillez réessayer.");
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      console.error("Élément CardNumber introuvable.");
      toast.error("Une erreur interne est survenue. Veuillez réessayer.");
      return;
    }

    setLoading(true);

    try {
      // Création de la méthode de paiement
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement,
        });

      if (paymentMethodError || !paymentMethod) {
        console.error(
          "Erreur lors de la création de la méthode de paiement:",
          paymentMethodError
        );
        throw new Error(
          "Le paiement a échoué. Veuillez vérifier votre carte et réessayer."
        );
      }

      setLoadingPayment(true);

      // Appel vers le backend pour initier l'achat (PaymentIntent)
      const data = await purchaseArticle(
        infoOpeningData._id,
        selectedArticle.productID,
        paymentMethod.id
      );

      if (data.error) {
        console.error("Erreur du backend :", data.error);
        throw new Error(data.error);
      }

      if (data.requiresAction && data.clientSecret) {
        // Authentification supplémentaire requise (3D Secure, etc.)
        const { error: confirmError, paymentIntent } =
          await stripe.confirmCardPayment(data.clientSecret);

        if (confirmError) {
          console.error("Erreur d'authentification 3D Secure:", confirmError);
          throw new Error("L'authentification a échoué. Veuillez réessayer.");
        }

        if (paymentIntent?.status === "succeeded") {
          toast.success("Paiement réussi !");
          setPaymentSuccess(true);

          // Appeler confirmPurchase si nécessaire
          if (paymentIntent?.id) {
            await confirmPurchase(paymentIntent.id);
          }
        } else {
          throw new Error(
            "Le paiement n'a pas abouti après l'authentification. Veuillez réessayer."
          );
        }
      } else if (data.succesBuy) {
        // Paiement réussi sans action supplémentaire
        toast.success(data.message || "Paiement réussi !");
        setPaymentSuccess(true);
      } else {
        // Cas où aucune condition n'est remplie (erreur inattendue)
        throw new Error(
          data.error ||
            "Le paiement n'a pas pu être complété. Veuillez réessayer."
        );
      }
    } catch (error: any) {
      console.error("Erreur dans le processus de paiement :", error.message);
      toast.error(
        error.message ||
          "Une erreur est survenue lors du paiement. Veuillez réessayer."
      );
    } finally {
      setLoading(false);
      setLoadingPayment(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setLayout(window.innerWidth <= 768 ? "horizontal" : "vertical");
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (paymentSuccess) {
      window.location.reload();
    }
  }, [paymentSuccess]);

  return (
    <div className="payment-form-container">
      <div className="payment-form-wrapper">
        {selectedSubscription && (
          <div className="payment-form__pricing-card">
            <PricingCard
              data={selectedSubscription}
              layout={layout}
              isBuy={false}
            />
          </div>
        )}
        {selectedArticle && (
          <div className="payment-form__pricing-card">
            <PricingArticleCard data={selectedArticle} isBuy={false} />
          </div>
        )}
        <form className="payment-form" onSubmit={handleSubmit}>
          <div className="payment-form__field">
            <label>
              <FaCreditCard className="payment-form__icon" /> Numéro de carte
            </label>
            <CardNumberElement options={elementOptions} />
          </div>

          <div className="payment-form__field">
            <label>
              <FaCalendarAlt className="payment-form__icon" /> Date d'expiration
            </label>
            <CardExpiryElement options={elementOptions} />
          </div>

          <div className="payment-form__field">
            <label>
              <FaLock className="payment-form__icon" /> Code CVC
            </label>
            <CardCvcElement options={elementOptions} />
          </div>

          <div className="payment-form__field">
            <label>
              <FaGift className="payment-form__icon" /> Code Promo
            </label>
            <div
              className="promo-code-input-wrapper"
              style={{ position: "relative" }}
            >
              <input
                type="text"
                value={promoCode}
                onChange={(e) =>
                  handlePromoCodeChange(e.target.value.toUpperCase())
                }
                maxLength={6}
                placeholder="XXXXXX"
                className="promo-code-input"
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.5em",
                  paddingRight: "2.5em",
                }}
              />
              {isVerifyingPromo && (
                <FaSpinner
                  className="promo-code-loader"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#ffffff",
                    animation: "spin 1s linear infinite",
                  }}
                />
              )}
            </div>
          </div>

          <button
            type="submit"
            className="payment-form__pay-button"
            disabled={!stripe || loading}
          >
            {loading ? (
              <div className="payment-button-content">
                <BeatLoader color="#ffffff" size={10} />
              </div>
            ) : (
              <div className="payment-button-content">
                {selectedArticle ? (
                  <span>Payer votre article</span>
                ) : (
                  <span>Payer votre Abonnement</span>
                )}
                <FaLock className="payment-form__icon" />
              </div>
            )}
          </button>

          {errorMessage && (
            <p className="payment-form__error-message">{errorMessage}</p>
          )}

          <p className="payment-form__security-message">
            <FaLock className="payment-form__icon" />
            Paiement 100% sécurisé via Stripe
          </p>
          <div className="payment-form__payment-logos">
            <SiVisa className="payment-form__payment-logo" />
            <SiMastercard className="payment-form__payment-logo" />
            <SiAmericanexpress className="payment-form__payment-logo" />
            <SiStripe className="payment-form__payment-logo" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormPayement;
