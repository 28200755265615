import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5"; // Importer l'icône de loupe
import { useSearchOpening } from "../../../store/useSearchOpening"; // Import du store
import "./SearchBar.scss";

const SearchBar = () => {
  const { searchOpeningTerm, setSearchOpeningTerm } = useSearchOpening();
  const [placeholderText, setPlaceholderText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopIndex, setLoopIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(false); // Pour savoir si l'utilisateur est en train de taper

  const { t } = useTranslation("homePage"); // Utiliser le namespace "home"

  const openings = [
    t("searchBar.italian"),
    t("searchBar.spanish"),
    t("searchBar.queenGambit"),
    t("searchBar.frenchDefense"),
    t("searchBar.caroKann"),
  ];
  const typingSpeed = 150; // Vitesse de l'écriture
  const deletingSpeed = 100; // Vitesse de l'effacement
  const delayBetweenOpenings = 1500; // Délai entre deux cycles

  useEffect(() => {
    if (isTyping) return; // Si l'utilisateur tape, on arrête l'animation du placeholder

    // Fonction pour gérer l'animation d'écriture et de suppression
    const handleTyping = () => {
      const currentOpening = openings[loopIndex % openings.length];

      if (isDeleting) {
        // Simule l'effacement
        setPlaceholderText((prev) => prev.slice(0, prev.length - 1));
        setTextIndex((prev) => prev - 1);
      } else {
        // Simule l'écriture
        setPlaceholderText(currentOpening.slice(0, textIndex + 1));
        setTextIndex((prev) => prev + 1);
      }

      // Une fois le texte totalement écrit, on attend avant de commencer à effacer
      if (!isDeleting && textIndex === currentOpening.length) {
        setTimeout(() => setIsDeleting(true), delayBetweenOpenings);
      }

      // Une fois le texte totalement effacé, on passe à l'ouverture suivante
      if (isDeleting && textIndex === 0) {
        setIsDeleting(false);
        setLoopIndex((prev) => prev + 1);
      }
    };

    const typingTimeout = setTimeout(
      handleTyping,
      isDeleting ? deletingSpeed : typingSpeed
    );

    return () => clearTimeout(typingTimeout);
  }, [textIndex, isDeleting, loopIndex, isTyping]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchOpeningTerm(event.target.value); // Met à jour le terme de recherche dans le store
    setIsTyping(true); // Désactive l'animation du placeholder quand l'utilisateur commence à taper
  };

  const handleBlur = () => {
    if (!searchOpeningTerm) {
      setIsTyping(false);
      setTextIndex(0);
      setIsDeleting(false);
      setPlaceholderText("");
    }
  };

  return (
    <div className="search-bar">
      <div className="input-wrapper">
        <input
          type="text"
          value={searchOpeningTerm}
          onChange={handleChange}
          onFocus={() => setIsTyping(true)}
          onBlur={handleBlur}
        />
        {!isTyping && (
          <div className="animated-placeholder">
            {placeholderText}
            <span className="cursor" />
          </div>
        )}
        {/* Icône de loupe à la fin de l'input */}
        <IoSearch className="search-icon" />
      </div>
    </div>
  );
};

export default SearchBar;
