import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import CustomGoogleSignInButton from '../CustomGoogleSignInButton/CustomGoogleSignInButton';
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';

interface GoogleSignInButtonProps {
  setIsLoading: (isLoading: boolean) => void;
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({ setIsLoading }) => {
  return (
    <div className="googleSignInButton">
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <CustomGoogleSignInButton setIsLoading={setIsLoading} />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleSignInButton;
