import { useState } from "react";
import { IoStar } from "react-icons/io5";
import "./LevelSelector.scss";

interface LevelSelectorProps {
  selectedLevel: 1 | 2 | 3 | null;
  onLevelChange: (level: 1 | 2 | 3 | null) => void;
}

const LevelSelector = ({
  selectedLevel,
  onLevelChange,
}: LevelSelectorProps) => {
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);

  const handleLevelClick = (level: 1 | 2 | 3) => {
    if (selectedLevel === level) {
      onLevelChange(null);
    } else {
      onLevelChange(level);
    }
  };

  const handleMouseEnter = (level: 1 | 2 | 3) => {
    setHoveredStar(level);
  };

  const handleMouseLeave = () => {
    setHoveredStar(null);
  };

  return (
    <div className="level-selector">
      {([1, 2, 3] as const).map((level) => (
        <span
          key={level}
          className={`star ${
            hoveredStar && level <= hoveredStar ? "hovered" : ""
          } ${selectedLevel && level <= selectedLevel ? "filled" : ""}`}
          onClick={() => handleLevelClick(level)}
          onMouseEnter={() => handleMouseEnter(level)}
          onMouseLeave={handleMouseLeave}
        >
          <IoStar />
        </span>
      ))}
    </div>
  );
};

export default LevelSelector;
