import confetti from "canvas-confetti";

export const fireConfetti = () => {
  // Canon tirant à gauche
  confetti({
    particleCount: 70,
    spread: 70,
    angle: 45, // Canon tirant en diagonale de la gauche vers le haut
    origin: { x: 0.0, y: 1 }, // Partie gauche en bas
    zIndex: 1000, // S'assurer que les confettis sont devant tout le reste
  });

  // Canon tirant à droite
  confetti({
    particleCount: 70,
    spread: 70,
    angle: 135, // Canon tirant en diagonale de la droite vers le haut
    origin: { x: 1, y: 1 }, // Partie droite en bas
    zIndex: 1000, // S'assurer que les confettis sont devant tout le reste
  });
};

export const fireworkConfetti = (): void => {
  const duration: number = 7 * 1000; // Durée de 15 secondes
  const animationEnd: number = Date.now() + duration;
  const defaults: {
    startVelocity: number;
    spread: number;
    ticks: number;
    zIndex: number;
  } = {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
  };

  // Fonction pour générer un nombre aléatoire dans une plage donnée
  function randomInRange(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  const interval: number = window.setInterval(function () {
    // Spécifie le type number pour un navigateur
    const timeLeft: number = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount: number = 50 * (timeLeft / duration);
    // Étant donné que les particules tombent vers le bas, on commence un peu plus haut que la position aléatoire
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    });
    confetti({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);
};
