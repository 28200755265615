import { create } from "zustand";

import { OpeningDataHomePage } from "../types";

interface SearchOpeningState {
  searchOpeningTerm: string;
  setSearchOpeningTerm: (term: string) => void;
  filteredOpenings: OpeningDataHomePage[]; // Liste filtrée d'ouvertures
  openingsData: OpeningDataHomePage[];

  setOpeningsData: (openings: OpeningDataHomePage[]) => void;
  setFilteredOpenings: (openings: OpeningDataHomePage[]) => void;
  filterColor: string | null; // Accepte string ou null
  setFilterColor: (color: string | null) => void;
  filterCategory: string | null; // Accepte string ou null
  setFilterCategory: (category: string | null) => void;
  filterLevel: number | null; // Nouveau filtre de niveau, accepte un entier ou null
  setFilterLevel: (level: number | null) => void;
  applyFilters: () => void; // Fonction pour appliquer les filtres
}

export const useSearchOpening = create<SearchOpeningState>((set, get) => ({
  searchOpeningTerm: "",
  setSearchOpeningTerm: (term: string) => {
    set({ searchOpeningTerm: term });
    get().applyFilters();
  }, // Appliquer les filtres après la mise à jour,
  filteredOpenings: [], // Initialiser avec les données mock
  openingsData: [],
  setFilteredOpenings: (openings: OpeningDataHomePage[]) =>
    set({ filteredOpenings: openings }),
  setOpeningsData: (openings: OpeningDataHomePage[]) =>
    set({ openingsData: openings, filteredOpenings: openings }),
  // Gestion de la couleur sélectionnée
  filterColor: null,
  setFilterColor: (color: string | null) => {
    const currentColor = get().filterColor;
    set({ filterColor: currentColor === color ? null : color });
    get().applyFilters(); // Appliquer les filtres après la mise à jour
  },

  // Gestion de la catégorie sélectionnée
  filterCategory: null,
  setFilterCategory: (category: string | null) => {
    const currentCategory = get().filterCategory;
    set({ filterCategory: currentCategory === category ? null : category });
    get().applyFilters(); // Appliquer les filtres après la mise à jour
  },

  // Gestion du niveau sélectionné
  filterLevel: null,
  setFilterLevel: (level: number | null) => {
    const currentLevel = get().filterLevel;
    set({ filterLevel: currentLevel === level ? null : level });
    get().applyFilters(); // Appliquer les filtres après la mise à jour
  },

  // Appliquer les filtres sur les données
  applyFilters: () => {
    const { filterColor, filterCategory, filterLevel, searchOpeningTerm } =
      get();
    let filteredData = get().openingsData;

    // Appliquer le filtre de couleur
    if (filterColor) {
      console.log(filterColor);
      filteredData = filteredData.filter(
        (opening: OpeningDataHomePage) => opening.color === filterColor
      );
    }

    // Appliquer le filtre de niveau
    if (filterLevel) {
      console.log(filterLevel);
      filteredData = filteredData.filter(
        (opening: OpeningDataHomePage) => opening.level === filterLevel
      );
    }

    // Fonction utilitaire pour enlever les accents
    const removeAccents = (str: string) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    // Appliquer le terme de recherche (si besoin)
    if (searchOpeningTerm) {
      const normalizedSearchTerm = removeAccents(
        searchOpeningTerm.toLowerCase()
      );

      filteredData = filteredData.filter((opening: OpeningDataHomePage) => {
        const normalizedOpeningName = removeAccents(
          opening.title.toLowerCase()
        );
        return normalizedOpeningName.includes(normalizedSearchTerm);
      });
    }

    // Mettre à jour les ouvertures filtrées
    set({ filteredOpenings: filteredData });
  },
}));
