import React from "react";
import "react-step-progress-bar/styles.css";
import "./ProgressBarComponent.scss"; // Importer le fichier SCSS

// Utiliser require pour importer la bibliothèque sans types
const ProgressBar = require("react-step-progress-bar").ProgressBar;

interface ProgressBarComponentProps {
  percentage: number; // La progression en pourcentage
}

const ProgressBarComponent: React.FC<ProgressBarComponentProps> = ({
  percentage,
}) => {
  return (
    <div className="progress-bar-container">
      <ProgressBar percent={percentage} />
    </div>
  );
};

export default ProgressBarComponent;
