import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { Chessboard } from "react-chessboard";
import { GameChess } from "../../../utils/GameChess";
import "./PreviewChessboard.scss";

interface ChessboardPreviewProps {
  color: "w" | "b";
  comments?: { [key: string]: string };
}

const ChessboardPreview = forwardRef((props: ChessboardPreviewProps, ref) => {
  const [hoveredMoveHistory, setHoveredMoveHistory] = useState<string[] | null>(
    null
  );
  const [previewPosition, setPreviewPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const gameChess = new GameChess();

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent, history: string[], comment?: string) => {
      setHoveredMoveHistory(history);

      const { clientX, clientY } = event;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const previewWidth = 200;
      const previewHeight = 200;
      let left = clientX;
      let top = clientY;

      if (clientX + previewWidth > viewportWidth) {
        left = viewportWidth - previewWidth;
      }

      if (clientY + previewHeight > viewportHeight) {
        top = viewportHeight - previewHeight;
      }

      setPreviewPosition({ top, left });
    },
    [gameChess, props.comments]
  );

  const handleMouseLeave = useCallback(() => {
    setHoveredMoveHistory(null);
    setPreviewPosition(null);
  }, []);

  useImperativeHandle(ref, () => ({
    handleMouseEnter,
    handleMouseLeave,
  }));

  if (!hoveredMoveHistory || !previewPosition) return null;

  const fen = gameChess.generateFENFromHistory(hoveredMoveHistory);

  if (!fen) return null;

  return (
    <div
      className="chessboard-preview"
      style={{ top: previewPosition.top, left: previewPosition.left }}
    >
      <Chessboard
        position={fen}
        boardOrientation={props.color === "w" ? "white" : "black"}
      />
    </div>
  );
});

export default ChessboardPreview;
