// src/utils/TreeManager.ts
import { OpeningMove, TreeNode } from "../types";

export default class TreeManager {
  // Convertit un OpeningMove en TreeNode
  public static convertToTreeData(openingMove: OpeningMove): TreeNode | null {
    const convertNode = (
      node: OpeningMove,
      history: string[] = []
    ): TreeNode | null => {
      if (!node) return null;

      // Ajouter le coup actuel à l'historique
      const updatedHistory = [...history, node.san || "Start"];

      return {
        name: node.san || "Start", // Assigne le coup comme nom, ou "Start" pour l'état initial
        move: node,
        children: Object.values(node.children)
          .map((childNode) => convertNode(childNode, updatedHistory)) // Passe l'historique mis à jour aux enfants
          .filter((child): child is TreeNode => child !== null),
      };
    };

    return convertNode(openingMove);
  }

  // Recherche du nœud cible en fonction de l'historique dans TreeNode
  public static findTargetNodeByHistory(
    treeData: TreeNode | null,
    targetHistory: string[]
  ): {
    ancestors: TreeNode[];
    targetNode: TreeNode | null;
  } {
    const search = (
      currentNode: TreeNode | null,
      parentNodes: TreeNode[] = []
    ): { ancestors: TreeNode[]; targetNode: TreeNode | null } => {
      if (!currentNode) return { ancestors: [], targetNode: null };
      if (this.arraysEqual(currentNode.move.history, targetHistory))
        return { ancestors: parentNodes, targetNode: currentNode };

      if (currentNode.children) {
        for (const child of currentNode.children) {
          const result = search(child, [...parentNodes, currentNode]);
          if (result.targetNode) return result;
        }
      }

      return { ancestors: [], targetNode: null };
    };

    return search(treeData);
  }

  // Construit un sous-arbre basé sur les ancêtres et le nœud cible
  public static buildSubsetTree(
    ancestors: TreeNode[],
    targetNode: TreeNode
  ): TreeNode {
    if (ancestors.length === 0) {
      // Pas de parent, le nœud cible est la racine
      return {
        ...targetNode,
        isCurrentNode: true,
        isAncestorNode: false,
        children: this.getDirectChildren(targetNode),
      };
    } else {
      const parent = ancestors[ancestors.length - 1];
      return {
        ...parent,
        isCurrentNode: false,
        isAncestorNode: true,
        children: [
          {
            ...targetNode,
            isCurrentNode: true,
            isAncestorNode: false,
            children: this.getDirectChildren(targetNode),
          },
        ],
      };
    }
  }

  // Récupère les enfants directs sans inclure les petits-enfants
  public static getDirectChildren(node: TreeNode | null): TreeNode[] {
    if (!node || !node.children) return [];
    return node.children.map((child) => ({
      ...child,
      isCurrentNode: false,
      isAncestorNode: false,
      children: [], // Ne pas inclure les petits-enfants
    }));
  }

  // Vérifie si deux tableaux sont égaux
  private static arraysEqual(a: any[], b: any[]): boolean {
    if (a.length !== b.length) return false;
    return a.every((val, index) => val === b[index]);
  }
}
