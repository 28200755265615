// NodeCreation.jsx
import React from "react";
import { OpeningMove, TreeNode } from "../../../../types";
import NodeWithNoOrManyChildren from "./NodeWithNoOrManyChildren/NodeWithNoOrManyChildren";
import NodeWithSingleChild from "./NodeWithSingleChild/NodeWithSingleChild";

interface NodeCreationProps {
  nodeDatum: TreeNode;
  handleMouseEnter: (
    event: React.MouseEvent,
    history: string[],
    comment?: string
  ) => void;
  handleMouseLeave: () => void;
  currentMove?: OpeningMove | null;
}

const NodeCreation: React.FC<NodeCreationProps> = ({
  nodeDatum,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const hasChildren = Object.keys(nodeDatum.move.children).length;

  if (hasChildren === 1 && nodeDatum.move.history.length > 0) {
    return (
      <NodeWithSingleChild
        nodeDatum={nodeDatum}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
    );
  } else {
    return (
      <NodeWithNoOrManyChildren
        nodeDatum={nodeDatum}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
    );
  }
};

export default NodeCreation;
