// AdminAuthContext.tsx
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Importe ton composant Loader
import Loader from "../components/commun/LoaderChess/LoaderChess";
import { useUserStore } from "../store/useUserStore";

interface AdminAuthContextProps {
  checkAuthToken: () => Promise<boolean>;
  isLoading: boolean;
}

const AdminAuthContext = createContext<AdminAuthContextProps>({
  checkAuthToken: async () => false,
  isLoading: true, // Valeur par défaut, en mode chargement
});

interface AdminAuthProviderProps {
  children: ReactNode;
}

export const AdminAuthProvider: React.FC<AdminAuthProviderProps> = ({
  children,
}) => {
  const { checkLoginStatus, role } = useUserStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // État de chargement

  // Fonction pour vérifier si l'utilisateur est admin et rediriger si nécessaire
  const checkAuthToken = async (): Promise<boolean> => {
    setIsLoading(true); // Activer le mode chargement
    const isAuthenticated = await checkLoginStatus();
    setIsLoading(false); // Désactiver le mode chargement une fois terminé

    if (!isAuthenticated || role !== 'admin') {
      navigate("/403"); // Redirige vers la page 403 si l'utilisateur n'est pas admin
      return false;
    }

    return true;
  };

  useEffect(() => {
    // Vérifie le token à chaque visite de page
    const verifyToken = async () => {
      await checkAuthToken();
    };
    verifyToken();
  }, [role]); // Réagir au changement de l'état `role`

  // Afficher le loader si le chargement est en cours
  if (isLoading) {
    return <Loader />;
  }

  return (
    <AdminAuthContext.Provider value={{ checkAuthToken, isLoading }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

// Hook pour accéder au contexte AdminAuth
export const useAdminAuth = () => useContext(AdminAuthContext);
