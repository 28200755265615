import Quill from "quill";
import React, { useRef } from "react";
import ReactDOMServer from "react-dom/server";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { defaultPieces } from "../../../assets/svg/pieces";
import "./CommentSection.scss";

const Embed = Quill.import("blots/embed");

class ChessIconBlot extends Embed {
  static blotName = "chess-icon";
  static tagName = "span";
  static className = "chess-icon";

  static create(value: any) {
    let node = super.create();
    node.setAttribute("class", "chess-icon");
    node.innerHTML = value;
    return node;
  }

  static value(node: any) {
    return node.innerHTML;
  }
}

Quill.register(ChessIconBlot);

const CustomToolbar = ({
  quillRef,
}: {
  quillRef: React.RefObject<ReactQuill>;
}) => {
  const pieces = defaultPieces({ width: "24px", height: "24px" });

  return (
    <div id="toolbar">
      <div className="toolbar-row">
        {Object.entries(pieces)
          .filter(([name]) => name.startsWith("w")) // Pièces blanches
          .map(([name, Icon]) => (
            <button
              key={name}
              onClick={() => {
                if (quillRef.current) {
                  const quill = quillRef.current.getEditor();
                  const range = quill.getSelection();
                  if (range) {
                    const svgHtml = ReactDOMServer.renderToStaticMarkup(Icon);
                    quill.insertEmbed(range.index, "chess-icon", svgHtml);
                    quill.setSelection(range.index + 1, 0);
                  }
                }
              }}
              className="chess-icon-btn"
              type="button"
              title={`Insert ${name}`}
            >
              {Icon}
            </button>
          ))}
      </div>
      <div className="toolbar-row">
        {Object.entries(pieces)
          .filter(([name]) => name.startsWith("b")) // Pièces noires
          .map(([name, Icon]) => (
            <button
              key={name}
              onClick={() => {
                if (quillRef.current) {
                  const quill = quillRef.current.getEditor();
                  const range = quill.getSelection();
                  if (range) {
                    const svgHtml = ReactDOMServer.renderToStaticMarkup(Icon);
                    quill.insertEmbed(range.index, "chess-icon", svgHtml);
                    quill.setSelection(range.index + 1, 0);
                  }
                }
              }}
              className="chess-icon-btn"
              type="button"
              title={`Insert ${name}`}
            >
              {Icon}
            </button>
          ))}
      </div>
    </div>
  );
};

interface CommentSectionProps {
  comment: string;
  onCommentChange: (comment: string) => void;
}

export default function CommentSection({
  comment,
  onCommentChange,
}: CommentSectionProps) {
  const quillRef = useRef<ReactQuill>(null);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (quillRef.current) {
  //       quillRef.current.focus();
  //     }
  //   }, 100); // Vérifie et garde le focus en continu
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="comment-section">
      <CustomToolbar quillRef={quillRef} />
      <ReactQuill
        ref={quillRef}
        value={comment}
        onChange={onCommentChange}
        placeholder="Ajouter un commentaire"
        modules={{
          toolbar: "#toolbar",
        }}
        formats={["chess-icon"]}
      />
    </div>
  );
}
