import React, { ReactNode, useState } from "react";
import { toast } from "react-toastify";
import { config } from "../../../config";
import useCreateOpeningStore from "../../../store/useCreateOpeningStore";
import { ConfirmationToast } from "../Toast/ToastDelete/ConfirmationToast";
import "./ClickSetting.scss"; // Importez le fichier CSS pour le style

// Définir une interface pour les props
interface ClickSettingProps {
  children: ReactNode; // Type pour les enfants
}

const ClickSetting: React.FC<ClickSettingProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { resetStore } = useCreateOpeningStore();
  // Fonction pour afficher le menu
  const handleClick = () => {
    setIsVisible((prev) => !prev); // Inverse l'état de visibilité
  };

  // Fonction pour cacher le menu
  const handleMenuClose = () => {
    setIsVisible(false);
  };

  const handleConfirm = () => {
    localStorage.removeItem(config.localStorageCreateOpening);

    resetStore();
    toast.success("Effacer !");
  };

  return (
    <div className="click-setting-container">
      <div onClick={handleClick}>{children}</div>
      {isVisible && (
        <div className="click-setting" onMouseLeave={handleMenuClose}>
          <ul>
            <li
              onClick={() =>
                ConfirmationToast("Voulez-vous continuer ?", handleConfirm)
              }
            >
              Effacer l'ouverture
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ClickSetting;
