import React, { useEffect, useState } from "react";
import icons from "../../../assets/svg/icons"; // Assure-toi que icons.stop et icons.premium sont des éléments SVG
import useCreateOpeningStore from "../../../store/useCreateOpeningStore";

interface LinkIconProps {
  transformedX: number;
  transformedY: number;
  iconSize: number;
  history: string[];
}

const LinksIcon: React.FC<LinkIconProps> = ({
  transformedX,
  transformedY,
  iconSize,
  history,
}) => {
  const {
    updateBlockEntrainement,
    openingManager,
    infoOpeningData,
    updateInfoOpeningData,
  } = useCreateOpeningStore();

  const [visible, setVisible] = useState<boolean>(false); // Initialisation à `false`
  const [hovered, setHovered] = useState<boolean>(false); // Contrôle la visibilité lors du survol
  const [selectedIcon, setSelectedIcon] = useState<string>("stop"); // Par défaut, l'icône est 'stop'

  useEffect(() => {
    const moveDetails = openingManager.getMoveDetails(history);
    if (moveDetails) {
      // Vérification si l'un des deux est indéfini, et renvoyer false dans ce cas
      const isPremiumSelected =
        moveDetails.path[0] && infoOpeningData.pathForPremium
          ? moveDetails.path[0] === infoOpeningData.pathForPremium
          : false;

      setVisible(isPremiumSelected || moveDetails.blockEntrainement);
      setSelectedIcon(isPremiumSelected ? "premium" : "stop");
    }
  }, [history, openingManager, infoOpeningData]);

  // Gestion du clic pour l'icône stop
  const handleClickStop = (): void => {
    setSelectedIcon("stop");
    const moveDetails = openingManager.getMoveDetails(history);
    if (moveDetails) {
      updateBlockEntrainement(moveDetails, !visible); // Marque comme `blockEntrainement`
    }
    setVisible(!visible);
  };

  // Gestion du clic pour l'icône premium
  const handleClickPremium = (): void => {
    setSelectedIcon("premium");
    setVisible(true);
    const moveDetails = openingManager.getMoveDetails(history);
    const newInfoOpeningData = {
      ...infoOpeningData,
      isFree: false,
      pathForPremium: moveDetails?.path[0], // Associer le chemin pour premium
    };

    if (moveDetails) {
      updateInfoOpeningData(newInfoOpeningData); // Mettre à jour les données pour premium
    }
  };

  const handleMouseEnter = () => {
    setHovered(true); // Affiche le menu lorsqu'on survole
  };

  const handleMouseLeave = () => {
    setHovered(false); // Cache le menu lorsqu'on quitte le survol
  };

  return (
    <g
      onMouseEnter={handleMouseEnter} // Garder le menu visible lorsqu'on survole le cercle ou le menu
      onMouseLeave={handleMouseLeave} // Cacher le menu lorsqu'on quitte la zone
    >
      {/* Le cercle de base */}
      <circle
        cx={transformedX}
        cy={transformedY}
        r={20}
        fill="transparent"
        style={{ cursor: "pointer", outline: "none" }}
        tabIndex={-1} // Empêche le focus
      />

      {/* Afficher l'icône sélectionnée ou le menu */}
      {(visible || hovered) && (
        <g
          transform={`translate(${transformedX - iconSize / 2}, ${
            transformedY - iconSize / 2
          })`}
          style={{ pointerEvents: "all" }} // Autoriser les interactions avec l'icône principale et le menu
        >
          {/* Icône principale sélectionnée */}
          {icons({ width: iconSize, height: iconSize })[selectedIcon]}

          {/* Afficher le menu si on survole */}
          {hovered && (
            <g transform={`translate(${iconSize * 1.5}, ${4})`}>
              {/* Ajout d'un fond pour le menu */}
              <rect
                x={-iconSize / 3} // Ajustement du fond pour bien entourer les icônes
                y={-iconSize / 2}
                width={iconSize * 3} // Largeur ajustée pour bien entourer les deux icônes
                height={iconSize + 8} // Hauteur ajustée pour laisser un peu d'espace autour
                rx={5}
                ry={5}
                fill="lightgray" // Couleur de fond du menu
                stroke="gray"
              />

              {/* Option 1: Icône stop */}
              <g
                transform={`translate(0, ${-2})`} // Positionner l'icône 'stop'
                onClick={handleClickStop}
                style={{ cursor: "pointer" }}
              >
                {icons({ width: iconSize, height: iconSize }).stop}
              </g>

              {/* Option 2: Icône premium */}
              <g
                transform={`translate(${iconSize * 1.5}, ${-2})`} // Positionner l'icône 'premium'
                onClick={handleClickPremium}
                style={{ cursor: "pointer" }}
              >
                {icons({ width: iconSize, height: iconSize }).premium}
              </g>
            </g>
          )}
        </g>
      )}
    </g>
  );
};

export default LinksIcon;
