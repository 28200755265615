import { useEffect } from "react";
import "./ColorSelector.scss";

interface ColorSelectorProps {
  selectedColor: string | null;
  onColorSelect: (color: string) => void;
}

export default function ColorSelector({
  selectedColor,
  onColorSelect,
}: ColorSelectorProps) {
  const colors = [
    "#C72A2A", // Attaque (Rouge vif)
    "#D68D2D", // Erreur (Orange vif)
    "#4CA76D", // Coup normal (Vert vif)
    "#3F6DB8", // Plan stratégique (Bleu vif)
    "#D6C34A", // Faiblesse (Jaune vif)
    "#6E4B8A", // Défense (Violet vif)
  ];

  // Si aucune couleur n'est sélectionnée, initialiser avec la première couleur
  useEffect(() => {
    if (!selectedColor) {
      onColorSelect(colors[0]);
    }
  }, [selectedColor, onColorSelect, colors]);

  return (
    <div className="color-selector">
      <div className="color-options">
        {colors.map((color, index) => (
          <div
            key={index}
            className={`color-circle ${
              selectedColor === color ? "selected" : ""
            }`}
            style={{ backgroundColor: color }}
            onClick={() => onColorSelect(color)}
          />
        ))}
      </div>
    </div>
  );
}
