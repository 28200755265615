import { CiRoute } from "react-icons/ci";
import { FaEraser, FaTrash } from "react-icons/fa";
import { FcRefresh } from "react-icons/fc";
import { PiFloppyDisk } from "react-icons/pi";
import "./Actions.scss";

interface ActionsProps {
  handleAddRoute: () => void;
  handleChangeColorBoard: () => void;
  onClear: () => void;
  onDelete: () => void;

  onSendServ: () => void;
  handleDownloadFiles: () => void;
}

export default function Actions({
  handleAddRoute,
  handleChangeColorBoard,
  onClear,
  onDelete,

  onSendServ,

  handleDownloadFiles,
}: ActionsProps) {
  return (
    <div className="actions">
      <div className="basic-actions">
        <button onClick={handleChangeColorBoard} className="turn" title="turn">
          <FcRefresh />
        </button>

        <button onClick={handleAddRoute} title="addRoute">
          <CiRoute />
        </button>

        <button onClick={onClear} title="Clear">
          <FaEraser />
        </button>
        <button onClick={onDelete} title="Supprimer">
          <FaTrash />
        </button>
      </div>

      <div className="sendDownload">
        <div className="send-button">
          <button onClick={onSendServ} title="Sauvegarder">
            <PiFloppyDisk />
          </button>
        </div>

        {/* <div className="download-button">
              <button onClick={handleDownloadFiles}>
                <LiaFileDownloadSolid />
              </button>
            </div> */}
      </div>
    </div>
  );
}
