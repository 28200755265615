import React from "react";
import OpeningGrid from "../../commun/OpeningGrid/OpeningGrid";
import "./TabContent.scss";
interface TabContentProps {
  activeTab: "subscription" | "cours";
}

const TabContent: React.FC<TabContentProps> = ({ activeTab }) => {
  return (
    <div className="tab-content">
      {activeTab === "subscription" && (
        <div className="subscription-content"></div>
      )}
      {activeTab === "cours" && (
        <div className="cours-content">
          <OpeningGrid isEditing={true} />
        </div>
      )}
    </div>
  );
};

export default TabContent;
