// OpeningTreeModal.tsx
import Modal from "react-modal";

import { RiCloseCircleLine } from "react-icons/ri";
import { OpeningMove, TreeNode } from "../../../types";
import TreeComponent from "../TreeComponent/TreeComponent";
import "./OpeningTreeModal.scss"; // Import du fichier SCSS
interface OpeningTreeModalProps {
  open: boolean;
  handleClose: () => void;
  treeNode?: TreeNode | null;
  currentMove: OpeningMove;
  loadMove?: (move: OpeningMove) => void | null; // Peut être undefined ou null
  treeId: string;
  legende?: React.ReactNode;
  color: "w" | "b";
}

const OpeningTreeModal: React.FC<OpeningTreeModalProps> = ({
  open,
  handleClose,
  treeNode,
  currentMove,
  loadMove,
  treeId,
  legende,
  color,
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      contentLabel="Vue Arborescente"
      className="treeModalContent"
      overlayClassName="treeModalOverlay"
    >
      {legende && <div className="treeModalOverlay_legende">{legende}</div>}

      <div onClick={handleClose}>
        <RiCloseCircleLine className="btnCloseModalTree" />
      </div>
      <TreeComponent
        onRequestClose={handleClose}
        treeNode={treeNode}
        currentMove={currentMove}
        loadMove={loadMove || (() => {})} // Fournit une fonction vide si loadMove est null/undefined
        TREE_ID={treeId}
        color={color}
      />
    </Modal>
  );
};

export default OpeningTreeModal;
