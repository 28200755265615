import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend"; // Pour charger les fichiers JSON
import { initReactI18next } from "react-i18next";

i18n
  .use(HttpApi) // Utiliser http-backend pour charger les fichiers JSON
  .use(LanguageDetector) // Pour détecter la langue du navigateur
  .use(initReactI18next) // Pour initialiser avec React
  .init({
    fallbackLng: "en", // Langue par défaut si aucune langue n'est détectée
    lng: "en", // Langue par défaut
    debug: false, // Pour afficher les logs dans la console
    backend: {
      // Chemin pour charger les fichiers de traduction. Utilise {{lng}} pour la langue et {{ns}} pour le namespace.
      loadPath: "/locales/{{lng}}/{{ns}}.json", // Ex : /locales/en/homePage.json
    },
    ns: ["homePage"], // Noms des fichiers de traduction (namespaces)
    defaultNS: "homePage", // Namespace par défaut si aucun n'est spécifié
    interpolation: {
      escapeValue: false, // Pas besoin d'échapper les valeurs dans React
    },
    detection: {
      order: ["navigator", "cookie", "localStorage", "path", "htmlTag"],
      caches: ["cookie"],
    },
  });

export default i18n;
