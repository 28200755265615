import { useState } from "react";
import { FaCheck, FaPen, FaTimes } from "react-icons/fa";
import asset from "../../assets/svg/icons";
import { useUserStore } from "../../store/useUserStore";
import ImageResizer from "../commun/ImageResizer/ImageResizer";
import "./UserInfo.scss";

const API_URL = process.env.REACT_APP_API_URL || "";

const UserInfo = () => {
  const { pseudo, email, userId, subscriptionExpiry } = useUserStore();
  const [isEditing, setIsEditing] = useState(false);
  const [pseudoInput, setPseudoInput] = useState(pseudo);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const handleEdit = () => setIsEditing(true);

  const handleConfirm = async () => {
    setIsEditing(false);

    // Initialisation de l'objet à envoyer
    const updatedData = new FormData();

    // Vérification des changements
    if (pseudoInput !== pseudo) {
      updatedData.append("pseudo", pseudoInput!);
    }
    if (croppedImage) {
      // Conversion de base64 en Blob pour l'ajouter à formData en tant que fichier
      const byteString = atob(croppedImage.split(",")[1]);
      const mimeString = croppedImage.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });
      updatedData.append("picture", blob, "profile.jpg"); // Nom du fichier arbitraire
    }

    // Envoi de la requête si des données ont changé
    if (updatedData.has("pseudo") || updatedData.has("picture")) {
      try {
        const response = await fetch(`${API_URL}/user/update`, {
          method: "PUT",
          credentials: "include", // Inclus les cookies d'authentification
          body: updatedData, // FormData, pas besoin de Content-Type ici
        });

        const data = await response.json();
        if (response.ok) {
          console.log("Utilisateur mis à jour:", data);
        } else {
          console.error("Erreur de mise à jour:", data.error);
        }
      } catch (error) {
        console.error("Erreur lors de la requête:", error);
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setPseudoInput(pseudo);
    setCroppedImage(null);
  };

  const handleImageCrop = (newImage: string) => {
    setCroppedImage(newImage);
  };

  return (
    <div className="user-info-card">
      <div className="profile-section">
        <div className="profile-img-container">
          {croppedImage ? (
            <img
              src={croppedImage}
              alt="Profile"
              className="profile-img"
              loading="lazy"
            />
          ) : (
            <div className="img-placeholder">
              <img
                src={`${API_URL}/user/picture/${pseudo}`}
                alt="Profil"
                loading="lazy"
              />
            </div>
          )}
          {isEditing && <ImageResizer onImageCrop={handleImageCrop} />}
        </div>
      </div>
      <div className="details-section">
        {isEditing ? (
          <div className="edit-fields">
            <input
              type="text"
              value={pseudoInput!}
              onChange={(e) => setPseudoInput(e.target.value)}
              placeholder="Modifier le nom"
              className="input-field"
            />
          </div>
        ) : (
          <div className="user-info">
            <p className="user-name">{pseudo}</p>
            <p className="user-email">{email}</p>
            <p className="user-premium">
              {asset({ width: 20, height: 20 }).premium}
              {subscriptionExpiry
                ? subscriptionExpiry.toLocaleDateString()
                : "Non défini"}
            </p>
            <p className="user-status">Abonné: 1256</p>
          </div>
        )}
      </div>

      {/* Icônes flottantes de modification, validation et annulation */}
      {!isEditing && (
        <FaPen onClick={handleEdit} className="edit-icon-floating" />
      )}
      {isEditing && (
        <div className="edit-actions-floating">
          <FaCheck
            onClick={handleConfirm}
            className="action-icon confirm-icon"
          />
          <FaTimes onClick={handleCancel} className="action-icon cancel-icon" />
        </div>
      )}
    </div>
  );
};

export default UserInfo;
