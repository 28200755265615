import TreeComponent from "../../../components/commun/TreeComponent/TreeComponent";
import useCreateOpeningStore from "../../../store/useCreateOpeningStore";
import "./OpeningTree.scss";

export default function OpeningTree() {
  const {
    getOpeningTreeConverted,
    currentMove,
    loadMove,
    treeOpeningData,
    infoOpeningData,
  } = useCreateOpeningStore();

  return (
    <div className="tree-container">
      <div className="tree-wrapper">
        {treeOpeningData && (
          <TreeComponent
            treeNode={getOpeningTreeConverted()}
            currentMove={currentMove}
            loadMove={loadMove}
            TREE_ID="treecreation"
            color={infoOpeningData.color}
          />
        )}
      </div>
    </div>
  );
}
