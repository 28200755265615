import React, { useEffect, useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import "./MenuOptions.scss";

interface MenuOptionsProps {
  options: { label: string; action: () => void; icon?: React.ReactNode }[]; // Ajout d'une icône facultative
}

const MenuOptions: React.FC<MenuOptionsProps> = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen((prev) => !prev);

  // Fermer le menu si l'utilisateur clique en dehors
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="menuOptions" ref={menuRef}>
      <button className="menuButton" onClick={handleToggle}>
        <FaEllipsisV />
      </button>
      {isOpen && (
        <div className="menuOptions_list">
          {options.map((option, index) => (
            <button
              key={index}
              className="menuOptions_item"
              onClick={() => {
                option.action();
                setIsOpen(false); // Ferme le menu après une action
              }}
            >
              {option.icon && (
                <span className="menuOptions_item_icon">{option.icon}</span>
              )}
              <span className="menuOptions_item_label">{option.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuOptions;
