import { useState } from "react";
import { useNavigate } from "react-router-dom";
import icons from "../../assets/svg/icons";
import Header from "../../components/commun/header/header";

import OpeningGrid from "../../components/commun/OpeningGrid/OpeningGrid";
import "./OpeningManagement.scss";

const OpeningManagement: React.FC = () => {
  const [focusedPanel, setFocusedPanel] = useState<"left" | "right" | null>(
    null
  );
  const navigate = useNavigate(); // Initialise le hook de navigation

  const handleClickLeft = () => {
    setFocusedPanel(focusedPanel === "left" ? null : "left");
  };

  const handleClickRight = () => {
    setFocusedPanel(focusedPanel === "right" ? null : "right");
    navigate("/createOpening"); // Navigue vers la page createOpening
  };

  const handleClickOutside = () => {
    setFocusedPanel(null);
  };

  return (
    <div className="openingManagement">
      <Header />
      <div className="openingManagement_container" onClick={handleClickOutside}>
        <div
          className={`leftPanel custom-scrollbar ${
            focusedPanel === "left" ? "focused" : ""
          } ${focusedPanel === "right" ? "blurred" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            handleClickLeft();
          }}
        >
          {focusedPanel === "left" ? (
            <OpeningGrid isEditing={true} /> // Affiche `OpeningGrid` si le panneau est focus
          ) : (
            <div className="iconWrapper">
              {icons({ width: 120, height: 150 }).penUpdate}
              <p>Update</p>
            </div>
          )}
        </div>
        <div className="separator" />
        <div
          className={`rightPanel ${focusedPanel === "right" ? "focused" : ""} ${
            focusedPanel === "left" ? "blurred" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            handleClickRight();
          }}
        >
          <div className="iconWrapper">
            {icons({ width: 150, height: 150 }).new}
          </div>
          <p>New</p>
        </div>
      </div>
    </div>
  );
};

export default OpeningManagement;
