import {
  playIllegalMoveSound,
  useAudio,
} from "../../../hooks/useAudioChessboard"; // Remplace `useIllegalAudio` par une fonction utilitaire
import { useModeLogic } from "../../../hooks/useModeLogic";
import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import ChessboardManager from "../ChessboardManager/ChessboardManager";
import "./ChessBoardCours.scss";

interface ChessboardCoursProps {
  boardSize: number;
}

const ChessboardCours: React.FC<ChessboardCoursProps> = ({ boardSize }) => {
  const { mode } = useLessonOpeningStore();

  const { currentMove, gameChess, getNextMoves, infoOpeningData } =
    useLessonOpeningStore();

  // Appel du hook pour jouer les sons en fonction du SAN
  useAudio(currentMove?.san);

  const { handleCorrectMove, handleIncorrectMove } = useModeLogic();

  const onDrop = (sourceSquare: string, targetSquare: string) => {
    const san = gameChess.getSANFromMove(sourceSquare, targetSquare);

    if (!san) return false;

    const nextMoves = getNextMoves(currentMove?.history);
    const matchingMove = nextMoves?.find((move) => move.san === san);

    if (matchingMove && matchingMove.blockEntrainement === false) {
      // Si le coup est correct, déléguons la logique spécifique au mode
      handleCorrectMove(matchingMove);
      return true;
    }

    // Gérer les actions spécifiques aux erreurs en fonction du mode
    handleIncorrectMove(sourceSquare, targetSquare);

    // Appel de la fonction pour jouer le son illégal
    playIllegalMoveSound();

    return false;
  };

  return (
    <ChessboardManager
      gameChess={gameChess}
      customArrows={mode === "cours" ? currentMove.arrows : []}
      arePiecesDraggable={mode !== "cours"}
      onPieceDrop={mode === "cours" ? undefined : onDrop}
      boardOrientation={infoOpeningData.color === "w" ? "white" : "black"}
      resizable={false}
      allowAnnotations={mode === "cours"}
      isMode={true}
      currentMove={currentMove}
      boardSize={boardSize}
    />
  );
};

export default ChessboardCours;
