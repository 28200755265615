import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ConfirmationToast = (
  message: string,
  onConfirm: () => void,
  options?: {
    autoClose?: number;
    position?:
      | "top-right"
      | "top-center"
      | "top-left"
      | "bottom-right"
      | "bottom-center"
      | "bottom-left";
  }
) => {
  const notification = toast.warning(
    <div className="toast-content">
      <p>{message}</p>
      <div>
        <button
          className="red-button"
          onClick={() => {
            onConfirm(); // Exécute l'action de confirmation
            toast.dismiss(notification); // Ferme la notification
          }}
        >
          Effacer
        </button>
      </div>
    </div>,
    {
      autoClose: options?.autoClose || 10000, // Durée personnalisable
      closeOnClick: false,
      draggable: false,
      position: options?.position || "bottom-right", // Position personnalisable
      theme: "colored",
    }
  );
};
