import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import AuthModal from "../components/AuthModal/AuthModal";
import PayementModal from "../components/PayementModal/PayementModal";
import { config } from "../config";
import { useLessonOpeningStore } from "../store/useLessonOpeningStore";
import { useUserStore } from "../store/useUserStore";

// Typage du contexte
interface AuthPremiumContextProps {
  checkAccess: (requiredAccess: "login" | "premium") => Promise<boolean>;
  pathWithAccessCheck: <T extends (...args: any[]) => any>(
    action: T,
    path: number
  ) => Promise<boolean>;

  closeModals: () => void;
}

// Création du contexte avec des valeurs par défaut
const AuthPremiumContext = createContext<AuthPremiumContextProps>({
  checkAccess: async () => false,
  pathWithAccessCheck: async () => false,
  closeModals: () => {},
});

// Typage des props du provider
interface AuthPremiumProviderProps {
  children: ReactNode;
}

// Création du provider du contexte qui gère la connexion et le premium
export const AuthPremiumProvider: React.FC<AuthPremiumProviderProps> = ({
  children,
}) => {
  const { isLogged, checkLoginStatus, isPremium } = useUserStore();
  const { infoOpeningData } = useLessonOpeningStore();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isPayementModalOpen, setIsPayementModalOpen] = useState(false);
  const [isUserPremium, setIsUserPremium] = useState(false);

  // Fonction pour vérifier l'accès selon le type ('login' ou 'premium')
  const checkAccess = async (
    requiredAccess: "login" | "premium"
  ): Promise<boolean> => {
    if (requiredAccess === "login") {
      if (isLogged) {
        return true;
      }
      const isUserLogged = await checkLoginStatus();
      if (!isUserLogged) {
        setIsLoginModalOpen(true);
        return false;
      }
    }

    if (requiredAccess === "premium") {
      const premiumStatus = await isPremium();
      setIsUserPremium(premiumStatus);
      if (premiumStatus) {
        return true;
      }
      setIsPayementModalOpen(true);
      return false;
    }

    return true;
  };

  const pathWithAccessCheck = async (action: () => void, path: number) => {
    if (path >= config.nbrMoveForConnect && !isLogged) {
      const isLoggedIn = await checkAccess("login");
      if (!isLoggedIn) {
        return false;
      }
    }

    console.log(infoOpeningData);
    const accesOk =
      isPremium() || infoOpeningData.hasPurchased || infoOpeningData.isCreator;

    if (
      infoOpeningData.pathForPremium &&
      path >= infoOpeningData.pathForPremium &&
      !accesOk
    ) {
      const isPremiumUser = await checkAccess("premium");
      if (!isPremiumUser) {
        return false;
      }
    }

    action();
    return true;
  };

  // Fermeture des modals
  const closeModals = () => {
    setIsLoginModalOpen(false);
    setIsPayementModalOpen(false);
  };

  useEffect(() => {
    if (isLogged) {
      setIsLoginModalOpen(false);
    }
  }, [isLogged]);

  useEffect(() => {
    const checkPremiumStatus = async () => {
      const premiumStatus = isPremium();
      setIsUserPremium(premiumStatus);
      if (premiumStatus) {
        setIsPayementModalOpen(false);
      }
    };

    checkPremiumStatus();
  }, [isPremium]);

  return (
    <AuthPremiumContext.Provider
      value={{
        checkAccess,
        pathWithAccessCheck,
        closeModals,
      }}
    >
      <>
        {children}
        {isLoginModalOpen && (
          <AuthModal
            open={isLoginModalOpen}
            handleClose={() => setIsLoginModalOpen(false)}
          />
        )}
        {isPayementModalOpen && (
          <PayementModal
            isOpen={isPayementModalOpen}
            closeModal={() => setIsPayementModalOpen(false)}
            overlayPointerEvent={false}
          />
        )}
      </>
    </AuthPremiumContext.Provider>
  );
};

// Hook pour consommer le contexte dans les composants
export const useAuthPremium = () => {
  const context = useContext(AuthPremiumContext);
  if (!context) {
    throw new Error(
      "useAuthPremium must be used within an AuthPremiumProvider"
    );
  }
  return context;
};
