// AuthCheckContext.tsx
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import Loader from "../components/commun/LoaderChess/LoaderChess";
import { useUserStore } from "../store/useUserStore";

interface AuthCheckContextProps {
  isUserLoggedIn: () => Promise<boolean>;
  isLoading: boolean;
}

const AuthCheckContext = createContext<AuthCheckContextProps>({
  isUserLoggedIn: async () => false, // Valeur par défaut si le contexte n'est pas fourni
  isLoading: true,
});

interface AuthCheckProviderProps {
  children: ReactNode;
}

export const AuthCheckProvider: React.FC<AuthCheckProviderProps> = ({
  children,
}) => {
  const { checkLoginStatus, isLogged } = useUserStore();
  const [isLoading, setIsLoading] = useState(true);

  // Fonction pour vérifier si l'utilisateur est connecté
  const isUserLoggedIn = async (): Promise<boolean> => {
    const isUserLogged = await checkLoginStatus();
    return isUserLogged;
  };

  useEffect(() => {
    // Vérifie l'état de connexion à l'initialisation
    const checkAuth = async () => {
      await isUserLoggedIn();
      setIsLoading(false); // Désactiver le chargement une fois la vérification terminée
    };
    checkAuth();
  }, [isLogged]);

  return (
    <AuthCheckContext.Provider value={{ isUserLoggedIn, isLoading }}>
      {isLoading ? <Loader /> : children}{" "}
      {/* Affiche le loader si en cours de chargement */}
    </AuthCheckContext.Provider>
  );
};

// Hook pour accéder au contexte
export const useAuthCheck = () => useContext(AuthCheckContext);
