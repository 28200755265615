import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChessboardCreation from "../../components/commun/ChessboardCreation/ChessboardCreation";
import Header from "../../components/commun/header/header";
import LoaderChess from "../../components/commun/LoaderChess/LoaderChess";
import OpeningEditor from "../../components/PannelCreateOpening/OpeningEditor/OpeningEditor";
import useCreateOpeningStore from "../../store/useCreateOpeningStore";
import "./CreateOpening.scss";
import OpeningTree from "./OpeningTree/OpeningTree";
const API_URL = process.env.REACT_APP_API_URL || "";

export default function CreateOpening() {
  const { infoOpeningData, loadDataFromLocalStorage, loadData } =
    useCreateOpeningStore();

  const { openingPath } = useParams();

  const [loading, setLoading] = useState(true);

  function extractTitleAndCreator(str: string) {
    const [title, creator] = str.split("-by-");
    return { title, creator };
  }

  useEffect(() => {
    const fetchOpenings = async (title: string, creator: string) => {
      try {
        const response = await axios.get(
          `${API_URL}/api/get-openings-by-title-and-creator`,
          {
            params: {
              title,
              creator,
              includeData: true,
            },
          }
        );

        const infoOpening = {
          _id: response.data._id,
          title: response.data.title,
          creator: response.data.creator,
          description: response.data.description,
          level: response.data.level,
          pathForPremium: response.data.pathForPremium,
          history: response.data.history,
          color: response.data.color,
          nbrOfPath: response.data.nbrOfPath,
          price: response.data.price,
        };

        loadData(response.data.data, infoOpening);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            console.error(
              "Aucune ouverture trouvée pour ce nom et ce créateur."
            );
          } else {
            console.error(
              "Erreur lors de la récupération des ouvertures :",
              error.message
            );
          }
        } else {
          console.error("Erreur inconnue :", error);
        }
      }

      setLoading(false);
    };

    if (openingPath) {
      const { title, creator } = extractTitleAndCreator(openingPath);
      fetchOpenings(title, creator);
    } else {
      loadDataFromLocalStorage();
      setLoading(false);
    }

    return () => {
      // resetStoreExceptOpening()
    };
  }, [openingPath]);

  if (loading) {
    return <LoaderChess />;
  }

  const pageTitle = `Learn ${infoOpeningData.title} - Chess Openings`;

  return (
    <div className="pageCreateOpening">
      <Header />
      <div className="flex-container">
        {/* Colonne gauche */}
        <div className="left-panel">
          <OpeningEditor />
        </div>

        {/* Colonne centrale */}
        <div className="center-panel">
          <ChessboardCreation />
        </div>

        {/* Colonne droite */}
        <div className="right-panel">
          <OpeningTree />
        </div>
      </div>
    </div>
  );
}
