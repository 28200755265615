import { ReactNode } from "react";

// Interface pour les propriétés des icônes
interface IconProps {
  width?: string | number;
  height?: string | number;
}

// Fonction qui retourne les icônes avec les dimensions spécifiées
const icons = ({
  width = 20,
  height = 20,
}: IconProps): Record<string, ReactNode> => ({
  new: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="UploadedToSvgRepo"
      height={height}
      width={width}
      viewBox="0 0 32 32"
      xmlSpace="preserve"
    >
      <path
        d="M26.121,8.122l-5.243-5.243C20.316,2.316,19.553,2,18.757,2H8C6.343,2,5,3.343,5,5v22  
         c0,1.657,1.343,3,3,3h16c1.657,0,3-1.343,3-3V10.243C27,9.447,26.684,8.684,26.121,8.122z M20,3.445  
         c0.058,0.046,0.118,0.088,0.172,0.141l5.243,5.243C25.467,8.882,25.509,8.942,25.555,9H22c-1.103,0-2-0.897-2-2V3.445z M26,27  
         c0,1.103-0.897,2-2,2H8c-1.103,0-2-0.897-2-2V5c0-1.103,0.897-2,2-2h10.757C18.84,3,18.919,3.014,19,3.024V7c0,1.654,1.346,3,3,3  
         h3.976C25.986,10.081,26,10.16,26,10.243V27z M20,14h-2v-2c0-0.552-0.448-1-1-1h-2c-0.552,0-1,0.448-1,1v2h-2c-0.552,0-1,0.448-1,1  
         v2c0,0.552,0.448,1,1,1h2v2c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-2h2c0.552,0,1-0.448,1-1v-2C21,14.448,20.552,14,20,14z   
         M20,17h-3v3h-2v-3h-3v-2h3v-3h2v3h3V17z"
      />
    </svg>
  ),
  penUpdate: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      version="1.1"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 441 512.399"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />

        <path
          fill-rule="nonzero"
          d="M102.78 354.886c-5.727,0 -10.372,-4.645 -10.372,-10.372 0,-5.727 4.645,-10.372 10.372,-10.372l85.566 0c-3.023,6.667 -5.569,13.598 -7.597,20.744l-77.969 0zm0 -72.688c-5.727,0 -10.372,-4.646 -10.372,-10.372 0,-5.728 4.645,-10.373 10.372,-10.373l143.271 0c2.829,0 5.394,1.134 7.264,2.971 -9.26,5.017 -17.929,10.986 -25.876,17.774l-124.659 0zm0 -72.682c-5.727,0 -10.372,-4.645 -10.372,-10.372 0,-5.727 4.645,-10.372 10.372,-10.372l151.286 0c5.727,0 10.373,4.645 10.373,10.372 0,5.727 -4.646,10.372 -10.373,10.372l-151.286 0z"
        />

        <path d="M324.263 278.925c32.232,0 61.419,13.067 82.545,34.192 21.125,21.124 34.192,50.313 34.192,82.543 0,32.236 -13.067,61.419 -34.192,82.544 -21.126,21.126 -50.311,34.195 -82.545,34.195 -32.232,0 -61.418,-13.069 -82.542,-34.195 -21.126,-21.125 -34.195,-50.312 -34.195,-82.544 0,-32.232 13.069,-61.417 34.195,-82.543 21.125,-21.125 50.308,-34.192 82.542,-34.192zm-263.4 -278.925l174.81 0c3.382,0 6.384,1.619 8.278,4.123l110.107 119.12c1.839,1.987 2.746,4.503 2.746,7.011l0.052 0 0 119.819c-6.756,-1.506 -13.686,-2.554 -20.752,-3.112l0 -92.212 -43.666 0 0 -0.042 -0.161 0c-22.046,-0.349 -39.33,-6.222 -51.694,-16.784 -12.849,-10.979 -20.063,-26.615 -21.503,-46.039 -0.062,-0.458 -0.096,-0.928 -0.096,-1.405l0 -69.727 -158.121 0c-11.02,0 -21.049,4.516 -28.321,11.79 -7.274,7.272 -11.79,17.301 -11.79,28.321l0 338.276c0,11.015 4.521,21.038 11.796,28.312 7.278,7.28 17.31,11.801 28.315,11.801l120.749 0c2.196,7.158 4.915,14.092 8.116,20.752l-128.865 0c-16.73,0 -31.958,-6.849 -42.987,-17.881 -11.024,-11.024 -17.876,-26.241 -17.876,-42.984l0 -338.276c0,-16.749 6.842,-31.969 17.87,-42.993 11.024,-11.028 26.244,-17.87 42.993,-17.87zm178.873 29.983l0 60.432c1.021,13.738 5.819,24.536 14.302,31.784 8.668,7.404 21.488,11.544 38.4,11.835l0 -0.038 43.443 0 -96.145 -104.013zm80.674 408.278l-46.037 12.272 6.638 -49.409 39.399 37.137zm-29.254 -48.356l45.215 -51.568c0.758,-0.885 1.875,-1.412 3.034,-1.443 0.727,-0.011 1.444,0.138 2.086,0.485l35.071 31.877c0.695,0.654 1.116,1.561 1.136,2.518 0.032,1.065 -0.42,2.118 -1.232,2.824l-46.036 52.442 -39.328 -37.135 0.054 0z" />
      </g>
    </svg>
  ),
  hearth: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      height={height}
      width={width}
      viewBox="0 0 489.292 489.292"
      xmlSpace="preserve"
    >
      <g id="XMLID_9_">
        <g id="XMLID_1562_">
          <path
            style={{ fill: "#F7931E" }}
            d="M481.646,162.772c0-73.89-59.9-133.79-133.79-133.79c-43.264,0-80.821,19.594-105.276,51.449    c-23.692-31.796-62.265-51.449-104.965-51.449C65.834,28.982,7.646,87.17,7.646,158.95c0,30.039,5.822,62.087,22.936,84.097l0,0    L244.646,458.23L443.42,258.337l0,0C467.812,234.102,481.646,199.873,481.646,162.772z"
          />
        </g>
        <g id="XMLID_1602_">
          <path
            style={{ fill: "#FBB03B" }}
            d="M244.646,82.498c0,0,116.976,84.125,2.239,373.48L22.476,229.99l-14.83-65.396l20.421-77.871    L83.4,40.056l60.977-12.193l69.689,24.054L244.646,82.498z"
          />
        </g>
        <g id="XMLID_1699_">
          <polygon
            style={{ fill: "#FFFFFF" }}
            points="246.885,455.978 22.476,229.99 15.291,196.199 260.216,442.625"
          />
        </g>
        <g id="XMLID_514_">
          <path
            style={{ fill: "#1D1D1B" }}
            d="M244.645,469.074L48.201,271.479C17.566,242.97,0,204.022,0,164.594    C0,84.985,64.768,20.217,144.377,20.217c36.431,0,72.554,15.32,100.269,42.286c27.716-26.966,63.84-42.286,100.27-42.286    c79.609,0,144.376,64.768,144.376,144.377c0,39.428-17.566,78.377-48.2,106.885L244.645,469.074z M144.377,35.508    c-71.179,0-129.086,57.907-129.086,129.086c0,34.721,16.234,70.528,43.429,95.786l0.219,0.211l185.707,186.795l185.815-186.902    l0.111-0.104C457.766,235.124,474,199.316,474,164.594c0-71.179-57.907-129.086-129.085-129.086    c-34.701,0-69.206,15.722-94.668,43.135l-5.602,6.03l-5.602-6.03C213.583,51.23,179.078,35.508,144.377,35.508z"
          />
        </g>
        <g id="XMLID_579_">
          <rect
            x="286.694"
            y="294.433"
            transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 252.226 812.2708)"
            style={{ fill: "#1D1D1B" }}
            width="15.291"
            height="118.93"
          />
        </g>
      </g>
    </svg>
  ),
  premium: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <polygon
        style={{ fill: "#FFDB6C" }}
        points="503.717,166.922 8.265,166.922 87.338,55.893 424.644,55.893"
      />
      <polygon
        style={{ fill: "#FFB04C" }}
        points="8.265,166.922 503.717,166.922 255.991,456.108"
      />
      <polygon
        style={{ fill: "#FFC866" }}
        points="424.644,55.893 424.644,166.922 503.717,166.922"
      />
      <polygon
        style={{ fill: "#F7A05E" }}
        points="424.644,166.922 255.991,456.108 503.717,166.922"
      />
      <polygon
        style={{ fill: "#FFDB6C" }}
        points="87.338,55.893 424.644,55.893 255.991,456.108"
      />
      <polygon
        style={{ fill: "#FFF2BB" }}
        points="377.855,166.922 424.644,55.893 87.338,55.893 134.127,166.922"
      />
      <polygon
        style={{ fill: "#FFEB8A" }}
        points="134.127,166.922 255.991,55.893 377.855,166.922"
      />
      <path d="M511.617,169.434c0.779-2.449,0.355-5.23-1.137-7.327L431.408,51.076c-1.551-2.176-4.153-3.508-6.826-3.486H87.391  c-2.67-0.02-5.268,1.314-6.816,3.486L1.503,162.107c-2.166,3.042-1.971,7.375,0.457,10.216l247.726,289.186  c1.577,1.842,3.881,2.901,6.305,2.901c2.424,0,4.728-1.059,6.305-2.901l247.726-289.186c0.727-0.85,1.269-1.863,1.606-2.928  c-0.042,0.134,0.042-0.131-0.023,0.074C511.66,169.297,511.577,169.557,511.617,169.434  C512.395,166.985,511.557,169.619,511.617,169.434C512.395,166.985,511.557,169.619,511.617,169.434z M259.172,67.125l97.677,91.496  H156.001L259.172,67.125z M137.28,153.029L99.845,64.194h137.607L137.28,153.029z M280.333,64.194h131.806l-37.304,88.524  L280.333,64.194z M426.515,72.831l61.097,85.791h-97.248L426.515,72.831z M85.467,72.83l36.152,85.791H24.37L85.467,72.83z   M255.991,443.347L26.308,175.224h107.734c0.03,0,0.059,0.007,0.089,0.007c0.044,0,0.089-0.006,0.132-0.007h243.546  c0.014,0,0.03,0.002,0.044,0.002c0.01,0,0.02-0.002,0.03-0.002h107.792L255.991,443.347z" />
      <path d="M156.945,199.694c-1.781-4.225-6.654-6.208-10.873-4.427c-4.225,1.78-6.207,6.649-4.427,10.873l73.542,174.515  c1.337,3.172,4.414,5.081,7.653,5.081c1.076,0,2.168-0.209,3.22-0.654c4.225-1.78,6.207-6.649,4.427-10.873L156.945,199.694z" />
      <path d="M365.912,195.267c-4.223-1.781-9.093,0.201-10.873,4.427l-72.197,171.323c-1.78,4.224,0.201,9.093,4.427,10.873  c1.053,0.444,2.145,0.654,3.22,0.654c3.239,0,6.317-1.908,7.653-5.081l72.197-171.323  C372.118,201.917,370.137,197.047,365.912,195.267z" />
    </svg>
  ),

  stop: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="invisible_box" data-name="invisible box">
          <rect width="48" height="48" fill="none" />
        </g>
        <g id="icons_Q2" data-name="icons Q2">
          <path
            fill="red"
            d="M43.4,15.1,32.9,4.6A2,2,0,0,0,31.5,4h-15a2,2,0,0,0-1.4.6L4.6,15.1A2,2,0,0,0,4,16.5v15a2,2,0,0,0,.6,1.4L15.1,43.4a2,2,0,0,0,1.4.6h15a2,2,0,0,0,1.4-.6L43.4,32.9a2,2,0,0,0,.6-1.4v-15A2,2,0,0,0,43.4,15.1Z"
          />

          <path
            fill="white"
            d="M32.4,29.6a1.9,1.9,0,0,1,0,2.8,1.9,1.9,0,0,1-2.8,0L24,26.8l-5.6,5.6a1.9,1.9,0,0,1-2.8,0,1.9,1.9,0,0,1,0-2.8L21.2,24l-5.6-5.6a2,2,0,0,1,2.8-2.8L24,21.2l5.6-5.6a2,2,0,0,1,2.8,2.8L26.8,24Z"
          />
        </g>
      </g>
    </svg>
  ),

  open: (
    <svg
      className="icon_open"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 128 128"
    >
      <path d="M 84 11 C 82.3 11 81 12.3 81 14 C 81 15.7 82.3 17 84 17 L 106.80078 17 L 60.400391 63.400391 C 59.200391 64.600391 59.200391 66.499609 60.400391 67.599609 C 61.000391 68.199609 61.8 68.5 62.5 68.5 C 63.2 68.5 63.999609 68.199609 64.599609 67.599609 L 111 21.199219 L 111 44 C 111 45.7 112.3 47 114 47 C 115.7 47 117 45.7 117 44 L 117 14 C 117 12.3 115.7 11 114 11 L 84 11 z M 24 31 C 16.8 31 11 36.8 11 44 L 11 104 C 11 111.2 16.8 117 24 117 L 84 117 C 91.2 117 97 111.2 97 104 L 97 59 C 97 57.3 95.7 56 94 56 C 92.3 56 91 57.3 91 59 L 91 104 C 91 107.9 87.9 111 84 111 L 24 111 C 20.1 111 17 107.9 17 104 L 17 44 C 17 40.1 20.1 37 24 37 L 69 37 C 70.7 37 72 35.7 72 34 C 72 32.3 70.7 31 69 31 L 24 31 z"></path>
    </svg>
  ),

  facebook: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#FFFFFF"
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 310 310"
    >
      <g id="XMLID_834_">
        <path
          id="XMLID_835_"
          d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064   c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996   V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545   C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703   c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"
        />
      </g>
    </svg>
  ),

  disconnect: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 330 330"
    >
      <g id="XMLID_2_">
        <path
          id="XMLID_4_"
          d="M51.213,180h173.785c8.284,0,15-6.716,15-15s-6.716-15-15-15H51.213l19.394-19.393   c5.858-5.857,5.858-15.355,0-21.213c-5.856-5.858-15.354-5.858-21.213,0L4.397,154.391c-0.348,0.347-0.676,0.71-0.988,1.09   c-0.076,0.093-0.141,0.193-0.215,0.288c-0.229,0.291-0.454,0.583-0.66,0.891c-0.06,0.09-0.109,0.185-0.168,0.276   c-0.206,0.322-0.408,0.647-0.59,0.986c-0.035,0.067-0.064,0.138-0.099,0.205c-0.189,0.367-0.371,0.739-0.53,1.123   c-0.02,0.047-0.034,0.097-0.053,0.145c-0.163,0.404-0.314,0.813-0.442,1.234c-0.017,0.053-0.026,0.108-0.041,0.162   c-0.121,0.413-0.232,0.83-0.317,1.257c-0.025,0.127-0.036,0.258-0.059,0.386c-0.062,0.354-0.124,0.708-0.159,1.069   C0.025,163.998,0,164.498,0,165s0.025,1.002,0.076,1.498c0.035,0.366,0.099,0.723,0.16,1.08c0.022,0.124,0.033,0.251,0.058,0.374   c0.086,0.431,0.196,0.852,0.318,1.269c0.015,0.049,0.024,0.101,0.039,0.15c0.129,0.423,0.28,0.836,0.445,1.244   c0.018,0.044,0.031,0.091,0.05,0.135c0.16,0.387,0.343,0.761,0.534,1.13c0.033,0.065,0.061,0.133,0.095,0.198   c0.184,0.341,0.387,0.669,0.596,0.994c0.056,0.088,0.104,0.181,0.162,0.267c0.207,0.309,0.434,0.603,0.662,0.895   c0.073,0.094,0.138,0.193,0.213,0.285c0.313,0.379,0.641,0.743,0.988,1.09l44.997,44.997C52.322,223.536,56.161,225,60,225   s7.678-1.464,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L51.213,180z"
        />
        <path
          id="XMLID_5_"
          d="M207.299,42.299c-40.944,0-79.038,20.312-101.903,54.333c-4.62,6.875-2.792,16.195,4.083,20.816   c6.876,4.62,16.195,2.794,20.817-4.083c17.281-25.715,46.067-41.067,77.003-41.067C258.414,72.299,300,113.884,300,165   s-41.586,92.701-92.701,92.701c-30.845,0-59.584-15.283-76.878-40.881c-4.639-6.865-13.961-8.669-20.827-4.032   c-6.864,4.638-8.67,13.962-4.032,20.826c22.881,33.868,60.913,54.087,101.737,54.087C274.956,287.701,330,232.658,330,165   S274.956,42.299,207.299,42.299z"
        />
      </g>
    </svg>
  ),

  login: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 330.002 330.002"
    >
      <g id="XMLID_9_">
        <path
          id="XMLID_10_"
          d="M169.392,199.395c-5.858,5.857-5.858,15.355,0,21.213c2.929,2.929,6.767,4.394,10.607,4.394   c3.838,0,7.678-1.465,10.606-4.394l44.998-44.997c0.347-0.347,0.676-0.712,0.988-1.091c0.069-0.084,0.128-0.176,0.196-0.262   c0.235-0.299,0.467-0.6,0.68-0.917c0.055-0.083,0.101-0.171,0.154-0.254c0.211-0.329,0.418-0.662,0.603-1.007   c0.032-0.06,0.057-0.123,0.088-0.184c0.194-0.374,0.378-0.753,0.541-1.145c0.017-0.04,0.028-0.081,0.044-0.121   c0.167-0.411,0.32-0.829,0.45-1.258c0.014-0.046,0.022-0.094,0.036-0.14c0.123-0.419,0.235-0.844,0.321-1.278   c0.024-0.121,0.035-0.245,0.056-0.367c0.063-0.36,0.125-0.72,0.162-1.088c0.05-0.496,0.076-0.995,0.076-1.497   c0-0.503-0.026-1.002-0.076-1.497c-0.037-0.371-0.1-0.734-0.164-1.097c-0.021-0.119-0.031-0.24-0.055-0.358   c-0.087-0.437-0.199-0.864-0.323-1.286c-0.013-0.044-0.02-0.088-0.034-0.132c-0.131-0.432-0.286-0.852-0.454-1.267   c-0.015-0.036-0.025-0.075-0.041-0.111c-0.164-0.394-0.349-0.776-0.544-1.152c-0.03-0.058-0.054-0.119-0.085-0.176   c-0.187-0.348-0.394-0.682-0.606-1.013c-0.053-0.082-0.097-0.168-0.151-0.249c-0.213-0.317-0.445-0.62-0.681-0.919   c-0.067-0.086-0.126-0.177-0.195-0.261c-0.312-0.379-0.641-0.744-0.988-1.091l-44.998-44.998c-5.857-5.858-15.355-5.858-21.213,0   c-5.858,5.858-5.858,15.355,0,21.213l19.393,19.394H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h173.785L169.392,199.395z"
        />
        <path
          id="XMLID_11_"
          d="M207.301,42.3c-40.945,0-79.04,20.312-101.903,54.333c-4.621,6.876-2.793,16.196,4.083,20.816   c6.876,4.621,16.196,2.793,20.816-4.083C147.578,87.652,176.365,72.3,207.301,72.3c51.116,0,92.701,41.586,92.701,92.701   s-41.586,92.701-92.701,92.701c-30.844,0-59.585-15.283-76.879-40.882c-4.638-6.864-13.962-8.67-20.827-4.032   c-6.864,4.638-8.67,13.962-4.032,20.827c22.882,33.868,60.915,54.087,101.738,54.087c67.658,0,122.701-55.044,122.701-122.701   S274.958,42.3,207.301,42.3z"
        />
      </g>
    </svg>
  ),
});

export default icons;
