import { useEffect, useState } from "react";

// Fonction pour jouer les sons
const playAudios = async (audios: HTMLAudioElement[]) => {
  for (const audio of audios) {
    try {
      await audio.play();
    } catch (error) {
      console.error("Erreur lors de la lecture de l'audio :", error);
    }
  }
};

// Hook pour jouer les sons en fonction du SAN (notation des coups d'échecs)
export function useAudio(san: string | undefined) {
  const [audioQueue, setAudioQueue] = useState<HTMLAudioElement[]>([]);

  useEffect(() => {
    if (!san) return;

    const audios: HTMLAudioElement[] = [];

    // Vérifier si c'est un roque (petit ou grand)
    if (san === "O-O" || san === "O-O-O") {
      audios.push(new Audio("/sounds/castle.mp3")); // Son pour le roque
    }

    // Vérifier s'il y a une capture
    if (san.includes("x")) {
      audios.push(new Audio("/sounds/capture.mp3")); // Son de capture
    }

    // Vérifier s'il y a un échec
    if (san.includes("+")) {
      audios.push(new Audio("/sounds/move-check.mp3")); // Son pour l'échec
    }

    // Si ce n'est pas un roque, une capture ou un échec, jouer le son de déplacement normal
    if (audios.length === 0) {
      audios.push(new Audio("/sounds/move-self.mp3")); // Son de déplacement normal
    }

    // Mettre à jour la file d'attente des sons
    setAudioQueue(audios);

    // Jouer les sons de la file d'attente
    playAudios(audios);

    // Nettoyage : arrêter tous les sons en cours et réinitialiser
    return () => {
      audios.forEach((audio) => {
        audio.pause();
        audio.currentTime = 0;
      });
    };
  }, [san]);

  // Nettoyer l'ancienne file d'attente si le SAN change
  useEffect(() => {
    return () => {
      audioQueue.forEach((audio) => {
        audio.pause();
        audio.currentTime = 0;
      });
    };
  }, [audioQueue]);
}

// Fonction simple pour jouer un son illégal
export const playIllegalMoveSound = () => {
  const illegalAudio = new Audio("/sounds/illegal.mp3");
  illegalAudio.play().catch((error) => {
    console.error("Erreur lors de la lecture de l'audio :", error);
  });
};
