import { evaluation } from "../../../assets/svg/evaluation";
import { ChoiceEvaluation } from "../../../types";
import "./MoveEvaluationSelector.scss";

interface MoveEvaluationSelectorProps {
  activeButton: string | null;
  onButtonClick: (button: ChoiceEvaluation) => void;
}

export default function MoveEvaluationSelector({
  activeButton,
  onButtonClick,
}: MoveEvaluationSelectorProps) {
  return (
    <div className="move-evaluation-selector">
      <div
        className={`button ${activeButton === "perfect" ? "active" : ""}`}
        onClick={() => onButtonClick("perfect")}
      >
        {evaluation.perfect({
          size: 20,
        })}
      </div>
      <div
        className={`button ${activeButton === "good" ? "active" : ""}`}
        onClick={() => onButtonClick("good")}
      >
        {evaluation.good({
          size: 20,
        })}
      </div>

      <div
        className={`button ${activeButton === "mistake" ? "active" : ""}`}
        onClick={() => onButtonClick("mistake")}
      >
        {evaluation.mistake({
          size: 20,
        })}
      </div>

      <div
        className={`button ${activeButton === "error" ? "active" : ""}`}
        onClick={() => onButtonClick("error")}
      >
        {evaluation.error({
          size: 20,
        })}
      </div>
    </div>
  );
}
