import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import Modal from "react-modal";
import {
  fetchArticleDetails,
  fetchSubscriptionPrices,
} from "../../services/api";
import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import { useSubscriptionStore } from "../../store/useSubscriptionStore";
import { useUserStore } from "../../store/useUserStore";
import { InfoSubscription } from "../../types";
import LoaderChess from "../commun/LoaderChess/LoaderChess";
import FormPayement from "./FormPayement/FormPayement";
import NotLogged from "./NotLogged/NotLogged";
import "./PayementModal.scss";
const API_URL = process.env.REACT_APP_API_URL || "";
Modal.setAppElement("#root");

interface PayementModalProps {
  isOpen: boolean;
  closeModal: () => void;
  overlayPointerEvent: boolean;
  articleId?: string; // Nouvelle prop pour l'ID de l'article
}
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

const PayementModal: React.FC<PayementModalProps> = ({
  isOpen,
  closeModal,
  overlayPointerEvent,
}) => {
  const { isPremium, isLogged } = useUserStore();
  const { infoOpeningData } = useLessonOpeningStore();
  const { selectArticle, selectedArticle } = useSubscriptionStore();
  const [subscriptions, setSubscriptions] = useState<InfoSubscription[]>([]);

  const { isLoadingPayment } = useSubscriptionStore();

  // Gérer le style du body lors de l'ouverture/fermeture de la modal
  useEffect(() => {
    if ((isLogged && infoOpeningData.hasPurchased) || isPremium()) {
      closeModal();
    }
  }, [isOpen, isLogged, infoOpeningData.hasPurchased]);

  // Récupérer les abonnements si l'utilisateur est connecté et non premium
  useEffect(() => {
    if (isLogged && !isPremium()) {
      fetchSubscriptionPrices()
        .then((data) => setSubscriptions(data))
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des abonnements :",
            error
          );
        });
    }
  }, [isLogged, isPremium]);

  // Récupérer les détails de l'article lorsque la modal s'ouvre et un ID est fourni
  useEffect(() => {
    if (isOpen && infoOpeningData._id) {
      fetchArticleDetails(infoOpeningData._id)
        .then((data) => {
          selectArticle(data);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération de l'article :", error);
        });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={!overlayPointerEvent}
      contentLabel="payement Modal"
      className="payementModal"
      overlayClassName={`payementModal-overlay`}
    >
      {isLoadingPayment ? null : (
        <div onClick={closeModal}>
          <RiCloseCircleLine className="payementModal_close-icon" />
        </div>
      )}

      {!isLogged && (
        <div className="ContentNotLoggedInPremium">
          <NotLogged message="Veuiller vous connecter pour proceder au paiement" />
        </div>
      )}
      {isLogged && !isPremium() && (
        <>
          {selectedArticle ? (
            <Elements stripe={stripePromise}>
              <FormPayement />
            </Elements>
          ) : (
            <LoaderChess />
          )}
        </>
      )}
    </Modal>
  );
};

export default PayementModal;
