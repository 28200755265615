import { useState } from "react";
import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import GraduationPanel from "./GraduationPanel/GraduationPanel";
import LearnPanel from "./LearnPanel/LearnPanel";
import TrainingPanel from "./TrainingPanel/TrainingPanel";

const PanelContainer = () => {
  // Gestion de l'état pour savoir si on est en mode "Learn" ou "Training"
  const { mode } = useLessonOpeningStore();
  const [isLocked, setIsLocked] = useState(false);

  // Rendre le bon composant en fonction du mode
  let component;

  if (mode === "cours" && !isLocked) {
    component = <LearnPanel />;
  } else if (mode === "entrainement" || isLocked) {
    component = <TrainingPanel setIsLocked={setIsLocked} />;
  } else {
    // Optionnel : un composant par défaut ou rien
    component = <GraduationPanel />;
  }

  return <>{component}</>;
};

export default PanelContainer;
