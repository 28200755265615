import React from "react";
import { Chessboard } from "react-chessboard";
import "./ChessboardSizeHandler.scss";

interface ChessboardSizeHandlerProps {
  position: string;
  boardSize: number;
  customArrows: any;
  arePiecesDraggable: boolean;
  onPieceDrop?: (sourceSquare: string, targetSquare: string) => boolean;
  onSquareClick: (square: string) => void;
  boardOrientation: "white" | "black";
  resizable?: boolean;
  createArrow?: boolean;
  isMode?: boolean;
  customSquareStyles?: any;
}

const ChessboardSizeHandler: React.FC<ChessboardSizeHandlerProps> = ({
  position,
  customArrows,
  arePiecesDraggable,
  onPieceDrop,
  onSquareClick,
  boardOrientation,
  customSquareStyles,
  boardSize,
  createArrow = true,
}) => {
  return (
    <Chessboard
      position={position}
      boardWidth={boardSize}
      customArrows={customArrows}
      arePiecesDraggable={arePiecesDraggable}
      onPieceDrop={onPieceDrop}
      onSquareClick={onSquareClick}
      boardOrientation={boardOrientation}
      areArrowsAllowed={createArrow}
      customSquareStyles={customSquareStyles}
    />
  );
};

export default ChessboardSizeHandler;
