// store/useAnnotationsStore.ts
import { create } from "zustand";

type AnnotationType =
  | "error"
  | "warning"
  | "success"
  | "circle"
  | "arrow"
  | "legalMove"
  | "check"
  | "selectPiece"
  | "captureMove";

type Annotation = {
  position: string;
  type: AnnotationType;
  color?: string; // Couleur pour les cercles et les flèches
  from?: string; // Position de départ pour les flèches
  to?: string; // Position d'arrivée pour les flèches
};

interface AnnotationsState {
  annotations: Annotation[];
  addAnnotation: (
    position: string,
    type: AnnotationType,
    color?: string,
    from?: string,
    to?: string
  ) => void;
  removeAnnotationByPosition: (position: string, type?: AnnotationType) => void;
  removeAllAnnotations: () => void;
  getAnnotations: () => Annotation[];
  generateCustomSquareStyles: () => Record<string, any>;
}

export const useAnnotationsStore = create<AnnotationsState>((set, get) => ({
  annotations: [],

  // Ajouter une annotation
  addAnnotation: (position, type, color, from, to) => {
    set((state) => ({
      annotations: [...state.annotations, { position, type, color, from, to }],
    }));
  },

  // Supprimer une annotation par position et type
  removeAnnotationByPosition: (position, type) =>
    set((state) => ({
      annotations: state.annotations.filter(
        (annotation) =>
          annotation.position !== position ||
          (type ? annotation.type !== type : true)
      ),
    })),

  // Supprimer toutes les annotations
  removeAllAnnotations: () => set(() => ({ annotations: [] })),

  // Obtenir toutes les annotations
  getAnnotations: () => {
    const state = get();
    return state.annotations;
  },

  // Générer les styles personnalisés pour les cases en fonction des annotations
  generateCustomSquareStyles: () => {
    const state = get();

    return state.annotations.reduce((styles: any, annotation: any) => {
      if (
        annotation.type === "error" ||
        annotation.type === "success" ||
        annotation.type === "selectPiece"
      ) {
        styles[annotation.position] = {
          backgroundColor:
            annotation.type === "error"
              ? "rgba(255, 0, 0, 0.5)" // Fond rouge semi-transparent pour les erreurs
              : "hsl(34, 78%, 60%,0.7)", // Fond orange semi-transparent pour les succès
        };
      }

      if (annotation.type === "legalMove" && annotation.color) {
        styles[annotation.position] = {
          background: `radial-gradient(circle at center, ${annotation.color} 22%, transparent 23%)`,
          borderRadius: "50%",
        };
      }

      if (annotation.type === "captureMove" && annotation.color) {
        styles[annotation.position] = {
          background: `
            radial-gradient(circle, transparent 75%, ${annotation.color} 36%)
          `,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        };
      }

      if (annotation.type === "check" && annotation.color) {
        styles[annotation.position] = {
          background: `
            radial-gradient(circle at center, ${annotation.color} 0%, ${annotation.color} 60%, transparent 100%)
          `,
          borderRadius: "10%", // Légèrement arrondi pour créer un effet subtil
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          boxShadow: `inset 0 0 3px ${annotation.color}, 0 0 8px ${annotation.color}`, // Ombre atténuée pour un effet moins intense
        };
      }

      if (annotation.type === "circle" && annotation.color) {
        styles[annotation.position] = {
          background: `radial-gradient(circle at center, transparent 60%, ${annotation.color} 75%, transparent 80%)`,
          borderRadius: "50%",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        };
      }

      return styles;
    }, {});
  },
}));
