import React from "react";
import { defaultPieces } from "../../../assets/svg/pieces";
import "./ColorSelectorBoard.scss";

interface ColorSelectorBoardProps {
  selectedColor: string | null;
  onColorSelect: (color: string | null) => void;
}

const ColorSelectorBoard: React.FC<ColorSelectorBoardProps> = ({
  selectedColor,
  onColorSelect,
}) => {
  const pieces = defaultPieces({ width: "25px", height: "25px" });

  const handleColorClick = (color: string) => {
    if (selectedColor === color) {
      onColorSelect(null);
    } else {
      onColorSelect(color);
    }
  };

  return (
    <div className="color-selectorBoard">
      <button
        onClick={() => handleColorClick("w")}
        className={`color-button ${selectedColor === "w" ? "active" : ""}`}
        aria-label="white"
      >
        {pieces.wP}
      </button>
      <button
        onClick={() => handleColorClick("b")}
        className={`color-button ${selectedColor === "b" ? "active" : ""}`}
        aria-label="black"
      >
        {pieces.bP}
      </button>
    </div>
  );
};

export default ColorSelectorBoard;
