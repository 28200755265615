// src/services/api.ts

import axios from "axios";
import {
  ArticleStripeDetails,
  InfoSubscription,
  OpeningDataHomePage,
} from "../types"; // Assurez-vous que les types sont correctement définis et importés
const API_URL = process.env.REACT_APP_API_URL || "";

/**
 * Récupère tous les prix d'abonnement actifs pour un produit spécifique depuis l'API.
 * @returns Une promesse qui résout un tableau d'InfoSubscription.
 */
export const fetchSubscriptionPrices = async (): Promise<
  InfoSubscription[]
> => {
  try {
    const response = await fetch(`${API_URL}/api/prices`);

    if (!response.ok) {
      throw new Error("Échec de la récupération des informations d'abonnement");
    }

    const data: InfoSubscription[] = await response.json();
    return data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations d'abonnement :",
      error
    );
    throw error;
  }
};

/**
 * Récupère les informations détaillées sur les abonnements depuis l'API.
 * @returns Une promesse qui résout un tableau d'InfoSubscription.
 */
export const fetchSubscriptions = async (): Promise<InfoSubscription[]> => {
  try {
    const response = await fetch(`${API_URL}/api/subscriptions`);

    if (!response.ok) {
      throw new Error("Échec de la récupération des abonnements");
    }

    const data: InfoSubscription[] = await response.json();
    return data;
  } catch (error) {
    console.error("Erreur lors de la récupération des abonnements :", error);
    throw error;
  }
};

/**
 * Récupère les données d'ouverture depuis l'API.
 * @returns Une promesse qui résout un tableau d'OpeningDataHomePage.
 */
export const fetchOpeningsData = async (): Promise<OpeningDataHomePage[]> => {
  try {
    const response = await axios.get(`${API_URL}/api/get-openings`);

    const openingsData = response.data;

    const tabOpeningData: OpeningDataHomePage[] = [];

    openingsData.forEach((opening: OpeningDataHomePage) => {
      const {
        _id,
        title,
        level,
        color,
        description,
        history,
        nbrOfPath,
        pathForPremium,
        creator,
        likes,
        favorites,
      } = opening;

      tabOpeningData.push({
        _id,
        creator,
        title,
        nbrOfPath,
        level,
        pathForPremium,
        color,
        description,
        history,
        likes,
        favorites,
      } as OpeningDataHomePage);
    });

    return tabOpeningData;
  } catch (error) {
    console.error("Erreur lors de la récupération des ouvertures :", error);
    throw error;
  }
};

/**
 * Effectue un achat pour un article spécifique.
 * @param id L'ID de l'article à acheter.
 * @param paymentMethodId L'ID de la méthode de paiement Stripe.
 * @returns Une promesse qui résout le message de confirmation.
 */
export const purchaseArticle = async (
  id: string,
  paymentMethodId: string
): Promise<{ message: string; openingId: string }> => {
  try {
    const response = await axios.post(
      `${API_URL}/api/purchase/${id}`,
      { paymentMethodId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Assurez-vous d'utiliser un token valide.
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'achat de l'article :", error);
    throw error;
  }
};

/**
 * Récupère les détails d'un article spécifique par son ID.
 * @param id L'ID de l'article.
 * @returns Une promesse qui résout les détails de l'article.
 */
export const fetchArticleDetails = async (
  id: string
): Promise<ArticleStripeDetails> => {
  try {
    const response = await axios.get(
      `${API_URL}/api/article/stripe-details/${id}`
    );

    if (!response.data) {
      throw new Error("Les détails de l'article sont introuvables.");
    }

    return response.data as ArticleStripeDetails;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des détails de l'article :",
      error
    );
    throw error;
  }
};

/**
 * Récupère les données d'ouverture par créateur depuis l'API.
 * @param creator Le nom du créateur dont on souhaite récupérer les ouvertures.
 * @returns Une promesse qui résout un tableau d'OpeningDataHomePage.
 */
export const fetchOpeningsByCreator = async (
  creator: string
): Promise<OpeningDataHomePage[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/api/get-openings-by-creator/${encodeURIComponent(creator)}`
    );

    const openingsData = response.data;
    const tabOpeningData: OpeningDataHomePage[] = [];

    openingsData.forEach((opening: OpeningDataHomePage) => {
      console.log(opening);
      const {
        _id,
        title,
        level,
        color,
        description,
        history,
        nbrOfPath,
        pathForPremium,
        creator,
      } = opening;

      tabOpeningData.push({
        _id,
        creator,
        title,

        nbrOfPath,
        level,
        pathForPremium,
        color,
        description,
        history,
      } as OpeningDataHomePage);
    });

    return tabOpeningData;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des ouvertures par créateur :",
      error
    );
    throw error;
  }
};
