import React from "react";
import "./TabNavigation.scss";

interface TabNavigationProps {
  activeTab: "subscription" | "cours";
  onTabChange: (tab: "subscription" | "cours") => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({
  activeTab,
  onTabChange,
}) => {
  return (
    <div className="tab-navigation">
      <button
        className={activeTab === "subscription" ? "active" : ""}
        onClick={() => onTabChange("subscription")}
      >
        🎟️ Subscription
      </button>
      <button
        className={activeTab === "cours" ? "active" : ""}
        onClick={() => onTabChange("cours")}
      >
        📚 Cours
      </button>
    </div>
  );
};

export default TabNavigation;
