import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useSubscriptionStore } from "../../../store/useSubscriptionStore";
import { InfoSubscription } from "../../../types";
import ChoiceSubscription from "../ChoiceSubscription/ChoiceSubscription";
import FormPayement from "../FormPayement/FormPayement";
import "./LogicBuy.scss";

interface LogicBuyProps {
  data: InfoSubscription[];
}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);
const LogicBuy: React.FC<LogicBuyProps> = ({ data }) => {
  const { selectedSubscription, resetSubscription } = useSubscriptionStore();

  // Utiliser useEffect pour réinitialiser le store à la destruction du composant
  useEffect(() => {
    return () => {
      resetSubscription(); // Réinitialise le store lorsque le composant est démonté
    };
  }, [resetSubscription]);

  return (
    <div className="contentLogicBuy">
      {selectedSubscription ? (
        <Elements stripe={stripePromise}>
          <FormPayement />
        </Elements>
      ) : (
        <ChoiceSubscription />
      )}
    </div>
  );
};

export default LogicBuy;
