import { useEffect } from "react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa"; // Import des icônes que tu vas utiliser
import brain from "../../../assets/brain.png";
import hourglass from "../../../assets/hourglass.png";
import route from "../../../assets/route.png";
import target from "../../../assets/target.png";
import { config } from "../../../config";
import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import { fireworkConfetti } from "../../../utils/confetti";
import { averageMoveTime } from "../../../utils/utils";
import SessionStats from "../SessionStats/SessionStats";
import "./EvaluationDetails.scss";

export default function EvaluationDetails() {
  const {
    resetMoveTimes,
    lastMoveTimeStart,
    completedVariante,
    nbrMemoryPosition,
    scoreGraduation,
    nbrMove,
    errorCount,
    movesTimes,
  } = useLessonOpeningStore();

  useEffect(() => {
    lastMoveTimeStart();

    return () => {
      resetMoveTimes();
    };
  }, []); // Le tableau vide assure que l'effet ne se déclenche qu'une fois, au montage et démontage

  useEffect(() => {
    if (scoreGraduation) {
      fireworkConfetti();
    }
  }, [scoreGraduation]);

  const calculateSuccessRate = () => {
    if (nbrMove === 0) return "N/A";
    return `${Math.min(
      Math.round(((nbrMove - errorCount) / nbrMove) * 100),
      100
    )}%`;
  };

  return scoreGraduation ? (
    <div className="scoreGraduation">
      <div className="score">{scoreGraduation}/20</div>

      <div className="recap-grid">
        <div className="recap-item">
          <img
            src={route}
            alt="variantes complétées"
            className="icon"
            loading="lazy"
          />
          <span>
            {completedVariante}/{config.nbrCompletedVariante}
          </span>
        </div>

        <div className="recap-item">
          <img
            src={brain}
            alt="postion memorizee"
            className="icon"
            loading="lazy"
          />{" "}
          <span>
            {nbrMemoryPosition}/{config.nbrMemoryPosition}
          </span>
        </div>

        <div className="recap-item">
          <FaCheckCircle className="icon" />
          <span>{nbrMove}</span>
        </div>

        <div className="recap-item">
          <FaExclamationTriangle className="icon error-count" />
          <span>{errorCount}</span>
        </div>
        <div className="recap-item">
          <img
            src={target}
            className="target-icon"
            alt="target"
            loading="lazy"
          />
          <span>{calculateSuccessRate()}</span>
        </div>
        <div className="recap-item">
          <img
            src={hourglass}
            className="hourglass-icon"
            alt="hourglass"
            loading="lazy"
          />

          <span>{averageMoveTime(movesTimes)}</span>
        </div>
      </div>
    </div>
  ) : (
    <div className="evaluationDetails">
      <SessionStats />
      <div className="evaluation">
        <div className="evaluation-item">
          <img src={route} alt="variante" loading="lazy" />
          <span>
            {completedVariante}/{config.nbrCompletedVariante}
          </span>
        </div>

        <div className="evaluation-item">
          <img
            src={brain}
            className="brain"
            alt="position memorize"
            loading="lazy"
          />
          <span>
            {nbrMemoryPosition}/{config.nbrMemoryPosition}
          </span>
        </div>
      </div>
    </div>
  );
}
