import React, { useEffect, useState } from "react";
import Header from "../../components/commun/header/header";

import LogicBuy from "../../components/PayementModal/LogicBuy/LogicBuy";
import RecapPremium from "../../components/Premium/RecapPremium/RecapPremium";
import { fetchSubscriptionPrices } from "../../services/api";
import { useUserStore } from "../../store/useUserStore";
import { InfoSubscription } from "../../types";

import LoaderChess from "../../components/commun/LoaderChess/LoaderChess";
import NotLogged from "../../components/PayementModal/NotLogged/NotLogged";
import "./Premium.scss";

const PremiumPage: React.FC = () => {
  const { isPremium, isLogged } = useUserStore();

  // État local pour stocker les informations d'abonnement et de premium
  const [subscriptions, setSubscriptions] = useState<InfoSubscription[]>([]);
  const [loading, setLoading] = useState(false);
  const [isUserPremium, setIsUserPremium] = useState<boolean>(false);

  useEffect(() => {
    const checkPremiumStatus = async () => {
      if (isLogged) {
        setLoading(true);
        try {
          const premiumStatus = await isPremium();
          setIsUserPremium(premiumStatus);

          if (!premiumStatus) {
            const data = await fetchSubscriptionPrices();
            setSubscriptions(data);
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des abonnements :",
            error
          );
        } finally {
          setLoading(false);
        }
      }
    };

    checkPremiumStatus();
  }, [isLogged, isPremium]);

  return (
    <div className="pagePremium">
      <Header />
      <div className="contentPremium">
        {!isLogged && (
          <div className="ContentNotLoggedInPremium">
            <NotLogged message="Connectez vous pour acceder au premium" />
          </div>
        )}
        {isUserPremium && isLogged && (
          <div className="contentUnsubscribe">
            <RecapPremium />
          </div>
        )}
        {isLogged &&
          !isUserPremium &&
          (loading ? <LoaderChess /> : <LogicBuy data={subscriptions} />)}
      </div>
    </div>
  );
};

export default PremiumPage;
