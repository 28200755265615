import React, { useEffect } from "react";
import icons from "../../../assets/svg/icons";
import { useUserStore } from "../../../store/useUserStore";
// Assure-toi de remplacer par le bon chemin vers ton store
import { fireworkConfetti } from "../../../utils/confetti";
import "./RecapPremium.scss";
const RecapPremium: React.FC = () => {
  // Récupère les informations depuis le store
  const { isPremium, subscriptionExpiry } = useUserStore();
  useEffect(() => {
    fireworkConfetti();
  }, []);

  return (
    <div className="recapPremium">
      <div className="recapPremium-content" style={{ textAlign: "center" }}>
        <div className="icon-container">
          {icons({ width: "100px", height: "100px" }).premium}
        </div>
        <h2>Vous êtes un membre Premium !</h2>
        {subscriptionExpiry && (
          <p>
            Votre abonnement premium se termine le{" "}
            <strong>{new Date(subscriptionExpiry).toLocaleDateString()}</strong>
            .
          </p>
        )}
        <div className="recapPremium_soustient">
          <p className="secondary-text">Merci de votre soutien continu. </p>
          {icons({ width: "40px", height: "40px" }).hearth}{" "}
        </div>
      </div>
    </div>
  );
};

export default RecapPremium;
