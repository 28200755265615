import React, { useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";

import { Crop } from "react-image-crop";
import ImageCropModal from "./ImageCropModal/ImageCropModal";
import "./ImageResizer.scss";

interface ImageResizerProps {
  onImageCrop: (croppedImage: string) => void;
}

const ImageResizer: React.FC<ImageResizerProps> = ({ onImageCrop }) => {
  const [src, setSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file && file.size <= 5 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setSrc(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    } else {
      alert(
        "Le fichier est trop volumineux. Veuillez sélectionner une image de 5 MB ou moins."
      );
    }

    // Réinitialisation du champ fichier pour permettre une nouvelle sélection
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCancel = () => {
    setSrc(null);
  };

  const handleValidate = (croppedImage: string) => {
    onImageCrop(croppedImage);
    setSrc(null);
  };

  return (
    <div className="image-resizer">
      <div
        className="image-overlay"
        onClick={() => fileInputRef.current?.click()}
      >
        <FaPlus className="plus-icon" />
        <input
          ref={fileInputRef}
          id="file-input"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
      </div>
      <ImageCropModal
        src={src}
        crop={crop}
        setCrop={setCrop}
        onCancel={handleCancel}
        onValidate={handleValidate}
      />
    </div>
  );
};

export default ImageResizer;
