import { Chess } from "chess.js";

export class GameChess {
  private chess: Chess;

  constructor() {
    this.chess = new Chess();
  }

  // Obtenir le nombre de coups effectués
  public getMoveCount() {
    return this.chess.history().length;
  }

  public getHistory() {
    return this.chess.history();
  }

  // Obtenir la position actuelle du plateau au format FEN
  public getBoardPosition() {
    return this.chess.fen();
  }

  // Nouvelle fonction pour obtenir tous les coups légaux pour le prochain coup
  public getLegalMoves(): { from: string; to: string; san: string }[] {
    const moves = this.chess.moves({ verbose: true }); // Obtenir tous les coups légaux avec des informations détaillées
    return moves.map((move) => ({
      from: move.from, // La case de départ
      to: move.to, // La case d'arrivée
      san: move.san, // La notation SAN (Standard Algebraic Notation)
    }));
  }

  // Trouver le coup correspondant à une notation (comme 'Nf3')
  public findMoveBySAN(san: string): { from: string; to: string } | null {
    const moves = this.chess.moves({ verbose: true }); // Obtient tous les coups possibles en format détaillé
    for (const move of moves) {
      if (move.san === san) {
        return { from: move.from, to: move.to };
      }
    }
    return null;
  }

  // Récupérer le coup précédent (juste avant le coup actuel)
  public getPreviousMove(): { from: string; to: string; san: string } | null {
    const history = this.chess.history({ verbose: true }); // Obtenir l'historique complet des coups détaillés

    if (history.length > 0) {
      const previousMove = history[history.length - 1]; // Le dernier coup joué (coup précédent)
      return {
        from: previousMove.from,
        to: previousMove.to,
        san: previousMove.san,
      };
    }

    return null; // Retourne null s'il n'y a pas de coup précédent
  }

  // Charger une position FEN à partir d'un historique et vérifier chaque coup
  public loadPosition(history: string[]) {
    this.chess.reset(); // Réinitialiser le plateau
    for (const move of history) {
      const result = this.chess.move(move); // Appliquer chaque coup
      if (!result) {
        console.error(`Coup invalide trouvé : ${move}`);
        break;
      }
    }
  }

  public getCurrentPlayerColor(): string {
    return this.chess.turn(); // Renvoie 'w' si c'est au tour des Blancs, 'b' si c'est au tour des Noirs
  }
  // Annuler le dernier mouvement
  public undoLastMove() {
    if (this.chess.undo()) {
      // Retourner la position actuelle après l'annulation
      return this.chess.fen();
    }
    return null; // Retourne null si aucune annulation n'est possible
  }

  public reset() {
    this.chess.reset(); // Réinitialiser l'état du jeu à la position initiale
  }

  // Fonction pour vérifier si le roi est en échec et retourner sa position
  public getKingInCheckPosition(): {
    inCheck: boolean;
    position: string | null;
  } {
    if (!this.chess.inCheck()) {
      return { inCheck: false, position: null };
    }

    // Obtenir la couleur du joueur actuel ('w' pour blanc, 'b' pour noir)
    const currentPlayerColor = this.chess.turn();
    const board = this.chess.board(); // Récupérer le plateau en format 2D
    const files = ["a", "b", "c", "d", "e", "f", "g", "h"];

    // Parcourir le plateau pour trouver le roi
    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        const piece = board[rank][file];
        if (piece && piece.type === "k" && piece.color === currentPlayerColor) {
          const position = `${files[file]}${8 - rank}`;
          return { inCheck: true, position };
        }
      }
    }

    return { inCheck: false, position: null }; // Retourne null si aucun roi n'est trouvé
  }

  // Obtenir le statut actuel de la partie
  public getGameStatus() {
    if (this.chess.isCheckmate()) return "Échec et mat";
    if (this.chess.isDraw()) return "Partie nulle";
    if (this.chess.inCheck()) return "Échec";
    return "En cours";
  }

  // Trouver la notation SAN correspondant à un coup donné (from, to)
  public getSANFromMove(from: string, to: string): string | null {
    const moves = this.chess.moves({ verbose: true }); // Obtenir tous les coups possibles en format détaillé

    for (const move of moves) {
      if (move.from === from && move.to === to) {
        return move.san; // Renvoie la notation SAN du coup trouvé
      }
    }

    return null; // Retourne null si aucun coup ne correspond
  }

  // Nouvelle fonction pour générer une position FEN à partir d'un historique
  public generateFENFromHistory(history: string[]): string | null {
    this.chess.reset(); // Réinitialiser la partie

    for (const move of history) {
      const result = this.chess.move(move); // Appliquer chaque coup de l'historique
      if (!result) {
        console.error(`Coup invalide trouvé dans l'historique : ${move}`);
        return null; // Retourner null si un coup est invalide
      }
    }

    return this.chess.fen(); // Retourner la position FEN après avoir joué tous les coups
  }

  // Nouvelle fonction pour obtenir un objet avec la pièce sur chaque case
  public getPiecesOnBoard(): {
    [key: string]: { type: string; color: string } | null;
  } {
    const board = this.chess.board(); // Utilise chess.board() pour obtenir la représentation en tableau 2D
    const boardMap: { [key: string]: { type: string; color: string } | null } =
      {};

    // Notation des colonnes d'échecs
    const files = ["a", "b", "c", "d", "e", "f", "g", "h"];

    for (let rank = 0; rank < board.length; rank++) {
      for (let file = 0; file < board[rank].length; file++) {
        const piece = board[rank][file];
        const square = files[file] + (8 - rank); // Conversion en notation des échecs (par ex. 'a8', 'e4')

        if (piece) {
          // Ajoute la pièce sur cette case au mapping
          boardMap[square] = { type: piece.type, color: piece.color };
        } else {
          boardMap[square] = null; // Case vide
        }
      }
    }

    return boardMap;
  }

  public loadFromFEN(fen: string): void {
    this.chess.load(fen); // Charge la position FEN
  }

  public move(san: string) {
    return this.chess.move(san); // Joue le coup en notation SAN et retourne le résultat du coup
  }
}
