import React, { useEffect, useRef, useState } from "react";
import { useLessonOpeningStore } from "../../../../store/useLessonOpeningStore";
import { ArticleStripeDetails } from "../../../../types";
import ChessboardPresentation from "../../ChessboardPresentation/ChessboardPresentation";
import "./PricingArticleCard.scss";

// Fonction pour convertir le code de devise en symbole
const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode.toUpperCase()) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    case "CAD":
      return "$";
    case "AUD":
      return "$";
    default:
      return currencyCode; // Retourner le code si aucun symbole n'est trouvé
  }
};

interface PricingArticleCardItemProps {
  data: ArticleStripeDetails;
  isBuy: boolean;
}

const PricingArticleCard: React.FC<PricingArticleCardItemProps> = ({
  data,
  isBuy,
}) => {
  const [imageFailed, setImageFailed] = useState(false);
  const { infoOpeningData } = useLessonOpeningStore();

  // Gestion des fonctionnalités limitées à 3
  const maxFeaturesToShow = 3;
  const displayedFeatures = data.features.slice(0, maxFeaturesToShow);
  const remainingFeaturesCount = data.features.length - maxFeaturesToShow;

  const parentRef = useRef<HTMLDivElement>(null);
  const [boardSize, setBoardSize] = useState<number | null>(null);

  // Met à jour la taille du plateau en fonction de la taille du conteneur parent
  useEffect(() => {
    const updateBoardSize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
        const parentHeight = parentRef.current.offsetHeight;

        // On choisit une taille basée sur la plus petite dimension
        setBoardSize(Math.min(parentWidth, parentHeight) * 0.9); // Par exemple, 90% de la plus petite dimension
      }
    };

    // Met à jour au chargement et lors du redimensionnement de la fenêtre
    updateBoardSize();
    window.addEventListener("resize", updateBoardSize);

    return () => {
      window.removeEventListener("resize", updateBoardSize);
    };
  }, []);

  return (
    <div className="article-card-horizontal">
      {/* Titre en haut */}
      <h2 className="card-title">{data.title}</h2>

      <div className="card-content">
        {/* Partie gauche : Image */}
        <div className="card-left">
          <div className="card-image" ref={parentRef}>
            {!imageFailed ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/api/get-article-image/${data.productID}`}
                alt={`${data.title} illustration`}
                onError={() => setImageFailed(true)} // Bascule vers un fallback si l'image échoue
                loading="lazy"
              />
            ) : (
              <ChessboardPresentation
                boardSize={150} // Utilise une taille par défaut si `boardSize` n'est pas encore calculé
                color={infoOpeningData.color}
                history={infoOpeningData.history}
              />
            )}
          </div>
        </div>

        {/* Partie droite : Fonctionnalités */}
        <div className="card-right">
          <div className="card-features">
            <ul>
              {displayedFeatures.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
              {remainingFeaturesCount > 0 && (
                <li className="more-features">
                  +{remainingFeaturesCount} more
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* Prix en bas */}
      <div className="card-footer">
        <h4 className="card-price">
          {parseFloat(data.price.toString()).toFixed(2)}{" "}
          {getCurrencySymbol(data.currency)}
        </h4>
        {isBuy && (
          <div className="buy-article-button">
            <button onClick={() => alert(`Buying: ${data.title}`)}>
              Buy Now
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingArticleCard;
