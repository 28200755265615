// components/ChessboardManager/ChessboardManager.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { evaluation } from "../../../assets/svg/evaluation";
import { useAnnotationsStore } from "../../../store/useAnnotationsStore";
import { OpeningMove } from "../../../types";
import { GameChess } from "../../../utils/GameChess";
import ChessboardSizeHandler from "../ChessboardSizeHandler/ChessboardSizeHandler";
import "./ChessboardManager.scss";

interface ChessboardManagerProps {
  gameChess: GameChess;
  boardSize: number;
  customArrows: any;
  arePiecesDraggable: boolean;
  onPieceDrop?: (sourceSquare: string, targetSquare: string) => boolean;
  boardOrientation: "white" | "black";
  resizable?: boolean;
  allowAnnotations?: boolean;
  enableClickHandling?: boolean;
  enableScrollOnPieces?: boolean;
  createArrow?: boolean;
  currentMove?: OpeningMove;
  isMode?: boolean;
}

interface Move {
  from: string;
  to: string;
  san: string;
}

const COLORS = {
  selectPiece: "hsl(34, 78%, 60%)",
  legalMove: "hsl(34, 78%, 60%)",
  captureMove: "hsl(34, 78%, 60%)",
};

const ChessboardManager: React.FC<ChessboardManagerProps> = ({
  gameChess,
  boardSize,
  customArrows,
  arePiecesDraggable,
  onPieceDrop = undefined,
  boardOrientation,
  currentMove,
  resizable = true,
  allowAnnotations = true,
  enableClickHandling = true,
  enableScrollOnPieces = true,
  createArrow = true,
}) => {
  const [selectedSquare, setSelectedSquare] = useState<string | null>(null);
  const [highlightedSquares, setHighlightedSquares] = useState<string[]>([]);

  const {
    addAnnotation,
    removeAnnotationByPosition,
    generateCustomSquareStyles,
    removeAllAnnotations,
  } = useAnnotationsStore();

  // Fonction pour sélectionner une pièce
  const selectPiece = (square: string, legalMoves: Move[]) => {
    const hasLegalMoves = legalMoves.some((move) => move.from === square);
    if (!hasLegalMoves) {
      console.log(`Aucun mouvement légal depuis la case ${square}`);
      return;
    }

    addAnnotation(square, "selectPiece", COLORS.selectPiece);
    setSelectedSquare(square);

    const newHighlightedSquares = legalMoves
      .filter((move) => move.from === square)
      .map((move) => {
        const isCapture = move.san.includes("x");
        const annotationType = isCapture ? "captureMove" : "legalMove";
        const annotationColor = isCapture
          ? COLORS.captureMove
          : COLORS.legalMove;

        addAnnotation(move.to, annotationType, annotationColor);
        return move.to;
      });
    setHighlightedSquares(newHighlightedSquares);
  };

  // Fonction pour déselectionner la pièce
  const deselectPiece = () => {
    removeAnnotations();
    setSelectedSquare(null);
    console.log("Sélection annulée");
  };

  // Fonction pour tenter d'exécuter un mouvement
  const attemptMove = (
    square: string,
    legalMoves: Move[],
    gameChess: GameChess,
    onPieceDrop?: (sourceSquare: string, targetSquare: string) => boolean
  ) => {
    if (!selectedSquare) return;

    const isLegalMove = legalMoves.some(
      (move) => move.from === selectedSquare && move.to === square
    );

    if (isLegalMove) {
      removeAnnotations();
      if (onPieceDrop) onPieceDrop(selectedSquare, square);
      setSelectedSquare(null);
    } else {
      const boardState = gameChess.getPiecesOnBoard();
      const pieceAtSquare = boardState[square];

      if (
        pieceAtSquare &&
        pieceAtSquare.color === gameChess.getCurrentPlayerColor()
      ) {
        // Changer la sélection vers la nouvelle pièce
        removeAnnotations();
        selectPiece(square, legalMoves);
      } else {
        console.log(
          `Le mouvement de ${selectedSquare} vers ${square} n'est pas légal`
        );
        deselectPiece();
      }
    }
  };

  // Fonction pour supprimer les annotations liées à la sélection
  const removeAnnotations = () => {
    if (selectedSquare) {
      removeAnnotationByPosition(selectedSquare, "selectPiece");
    }

    highlightedSquares.forEach((square) => {
      removeAnnotationByPosition(square, "legalMove");
      removeAnnotationByPosition(square, "captureMove");
    });

    setHighlightedSquares([]);
  };

  // Gestion de la sélection et des mouvements
  const handleSquareClick = (square: string) => {
    if (!enableClickHandling || !onPieceDrop) {
      return;
    }

    const legalMoves: Move[] = gameChess.getLegalMoves();
    const boardState = gameChess.getPiecesOnBoard();
    const pieceAtSquare = boardState[square];

    // Si une pièce est déjà sélectionnée
    if (selectedSquare) {
      if (square === selectedSquare) {
        // Déselectionner la pièce
        deselectPiece();
        return;
      }

      // Tenter d'exécuter le mouvement
      attemptMove(square, legalMoves, gameChess, onPieceDrop);
      return;
    }

    // Sélectionner une nouvelle pièce
    if (
      pieceAtSquare &&
      pieceAtSquare.color === gameChess.getCurrentPlayerColor()
    ) {
      selectPiece(square, legalMoves);
    } else {
      console.log(
        `La case ${square} n'est pas une case valide pour commencer ou ce n'est pas votre pièce`
      );
    }
  };

  const [kingCheckPosition, setKingCheckPosition] = useState<string | null>(
    null
  );

  // Gestion des annotations spéciales
  useEffect(() => {
    removeAllAnnotations();

    if (currentMove) {
      const moves = gameChess.getPreviousMove();
      const check = gameChess.getKingInCheckPosition();

      // Si le roi est en échec
      if (check.inCheck) {
        // Ajouter une annotation à la case du roi pour indiquer l'échec
        addAnnotation(check.position!, "check", "rgba(255, 0, 0, 0.8)");
        setKingCheckPosition(check.position!); // Mémoriser la position du roi en échec
      } else if (kingCheckPosition) {
        // Si le roi n'est plus en échec, supprimer l'annotation de l'ancienne case du roi
        removeAnnotationByPosition(kingCheckPosition, "check");
        setKingCheckPosition(null); // Réinitialiser la position
      }

      if (moves) {
        addAnnotation(moves.from, "success");
        addAnnotation(moves.to, "success");
      }

      currentMove.circles.forEach(([square, type]) => {
        addAnnotation(square, "circle", type);
      });
    }
  }, [currentMove, kingCheckPosition]);

  // Fonction pour désactiver "touch-action: none" sur les pièces
  const enableScrollOnPiecesFunc = useCallback(() => {
    const pieces = document.querySelectorAll("[data-piece]");

    pieces.forEach((piece) => {
      (piece as HTMLElement).style.touchAction = "auto";
    });
  }, []);

  useEffect(() => {
    if (!enableScrollOnPieces) {
      return;
    }

    enableScrollOnPiecesFunc();

    const observer = new MutationObserver(() => {
      enableScrollOnPiecesFunc();
    });

    const chessboard = document.querySelector(".chessboard-wrapper");
    if (chessboard) {
      observer.observe(chessboard, {
        childList: true,
        attributes: true,
        subtree: true,
      });
    }

    return () => observer.disconnect();
  }, [enableScrollOnPieces, enableScrollOnPiecesFunc]);

  const annotationsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    // Vérifier si les annotations sont autorisées
    if (!allowAnnotations) {
      return;
    }

    annotationsRef.current.forEach((annotation) => {
      if (annotation.parentNode) {
        annotation.parentNode.removeChild(annotation);
      }
    });

    annotationsRef.current = [];

    if (currentMove?.san) {
      const sanWithoutCheck = currentMove.san.replace("+", ""); //on eneleve le + si echec
      const targetSquare = sanWithoutCheck.slice(-2);

      const squareElement = document.querySelector(
        `[data-square='${targetSquare}']`
      ) as HTMLElement;

      if (
        squareElement &&
        currentMove.evaluation &&
        evaluation[currentMove.evaluation]
      ) {
        squareElement.style.position = "relative";
        const size = boardSize * 0.045;
        const svgWrapper = document.createElement("div");
        svgWrapper.classList.add("custom-child");
        svgWrapper.style.position = "absolute";
        svgWrapper.style.right = "-5px";
        svgWrapper.style.top = "-5px";
        svgWrapper.style.height = `${size}px`;
        svgWrapper.style.width = `${size}px`;
        svgWrapper.style.zIndex = "1000";

        const evaluationElement = evaluation[currentMove.evaluation]({
          size: size,
        });

        if (React.isValidElement(evaluationElement)) {
          ReactDOM.render(evaluationElement, svgWrapper);
          squareElement.appendChild(svgWrapper);
          annotationsRef.current.push(svgWrapper);
        }
      }
    }

    return () => {
      annotationsRef.current.forEach((annotation) => {
        if (annotation.parentNode) {
          annotation.parentNode.removeChild(annotation);
        }
      });
      annotationsRef.current = [];
    };
  }, [currentMove, allowAnnotations]); // Ajouter allowAnnotations comme dépendance

  return (
    <ChessboardSizeHandler
      position={gameChess.getBoardPosition()}
      customArrows={customArrows}
      arePiecesDraggable={arePiecesDraggable}
      onPieceDrop={onPieceDrop}
      onSquareClick={handleSquareClick}
      boardOrientation={boardOrientation}
      resizable={resizable}
      customSquareStyles={generateCustomSquareStyles()}
      createArrow={createArrow}
      boardSize={boardSize}
    />
  );
};

export default ChessboardManager;
