import { Link } from "react-router-dom";
import LoaderChess from "../../components/commun/LoaderChess/LoaderChess";
import "./NoFound.scss"; // Optionnel : pour ajouter des styles personnalisés

export default function NoFound() {
  return (
    <div className="no-found-container">
      <h1>Error 404</h1>
      <div className="no-found-container_loader">
        <LoaderChess />
      </div>

      <Link to="/" className="home-link">
        Retour à la page d'accueil
      </Link>
    </div>
  );
}
