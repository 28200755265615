import { Square } from "chess.js";
import { useEffect, useState } from "react";
import { useAnnotationsStore } from "../../../store/useAnnotationsStore";
import { Circle, OpeningMove } from "../../../types";

import { Arrow } from "react-chessboard/dist/chessboard/types";
import useCreateOpeningStore from "../../../store/useCreateOpeningStore";
import ChessboardManager from "../ChessboardManager/ChessboardManager";
import "./ChessboardCreation.scss";

export default function ChessboardCreation() {
  const { removeAllAnnotations, addAnnotation } = useAnnotationsStore();

  const {
    colorArrow,
    gameChess,
    currentMove,
    addMove,
    updateMove,
    infoOpeningData,
  } = useCreateOpeningStore();

  const [rightClickStartSquare, setRightClickStartSquare] = useState<
    string | null
  >(null);
  const [previewMove, setPreviewMove] = useState<OpeningMove | null>(null); // État pour la prévisualisation

  // Réinitialiser les annotations au premier rendu
  useEffect(() => {
    removeAllAnnotations();
  }, []);

  const onDrop = (sourceSquare: string, targetSquare: string) => {
    const san = gameChess.getSANFromMove(sourceSquare, targetSquare);
    if (!san) return false;

    const history = [...(currentMove?.history || []), san];

    const newMoves: OpeningMove = {
      san: san,
      evaluation: "good",
      circles: [],
      history: history,
      path: [],
      blockEntrainement: false,
      arrows: [],
      children: {},
      fen: gameChess.generateFENFromHistory(history) || "",
    };

    addMove(newMoves);

    return true;
  };

  const findSquareFromEvent = (e: MouseEvent): string | null => {
    let element = document.elementFromPoint(e.clientX, e.clientY);

    while (element && !element.getAttribute("data-square")) {
      element = element.parentElement;
    }

    return element ? element.getAttribute("data-square") : null;
  };

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (e.button === 2) {
        const square = findSquareFromEvent(e);
        if (square) {
          setRightClickStartSquare(square);
          setPreviewMove({ ...currentMove, arrows: [] }); // Initialiser previewMove avec aucune flèche temporaire
        }
      }
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (rightClickStartSquare) {
        const square = findSquareFromEvent(e);

        if (square && rightClickStartSquare !== square) {
          // Prévisualiser une seule flèche temporaire
          setPreviewMove({
            ...currentMove,
            arrows: [
              [rightClickStartSquare as Square, square as Square, colorArrow],
            ],
          });
        } else {
          // Si la souris revient à la case de départ, pas de flèche
          setPreviewMove({
            ...currentMove,
            arrows: [],
          });
        }
      }
    };

    const handleMouseUp = (e: MouseEvent) => {
      if (e.button === 2 && rightClickStartSquare) {
        const square = findSquareFromEvent(e);

        if (square && rightClickStartSquare !== square) {
          // Vérifiez si une flèche identique existe déjà
          const arrowExists = currentMove.arrows?.some(
            ([start, end]) =>
              start === (rightClickStartSquare as Square) &&
              end === (square as Square)
          );

          if (!arrowExists) {
            // Ajouter une seule flèche confirmée à currentMove
            const updatedArrows: Arrow[] = [
              ...(currentMove.arrows || []),
              [rightClickStartSquare as Square, square as Square, colorArrow],
            ];

            updateMove({ ...currentMove, arrows: updatedArrows });
          }
        } else if (square === rightClickStartSquare) {
          const updatedCircles: Circle[] = [
            ...(currentMove.circles || []),
            [square as Square, colorArrow],
          ];

          updateMove({ ...currentMove, circles: updatedCircles });
        }

        // Réinitialiser les états
        setRightClickStartSquare(null);
        setPreviewMove(null);
      }
    };

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [rightClickStartSquare, previewMove, currentMove]);

  useEffect(() => {
    removeAllAnnotations();

    (currentMove?.circles || []).forEach(([square, type]) => {
      addAnnotation(square, "circle", type);
    });
  }, [currentMove]);

  return (
    <ChessboardManager
      gameChess={gameChess}
      boardSize={500}
      customArrows={
        previewMove?.arrows
          ? [...(currentMove.arrows || []), ...previewMove.arrows] // Combiner les flèches existantes et temporaires
          : currentMove.arrows || [] // Afficher uniquement les flèches existantes si aucune prévisualisation
      }
      arePiecesDraggable={true}
      onPieceDrop={onDrop}
      boardOrientation={infoOpeningData.color === "w" ? "white" : "black"}
      createArrow={false}
      resizable={false}
    />
  );
}
