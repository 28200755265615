import axios from "axios";
import { create } from "zustand";

const API_URL = process.env.REACT_APP_API_URL || "";

interface UserState {
  isLogged: boolean;
  role: "user" | "creator" | "admin" | null;
  email: string | null;
  name: string | null;
  userId: string | null;
  pseudo: string | null;
  subscriptionExpiry: Date | null; // La date d'expiration de l'abonnement
  setSubscriptionExpiry: (expiry: Date | null) => void;
  setIsLogged: (isLogged: boolean) => void;
  checkLoginStatus: () => Promise<boolean>;
  updateSubscriptionExpiryInBDD: () => Promise<boolean>;
  isPremium: () => boolean;
}

export const useUserStore = create<UserState>((set, get) => ({
  // Initial state
  isLogged: false,
  role: null,
  email: null,
  name: null,
  pseudo: null,
  userId: null,
  subscriptionExpiry: null, // Initialisé à null

  // Setters
  setIsLogged: (isLogged: boolean): void => {
    set({ isLogged });
  },

  setSubscriptionExpiry: (expiry: Date | null): void => {
    set({ subscriptionExpiry: expiry });
  },

  // Met à jour la date d'expiration de l'abonnement depuis le backend
  updateSubscriptionExpiryInBDD: async (): Promise<boolean> => {
    try {
      const response = await axios.get(
        `${API_URL}/api/update-subscription-expiry`,
        {
          withCredentials: true,
        }
      );

      const subscriptionExpiry = response.data.subscriptionExpiry
        ? new Date(response.data.subscriptionExpiry)
        : null;

      // Si l'abonnement est expiré ou inexistant, définir subscriptionExpiry à null
      const isExpired = !subscriptionExpiry || subscriptionExpiry <= new Date();

      set({ subscriptionExpiry: isExpired ? null : subscriptionExpiry });

      // Retourne false si expiré, true sinon
      return !isExpired;
    } catch (error) {
      console.error("Error updating subscription expiry:", error);

      // En cas d'erreur, on met à null
      set({ subscriptionExpiry: null });
      return false;
    }
  },

  isPremium: () => {
    const state = get();

    // L’utilisateur doit être connecté
    if (!state.isLogged) {
      return false;
    }

    // Vérifie que subscriptionExpiry est définie et de type Date
    // puis qu’elle est strictement supérieure à la date actuelle
    const now = new Date();
    const expiry = state.subscriptionExpiry;

    if (!expiry || !(expiry instanceof Date) || expiry <= now) {
      return false;
    }

    // Si tout va bien, l’utilisateur est premium
    return true;
  },

  // Vérifie si l'utilisateur est connecté et récupère ses informations
  checkLoginStatus: async (): Promise<boolean> => {
    try {
      const response = await axios.get(`${API_URL}/api/user-info`, {
        withCredentials: true,
      });

      const userInfo = response.data;

      const subscriptionExpiry = userInfo.subscriptionExpiry
        ? new Date(userInfo.subscriptionExpiry)
        : null;

      const isExpired = !subscriptionExpiry || subscriptionExpiry <= new Date();

      set({
        isLogged: true,
        role: userInfo.role,
        email: userInfo.email,
        name: userInfo.name,
        pseudo: userInfo.pseudo,
        userId: userInfo.userId,
        subscriptionExpiry: isExpired ? null : subscriptionExpiry,
      });

      return true;
    } catch (error) {
      console.error("Error checking login status:", error);
      set({
        isLogged: false,
        role: null,
        email: null,
        name: null,
        pseudo: null,
        userId: null,
        subscriptionExpiry: null, // Si erreur, mettre à null
      });

      return false;
    }
  },
}));
