import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "";

const handleApiError = (error: any) => {
  if (error.response) {
    // Erreurs de l'API
    console.error("API Error:", error.response.data);
    throw new Error(error.response.data.error || "Une erreur est survenue.");
  } else {
    // Erreurs réseau ou autres
    console.error("Unexpected Error:", error);
    throw new Error("Une erreur inattendue est survenue. Veuillez réessayer.");
  }
};

// Service pour créer un abonnement
export const createSubscription = async (
  paymentMethodId: string,
  priceId: string,
  promoCode: string
) => {
  try {
    const response = await axios.post(`${API_URL}/api/create-subscription`, {
      paymentMethodId,
      priceId,
      promoCode,
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Service pour acheter un article
export const purchaseArticle = async (
  articleId: string,
  articleStripeId: string,
  paymentMethodId: string
) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/purchase/${articleId}`,
      {
        articleId,
        articleStripeId: articleStripeId,
        paymentMethodId,
      },
      { withCredentials: true }
    );

    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const confirmPurchase = async (paymentIntentId: string) => {
  try {
    // Effectuer la requête pour confirmer l'achat
    const response = await axios.post(
      `${API_URL}/api/confirm-purchase`,
      {
        paymentIntentId, // Passer l'ID du PaymentIntent pour valider l'achat
      },
      {
        withCredentials: true, // Inclure les cookies pour les sessions sécurisées
      }
    );

    return response.data; // Retourner les données si la requête est un succès
  } catch (error: any) {
    handleApiError(error); // Gérer les erreurs avec une méthode dédiée
    throw error; // Renvoyer l'erreur pour un traitement supplémentaire si nécessaire
  }
};

// Service pour vérifier un code promo
export const verifyPromoCode = async (code: string, priceId: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/verify-promo`,
      {
        code,
        priceId,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
