// routes.js
import { createBrowserRouter } from "react-router-dom";
import { AdminAuthProvider } from "../contexts/AdminAuthContext";

import AccessDenied from "../pages/AccessDenied/AccessDenied";

import CreateOpening from "../pages/CreateOpening/CreateOpening";
import HomePage from "../pages/HomePage/HomePage";
import LearnTraining from "../pages/LearnTraining/LearnTraining";
import NoFound from "../pages/NoFound/NoFound";

import LoaderChess from "../components/commun/LoaderChess/LoaderChess";
import OpeningManagement from "../pages/OpeningManagement/OpeningManagement";
import PremiumPage from "../pages/Premium/Premium";
import Profil from "../pages/Profil/Profil";
import Test from "../pages/Test/Test";

const routerConfig = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/payment",
    element: <PremiumPage />, // Ajouter la route pour la page de paiement
  },
  {
    path: "openings/:openingPath",
    element: (
      <>
        <LearnTraining />
      </>
    ),
  },
  {
    path: "/profil",
    element: (
      <>
        <Profil />
      </>
    ),
  },
  {
    path: "/OpeningManagement",
    element: (
      <>
        <AdminAuthProvider>
          <OpeningManagement />
        </AdminAuthProvider>
      </>
    ),
  },
  {
    path: "/createOpening",
    element: (
      <>
        <AdminAuthProvider>
          <CreateOpening />
        </AdminAuthProvider>
      </>
    ),
  },
  {
    path: "/createOpening/:openingPath",
    element: (
      <>
        <AdminAuthProvider>
          <CreateOpening />
        </AdminAuthProvider>
      </>
    ),
  },
  {
    path: "/test",
    element: (
      <>
        <Test />
      </>
    ),
  },
  {
    path: "/403",
    element: (
      <>
        <AccessDenied />
      </>
    ),
  },
  {
    path: "/*",
    element: (
      <>
        <NoFound />
      </>
    ),
  },
];

export const router = createBrowserRouter(routerConfig);
