import { IoMdClose } from "react-icons/io";
import { useLessonOpeningStore } from "../../../../store/useLessonOpeningStore";
import Tooltip from "../../Tooltip/Tooltip";
import "./GraduationPanel.scss";
// Typage pour la fonction de changement de mode

const GraduationPanel = () => {
  const { setMode, resetIncrementLesson } = useLessonOpeningStore();

  return (
    <div className="graduationPanel">
      <Tooltip text="Stop Evaluation">
        <button
          type="button"
          aria-haspopup="dialog"
          aria-expanded="false"
          aria-controls="radix-:r4c:"
          className="graduationPanel_btn"
          onClick={() => {
            resetIncrementLesson();
            setMode("cours");
          }}
        >
          <div className="graduationPanel_btn_stop">
            <IoMdClose />
          </div>
        </button>
      </Tooltip>
    </div>
  );
};

export default GraduationPanel;
