import React from "react";
import icons from "../../../assets/svg/icons";
import { useAuthPremium } from "../../../contexts/AuthPremiumContext";
import "./NotLogged.scss"; // Importation du fichier SCSS

interface NotLoggedProps {
  message: string; // Ajout d'une propriété pour personnaliser le message
}

const NotLogged: React.FC<NotLoggedProps> = ({ message }) => {
  const { checkAccess } = useAuthPremium();

  return (
    <div className="container">
      {icons({ width: 90, height: 90 }).premium}
      <h1 className="title">{message}</h1>
      <button className="login-button" onClick={() => checkAccess("login")}>
        Se connecter
      </button>
    </div>
  );
};

export default NotLogged;
