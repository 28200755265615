import React from "react";
import useCreateOpeningStore from "../../../../../store/useCreateOpeningStore";
import { TreeNode } from "../../../../../types";
import "./NodeWithNoOrManyChildren.scss";

interface NodeWithNoOrManyChildrenProps {
  nodeDatum: TreeNode;
  handleMouseEnter: (
    event: React.MouseEvent,
    history: string[],
    comment?: string
  ) => void;
  handleMouseLeave: () => void;
}

const NodeWithNoOrManyChildren: React.FC<NodeWithNoOrManyChildrenProps> = ({
  nodeDatum,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const { selectedNode, addMultiPath, setSelectedNode, infoOpeningData } =
    useCreateOpeningStore();
  const isPremium = infoOpeningData.pathForPremium! <= nodeDatum.move.path[0];
  // Vérifie si le nœud est actif

  let isActive = false;
  let isNoeudEnd = false;
  // 1. Si le premier nœud n'a pas de chemin et que son enfant n'a pas de chemin non plus

  const hasChildren =
    nodeDatum?.move?.children &&
    Object.keys(nodeDatum?.move?.children).length > 0;

  // Vérifie si le nœud actuel est le premier nœud (sans historique)
  const isFirstNode = nodeDatum?.move?.history.length === 0;

  // Vérifie si tous les enfants ont un chemin non vide
  const allChildrenHavePath =
    hasChildren &&
    Object.values(nodeDatum.move.children).every(
      (child) => child.path && child.path.length > 0
    );

  // Applique la condition uniquement si le nœud actuel est le premier
  if (isFirstNode && allChildrenHavePath) {
    isActive = true; // Le premier nœud est actif car tous ses enfants ont un chemin
  }
  // 2. Si le nœud a plusieurs enfants, vérifie si au moins un chemin a une longueur supérieure ou égale au nombre d'enfants
  else if (
    hasChildren &&
    Object.values(nodeDatum?.move?.children).every(
      (child) => child?.path?.length > 0
    )
  ) {
    isActive = true;
  }

  // 3. Si le nœud n'a pas d'enfants, il est actif si son chemin a une longueur > 0
  else if (!hasChildren && nodeDatum?.move?.path.length > 0) {
    isActive = true;
  } else if (!hasChildren && nodeDatum?.move?.path.length === 0) {
    isNoeudEnd = true;
  }

  const handleClick = () => {
    if (selectedNode) {
      addMultiPath(selectedNode, nodeDatum);
      setSelectedNode(null);
    } else {
      if (!isNoeudEnd) setSelectedNode(nodeDatum);
    }
  };

  return (
    <g
      // Applique la classe "active" ou "inactive" en fonction de l'état du nœud
      className={`nodeWithNoOrManyChildren ${
        isActive
          ? isPremium
            ? "activeOrange" // Utilisateur premium, actif
            : "activeGreen" // Utilisateur gratuit, actif
          : isNoeudEnd
          ? "inactive" // Noeud inactif
          : "noeudEnd" // Fin de nœud
      }`}
      onClick={isActive ? () => {} : (e) => handleClick()}
      onMouseEnter={(e) => handleMouseEnter(e, nodeDatum?.move?.history || [])}
      onMouseLeave={handleMouseLeave}
    >
      <circle cx="0" cy="0" r="12" strokeWidth="1" />
      <text
        x="0"
        y="0"
        textAnchor="middle"
        fontSize="10"
        fill="white"
        fontWeight="normal"
        dominantBaseline="central"
      >
        {nodeDatum.name}
      </text>
    </g>
  );
};

export default NodeWithNoOrManyChildren;
