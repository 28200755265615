import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import React from "react";
import { InfoSubscription } from "../../../../types";
import HorizontalPricingCardItem from "./Horizontal/Horizontal";
import VerticalPricingCard from "./Vertical/Vertical";

interface PricingSubscriptionCardProps {
  data: InfoSubscription;
  layout?: "vertical" | "horizontal";
  isBuy?: boolean;
  isSubscription?: boolean; // Nouvelle prop : true si abonnement, false si article
}

const PricingSubscriptionCard: React.FC<PricingSubscriptionCardProps> = ({
  data,
  layout = "vertical",
  isBuy = true,
  isSubscription = false,
}) => {
  return layout === "vertical" ? (
    <VerticalPricingCard
      data={data}
      isBuy={isBuy}
      isSubscription={isSubscription}
    />
  ) : (
    <HorizontalPricingCardItem
      data={data}
      isBuy={isBuy}
      isSubscription={isSubscription}
    />
  );
};

export default PricingSubscriptionCard;
