import { useEffect, useState } from "react";
import "./PriceSelector.scss";

interface PriceSelectorProps {
  price: number | undefined;
  onPriceChange: (price: number | undefined) => void;
}

const PriceSelector = ({ price, onPriceChange }: PriceSelectorProps) => {
  const [hoveredOption, setHoveredOption] = useState<"free" | "paid" | null>(
    null
  );
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState<string>(
    price !== undefined && price !== null ? price.toString() : ""
  );
  const [previousPrice, setPreviousPrice] = useState<number | undefined>(price);

  useEffect(() => {
    setInputValue(
      price !== undefined && price !== null ? price.toString() : ""
    );

    if (price !== undefined) {
      // Mémoriser le dernier prix valide lorsqu'il est défini
      setPreviousPrice(price);
    }
  }, [price]);

  const handleOptionClick = (option: "free" | "paid") => {
    if (option === "free") {
      // Passer à gratuit et mémoriser le prix actuel
      setPreviousPrice(price);
      onPriceChange(undefined);
    } else {
      // Restaurer le prix précédent ou utiliser une valeur par défaut
      const restoredPrice = previousPrice || 1;
      onPriceChange(restoredPrice);
    }
  };

  const handleMouseEnter = (option: "free" | "paid") => {
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // Autoriser uniquement les nombres et un point décimal
    value = value.replace(/[^0-9.]/g, "");

    // Gérer plusieurs points décimaux
    const parts = value.split(".");
    if (parts.length > 2) return;

    if (parts[1]) {
      // Limiter à deux chiffres après la virgule
      parts[1] = parts[1].slice(0, 2);
    }

    value = parts.join(".");
    setInputValue(value);

    if (value !== "") {
      const parsedValue = parseFloat(value);
      onPriceChange(isNaN(parsedValue) ? 0 : parsedValue);
    } else {
      onPriceChange(undefined);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (price === undefined) {
      onPriceChange(undefined);
    }
    // Ne pas formater automatiquement la valeur lors du blur
  };

  return (
    <div className="price-selector">
      <div className="price-options">
        {/* Option Gratuit */}
        <span
          className={`option ${
            (hoveredOption === "free" || (price === undefined && !isFocused)) &&
            "hovered"
          } ${price === undefined && !isFocused && "selected"}`}
          onClick={() => handleOptionClick("free")}
          onMouseEnter={() => handleMouseEnter("free")}
          onMouseLeave={handleMouseLeave}
        >
          Gratuit
        </span>
        {/* Option Payant */}
        <span
          className={`option ${
            (hoveredOption === "paid" || price !== undefined || isFocused) &&
            "hovered"
          } ${(price !== undefined || isFocused) && "selected"}`}
          onClick={() => handleOptionClick("paid")}
          onMouseEnter={() => handleMouseEnter("paid")}
          onMouseLeave={handleMouseLeave}
        >
          Payant
        </span>
      </div>
      {/* Afficher l'input pour le prix si l'option "payant" est sélectionnée */}
      {(price !== undefined || isFocused) && (
        <div className="price-input">
          <label htmlFor="price">Prix ($) :</label>
          <input
            type="text"
            id="price"
            value={inputValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleInputChange}
            placeholder="Entrer le prix"
            inputMode="decimal"
          />
        </div>
      )}
    </div>
  );
};

export default PriceSelector;
