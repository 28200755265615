import { useUserStore } from "../store/useUserStore";

// utils/debounce.ts
export function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout;
  return (...args: Parameters<T>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export function getPieceFromMove(
  san: string,
  history: string[]
): string | null {
  const pieceColor = history.length % 2 === 0 ? "b" : "w";
  const pieceChar = san[0];

  if (/[a-h]/.test(pieceChar)) {
    return pieceColor + "P"; // Pion
  }

  switch (pieceChar) {
    case "N":
      return pieceColor + "N"; // Cavalier
    case "B":
      return pieceColor + "B"; // Fou
    case "R":
      return pieceColor + "R"; // Tour
    case "Q":
      return pieceColor + "Q"; // Dame
    case "K":
      return pieceColor + "K"; // Roi
    default:
      return null;
  }
}

// Fonction pour télécharger un fichier JSON
export const downloadJsonFile = (data: object, filename: string) => {
  const fileData = JSON.stringify(data, null, 2);
  const blob = new Blob([fileData], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${filename}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Fonction checkAccess avec vérification asynchrone de isPremium
export const checkAccess = async (currentMove: any): Promise<boolean> => {
  const { isLogged, isPremium } = useUserStore.getState();
  const LOGIN_AFTER_MOVE = 5;
  const PREMIUM_AFTER_MOVE = 10;

  if (currentMove.path[0] > LOGIN_AFTER_MOVE && !isLogged) {
    return false; // Not logged in
  }

  if (currentMove.path[0] > PREMIUM_AFTER_MOVE && !(await isPremium())) {
    return false; // Not premium
  }

  return true; // User is allowed
};

// Utilise useMemo pour mémoriser le calcul du temps moyen afin d'éviter les recalculs inutiles
export const averageMoveTime = (movesTimes: number[]): string => {
  if (movesTimes.length > 0) {
    const totalMoveTime: number = movesTimes.reduce(
      (a: number, b: number) => a + b,
      0
    );
    return (totalMoveTime / movesTimes.length).toFixed(2);
  } else {
    return "N/A";
  }
};
